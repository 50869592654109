import React from 'react';
import right_arrow_icon from "../icons/right_arrow.svg";

class GridPager extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageIndex: this.props.pageIndex,
            numItems: 0,
        }

        this.containerRef = React.createRef();

        // for tracking grid changes
        this.numRows = 0;
        this.numCols = 0;

        // for finding number of items possible to display
        this.contentWidth = 100;
        this.contentHeight = 100;

        // for tracking whether the animation end listener is 
        // fired when the old pade is finished animating out
        this.animatingExit = false;

        // update layout when the grid has changed
        this.gridChangeListener = (rows, cols) => {
            console.log(`rows: ${rows}, cols: ${cols}`);
            this.setState({ numItems: rows * cols });
        };

        // compute number of rows and cols when resized
        this.resizeListener = (e) => {

            if (!this.containerRef.current) return;

            let rect = this.containerRef.current.getBoundingClientRect();
            let style = window.getComputedStyle(this.containerRef.current);
            let gap = parseInt(style.gap) | 0;

            // compute number of rows and cols
            let numRows = Math.floor(rect.height / (this.contentHeight + gap));
            let numCols = Math.floor(rect.width / (this.contentWidth + gap));
            if (numRows * (this.contentHeight + gap) + this.contentHeight <= rect.height) numRows++;
            if (numCols * (this.contentWidth + gap) + this.contentWidth <= rect.width) numCols++;

            if (numRows !== this.numRows || numCols !== this.numCols) {
                this.numRows = numRows;
                this.numCols = numCols;
                this.gridChangeListener(numRows, numCols);
            }
        };

        // watch for animation end events and swap content when needed
        this.animationListener = (e) => {

            // console.log("grid anim end");

            if (this.animatingExit) {
                this.animatingExit = false;

                this.animateTransition(false, this.state.pageIndex, this.nextPageIndex);
                this.setState({ pageIndex: this.nextPageIndex });
            }
        }

    }


    componentDidMount() {
        window.addEventListener('resize', this.resizeListener);
        this.containerRef.current.addEventListener("animationend", this.animationListener);
        this.resizeListener();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
        this.containerRef.current.removeEventListener("animationend", this.animationListener);
    }

    // animate the page transition given a current and next page,
    // and a boolean for whether the transition is exiting the old page,
    // as opposed to entering the new page
    animateTransition(isExit, fromPage, toPage) {
        let pageForward = toPage > fromPage;
        let element = this.containerRef.current;
        if (isExit) {
            element.style["animation-name"] = pageForward ? "page-out-left" : "page-out-right";
            element.style["animation-timing-function"] = "cubic-bezier(0.5, 0, 1, 0.5)";
        } else {
            element.style["animation-name"] = pageForward ? "page-in-right" : "page-in-left";
            element.style["animation-timing-function"] = "cubic-bezier(0, 0.5, 0.5, 1)";
        }
    }

    // get the start and stop indices for the subarray of elements to show
    // given the number of items per page and the page index
    getSliceIndexes(itemsPerPage, pageIndex) {
        let firstIndex = itemsPerPage * pageIndex;
        let lastIndex = itemsPerPage * (pageIndex + 1);
        return [firstIndex, lastIndex];
    }

    // use shouldComponentUpdate to start animation of page change
    shouldComponentUpdate(nextProps) {
        if (nextProps.pageIndex !== this.props.pageIndex) {
            // console.log(`pageChange: ${this.props.pageIndex} -> ${nextProps.pageIndex}`);

            this.nextPageIndex = nextProps.pageIndex;
            this.animatingExit = true;

            // animate current page exit and defer render to after animation is over
            this.animateTransition(true, this.props.pageIndex, nextProps.pageIndex);

            // TODO: check if this is bad
            return false;

        }
        return true;
    }

    render() {

        // console.log("GridPager render()");

        // console.log(`pageIndex: ${this.state.pageIndex}`);

        let allElements = [...this.props.elements];

        let numItems = this.state.numItems;

        let [thisPageIndex1, thisPageIndex2] = this.getSliceIndexes(numItems, this.state.pageIndex);
        let thisPageElements = allElements.slice(thisPageIndex1, thisPageIndex2);

        let numPages = Math.ceil(allElements.length / numItems);

        return <div className="gridpager-container">

            <div
                className="pager-button-container"
            >
                <button
                    style={{ marginLeft: "auto" }}
                    disabled={this.props.pageIndex <= 0}
                    className="pager-button"
                    onClick={() => {
                        if (this.props.onPageChange) this.props.onPageChange(this.props.pageIndex, this.props.pageIndex - 1);
                    }}
                ><img
                    className="pager-button-icon left"
                    alt=""
                    src={right_arrow_icon}
                ></img></button>
                <button
                    disabled={this.props.pageIndex >= numPages - 1}
                    className="pager-button"
                    onClick={() => {
                        if (this.props.onPageChange) this.props.onPageChange(this.props.pageIndex, this.props.pageIndex + 1);
                    }}
                ><img
                    className="pager-button-icon"
                    alt=""
                    src={right_arrow_icon}
                ></img></button>
            </div>

            <div
                className="pager-details-container"
            >
                <div
                    className="pager-page-display"
                >{`Page ${this.state.pageIndex + 1} / ${numPages}`}</div>
            </div>

            <div
                className="pager-page-container"
            >
                <div
                    ref={this.containerRef}
                    className="pager-grid-container"
                    style={{
                        ...this.props.style,
                        animationName: this.state.anim1,
                    }}
                >{thisPageElements}</div>
            </div>

        </div>

    }


}

export default GridPager;