import './App.css';
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import Destiny from './Destiny.js';
import Item from './components/Item.js';
import StatBars from './components/StatBars';
import ItemCard from './components/ItemCard.js';
import Pager from './components/Pager.js';
import Pager2 from './components/Pager2.js';
import Login from './components/Login.js';
import GridPager from './components/GridPager.js';
// import StatOrganizer from './components/StatOrganizer';
import Character from './components/Character.js';
import Setting from './components/Setting.js';
import LoadoutSelector from './components/LoadoutSelector.js';
import LoadoutEquip from './components/LoadoutEquip.js';
import loadingIcon from './icons/loading.svg';
import settingsIcon from './icons/settings.svg';
import ProfileSelector from './components/ProfileSelector.js';
import ArmoryFilter from './components/ArmoryFilter.js';
import TabSelector from './components/TabSelector.js';
import Utils from './Utils.js';

import background_detail from "./icons/background_detail.svg";
import kofi_logo from "./icons/kofi.png";
import LoadoutDetails from './components/LoadoutDetails';

const app = initializeApp({
  apiKey: "AIzaSyBv64d_o4tfjzViTTUn07S46H6auxBoJo0",
  storageBucket: "gs://my-guardian-companion.appspot.com/"
});

class App extends React.Component {

  static CHARACTER_SECTIONS = [
    {
      label: "Postmaster",
      buckets: [
        215593132, // lost items
      ]
    },
    {
      label: "Subclass",
      buckets: [
        3284755031, // subclass
      ]
    },
    {
      label: "Weapons",
      buckets: [
        1498876634, // kinetic weapons
        2465295065, // energy weapons
        953998645, // power weapons
      ]
    },
    {
      label: "Armor",
      buckets: [
        3448274439, // helmet
        3551918588, // gauntlets
        14239492, // chest armor
        20886954, // leg armor
        1585787867, // class armor
      ]
    },
    {
      label: "Equipment",
      buckets: [
        4023194814, // ghost
      ]
    },
  ];

  static CHARACTER_BUCKETS = [
    3284755031, // subclass
    1498876634, // kinetic weapons
    2465295065, // energy weapons
    953998645, // power weapons
    3448274439, // helmet
    3551918588, // gauntlets
    14239492, // chest armor
    20886954, // leg armor
    1585787867, // class armor
    4023194814, // ghost
    // 1469714392, // consumables
    215593132, // lost items
    // 138197802 // general
  ];

  static VAULT_BUCKETS = [
    1498876634, // kinetic weapons
    2465295065, // energy weapons
    953998645, // power weapons
    3448274439, // helmet
    3551918588, // gauntlets
    14239492, // chest armor
    20886954, // leg armor
    1585787867, // class armor
    4023194814, // ghost
    1469714392, // consumables
    // 215593132, // lost items
    138197802 // general
  ];



  constructor(props) {
    super(props);

    this.state = {

      // startup values
      init: false,
      loggedIn: false,
      mainAccountSet: false,
      profileData: null,

      showDevTools: false,
      characters: null,
      loading: false,
      inspectItemData: null,

      // all inventories are stored by bucket
      characterInventories: null,
      accountInventory: null,

      selectedCharacterId: null,

      showDetails: true,
      sortByPower: false,
      showPagedArmory: true,
      armoryPageIndex: 0,

      dataVersion: 0,

      selectedTab: 0,

      armoryType: "all",

      loadouts: [],
    }

    // full account data from refresh
    this.bulkData = undefined;

    this.inspectRef = React.createRef();
    this.mainRef = React.createRef();
    this.mouseElementRef = React.createRef();

    // TODO: check this for bugs... may be better as a ref
    this.selectedItemElement = undefined;

    // flag for whether to equip a dragged item
    this.equipOnRelease = false;

    // this.globalMouseDownListener = (e) => {
    //   // console.log("down");

    //   // console.log(e.target)

    //   // if(!e.target || !e.target.classList.contains("item")) return;

    //   // this.dragElement = e.target;

    // }

    // this.globalMouseUpListener = (e) => {
    //   console.log("up");

    //   if (!this.dragElement) return;

    //   let x = e.clientX;
    //   let y = e.clientY;
    //   let rect = this.dragElement.getBoundingClientRect();

    //   console.log(x);
    //   console.log(y);
    //   console.log(rect);

    //   if (x < rect.x || x > (rect.x + rect.width)) {
    //     console.log("out");
    //   }


    //   this.dragElement = undefined;

    // }

    // this.globalMouseMoveListener = (e) => {

    //   this.mousePosition = { x: e.clientX, y: e.clientY };

    //   let currentElement = this.mouseElementRef.current;

    //   if (!currentElement) return;

    //   // console.log(currentElement.style);

    //   // currentElement.style["background-color"] = `rgb(${this.mousePosition.x}, ${this.mousePosition.y}, 0)`
    //   currentElement.style.top = this.mousePosition.y + "px";
    //   currentElement.style.left = this.mousePosition.x + "px";

    //   // currentElement.innerHTML = JSON.stringify(this.mousePosition)

    //   // console.log(this.mousePosition);

    //   // console.log(e);
    // }

    this.globalClickListener = (e) => {

      if (this.inspectRef.current && !this.inspectRef.current.contains(e.target)) {

        this.setState({ inspectItemData: undefined });

        if (e.target === this.selectedItemElement) {
          e.stopPropagation();
        }

      }

    }

    this.downKeys = [];

    this.globalFocusListener = (e) => {
      if (this.shouldCheckRefresh || this.shouldCheckRefresh === undefined) {
        // console.log("focus");
        let now = Date.now();
        let lastRefreshTime;
        let lastRefreshData = localStorage.getItem("lastRefresh");
        if (lastRefreshData === "failed") return;
        if (lastRefreshData) {
          lastRefreshTime = JSON.parse(lastRefreshData);
        }
        if (lastRefreshTime === undefined || now - lastRefreshTime > 0.25 * 60 * 1000) {
          this.refreshData();
        }
      }
      this.shouldCheckRefresh = false;
    }


    this.globalBlurListener = (e) => {
      let clickedOff = e.srcElement.window !== undefined;
      console.log("clickedOff: " + clickedOff);
      if (clickedOff) {
        this.shouldCheckRefresh = true;
      }
    }

    this.globalKeyDownListener = (e) => {


      // console.log(e.keyCode)

      // prevent repeated events
      if (this.downKeys[e.keyCode]) return;

      // prevent events that come from user typing
      if (this !== e.target && (/textarea|select/i.test(e.target.nodeName) || e.target.type === "text")) {
        return;
      }

      this.downKeys[e.keyCode] = true;

      if (e.keyCode === 68) { // d

        this.setState(prevState => {
          if (prevState.selectedTab === 3) return;
          return { selectedTab: prevState.selectedTab + 1 }
        });

      } else if (e.keyCode === 65) { // a

        this.setState(prevState => {
          if (prevState.selectedTab === 0) return;
          return { selectedTab: prevState.selectedTab - 1 }
        });

      } else if (e.keyCode === 69) { // e

        this.setState(prevState => {
          return { showDetails: !prevState.showDetails }
        });

      } else if (e.keyCode === 16) { // shift

        let current = this.mouseElementRef.current;

        if (current) {
          current.style.visibility = "visible";
        }

      }

    }

    this.globalKeyUpListener = (e) => {

      // prevent repeated events
      if (!this.downKeys[e.keyCode]) return;

      // prevent events that come from user typing
      if (this !== e.target && (/textarea|select/i.test(e.target.nodeName) || e.target.type === "text")) {
        return;
      }

      this.downKeys[e.keyCode] = false;

      if (e.keyCode === 69) { // e

        this.setState(prevState => {
          return { showDetails: !prevState.showDetails }
        });

      } else if (e.keyCode === 16) { // shift

        let current = this.mouseElementRef.current;

        if (current) {
          current.style.visibility = "hidden";
        }

      }

    }




  }


  isInstantTransferMode() {
    // shift pressed
    return false; //this.downKeys[16];
  }

  getClassType(characterId) {
    return this.bulkData?.characters?.data[characterId]?.classType;
  }

  getLastPlayedCharacterId() {

    let characterData = this.bulkData?.characters?.data;

    if (!characterData) return undefined;

    let maxDate = 0;

    let characterIds = Object.keys(characterData);

    let lastCharacterId = characterIds[0];

    for (let characterId of characterIds) {

      let character = characterData[characterId];

      let dateLastPlayed = Date.parse(character.dateLastPlayed);

      if (dateLastPlayed > maxDate) {

        maxDate = dateLastPlayed;

        lastCharacterId = characterId;

      }

    }

    return lastCharacterId;

  }

  // load all necessary data on page load
  async initialize() {

    this.setState({ loading: true });

    let authenticated = false;

    // check for auth code in url
    let urlParams = new URLSearchParams(window.location.search);
    let authCode = urlParams.get("code");
    if (authCode) {
      // remove auth code from the url and print it to the console
      window.history.replaceState({}, document.title, "/");
      console.log("authCode: " + authCode);
    }

    // check if user is authenticated or authenticate if there
    // is a code in the url
    if (localStorage.getItem("oauth")) {
      authenticated = true;
      console.log("User is authenticated");
    } else if (authCode) { // user not logged in
      let authResult = await Destiny.authenticateUser(authCode);
      if (authResult.success) {
        authenticated = true;
      } else { // cannot log in due to lack of code in url
        Destiny.clearUserData();
        this.setState({ loggedIn: false, loading: false });
      }
    }

    // if there is no user, there is nothing else we can do
    if (!authenticated) {
      console.log("User not logged in");
      this.setState({ loading: false });
      return;
    }

    this.setState({ loggedIn: true });

    // if the user is logged in but has not selected their main account,
    // get their linked bungie profiles and let them select one
    if (!localStorage.getItem("membershipId")) {
      let oauth = JSON.parse(localStorage.getItem("oauth"));
      console.log(oauth.membership_id);
      this.setState({ loading: true });
      let profileData = await Destiny.getLinkedProfiles(-1, oauth.membership_id);
      console.log(profileData);
      this.setState({ profileData: profileData });
    } else {
      this.setState({ loggedIn: true, mainAccountSet: true });
    }

    // init the database so the manifest can be written to if needed
    await Destiny.initIndexedDB();
    await Destiny.initFirebase(app);

    // refresh the token if needed
    if (Destiny.authRefreshNeeded()) {
      let tokenRefreshSuccess = await Destiny.refreshAuthToken();
      if (!tokenRefreshSuccess) {
        alert("Could not refresh authentication. Try logging out and back in.");
        this.setState({ loading: false });
        return;
      } else {
        console.log("token refresh success");
      }
    }


    this.setState({ loadingLabel: "Downloading data from Bungie..." });

    // update manifest content that is not up to date. skips up to date items
    let manifestUpdateSuccess = await Destiny.updateManifestFast();
    if (!manifestUpdateSuccess) {
      alert("Could not load all Destiny data from Bungie. Try refreshing the page.\n\n[#1]");
      this.setState({ loading: false });
      // return;
    }

    this.setState({ loadingLabel: "Loading local data..." });

    // initialize memory from local manifest data
    let memoryInitSuccess = await Destiny.initMemory();
    if (!memoryInitSuccess) {
      alert("Could not load all Destiny data from Bungie. Try refreshing the page.\n\n[#2]");
      this.setState({ loading: false });
      return;
    }

    // load cached data
    let cachedBulkData = await Destiny.dbGet("refreshData");
    if (cachedBulkData) {

      // console.log(cachedBulkData);

      this.bulkData = JSON.parse(cachedBulkData);
      this.destinyData = await Destiny.processAccountData(this.bulkData);

      // select most recent character to show while refreshing
      let lastCharacterId = this.getLastPlayedCharacterId();
      this.setState({ selectedCharacterId: lastCharacterId });

    }

    // load saved loaouts
    let loadouts = await Destiny.loadLoadouts();
    if (loadouts) this.setState({ loadouts });

    // most important initialization is complete, show content
    this.setState({
      init: true,
      loading: false,
      loadingProgress: undefined,
      loadingLabel: undefined,
    });

    // refresh and select most recent character if user is logged in
    if (authenticated) {
      await this.refreshData();
      // let lastCharacterId = this.getLastPlayedCharacterId();
      // this.setState({ selectedCharacterId: lastCharacterId });
    }

  }

  componentWillUnmount() {
    // window.removeEventListener("mousedown", this.globalMouseDownListener, true);
    // window.removeEventListener("mouseup", this.globalMouseUpListener, true);
    // window.removeEventListener("mousemove", this.globalMouseMoveListener, true);
    document.body.removeEventListener("click", this.globalClickListener, true);
    document.body.removeEventListener("keydown", this.globalKeyDownListener, true);
    document.body.removeEventListener("keyup", this.globalKeyUpListener, true);
    window.removeEventListener("focus", this.globalFocusListener, true);
    window.removeEventListener("blur", this.globalBlurListener, true);
  }

  componentDidMount() {
    // window.addEventListener("mousedown", this.globalMouseDownListener, true);
    // window.addEventListener("mouseup", this.globalMouseUpListener, true);
    // window.addEventListener("mousemove", this.globalMouseMoveListener, true);
    document.body.addEventListener("click", this.globalClickListener, true);
    document.body.addEventListener("keydown", this.globalKeyDownListener, true);
    document.body.addEventListener("keyup", this.globalKeyUpListener, true);
    window.addEventListener("focus", this.globalFocusListener, true);
    window.addEventListener("blur", this.globalBlurListener, true);

    // this is async!
    this.initialize();

  }

  componentDidUpdate(prevProps) {

    // update inspect card position to stay nicely in view
    if (this.inspectRef.current) {
      let computedStyle = window.getComputedStyle(this.inspectRef.current);
      let scrollTop = this.mainRef.current.scrollTop;

      let top = parseInt(this.state.inspectItemRect.top);
      let newTop = Math.max(top - parseInt(computedStyle.height), 100) + scrollTop; // TODO: remove magic number 100 and replace with height of nav bar
      this.inspectRef.current.style.top = newTop + "px";
    }

  }



  async refreshData() {

    // keeps multiple refreshes from running at the same time
    if (this.refreshing || !this.state.mainAccountSet) return;
    this.refreshing = true;

    this.setState({ loading: true, loadingLabel: "Refreshing Account..." });

    // refresh authentication if needed
    if (Destiny.authRefreshNeeded()) {
      let tokenRefreshSuccess = await Destiny.refreshAuthToken();
      if (!tokenRefreshSuccess) {
        alert("Could not refresh authentication. Try logging out and back in.");
        this.setState({ loading: false, loadingLabel: undefined });
        return;
      } else {
        console.log("token refresh success");
      }
    }

    // cancel if not logged in yet
    if (!localStorage.getItem("oauth") || !localStorage.getItem("membershipId")) return;

    console.log("refreshing all data...")

    // fetch and process account data
    let downloadResult = await Destiny.refreshAccountData(
      Destiny.getMembershipType(),
      localStorage.getItem("membershipId")
    );


    if (downloadResult.success) {
      this.bulkData = downloadResult.bulkData;
      this.destinyData = downloadResult.destinyData;
    } else if (downloadResult.alert) {
      alert("Account refresh failed. If you reloaded the page during an account refresh, ignore this error.\n\nMessage: " + downloadResult.message);
    }

    let selectedCharacterId = this.getLastPlayedCharacterId();

    this.setState((prevState) => ({
      dataVersion: prevState.dataVersion + 1,
      loading: false,
      loadingLabel: undefined,
      selectedCharacterId
    }));

    this.refreshing = false;

  }

  getInspectCard() {

    let itemRect = this.selectedItemElement?.getBoundingClientRect();
    // let mainRect = this.mainRef.current?.getBoundingClientRect();

    if (!itemRect) {
      console.error("this.selectedItemElement is undefined, cannot display inspect card");
      return;
    }

    let scrollTop = this.mainRef.current.scrollTop;

    let inspectCardPosition = {
      top: itemRect.top + scrollTop,
    }

    // let dist = itemRect.top - mainRect.top;

    // let inspectCardPosition = {
    //   top: `calc(${mainRect.top - Math.max(0, dist) + scrollTop}px)`,
    //   // transform: `translate(0, calc(-100% + ${dist}px))`
    // }

    if (itemRect.left + itemRect.width / 2 < document.body.clientWidth / 2) {
      inspectCardPosition.left = itemRect.left + itemRect.width;
    } else {
      inspectCardPosition.right = this.mainRef.current.scrollWidth - itemRect.left;
    }


    let allCharacterData = this.bulkData.characters.data;

    let itemData = this.state.inspectItemData;


    return <ItemCard
      elementRef={this.inspectRef}
      style={{ ...inspectCardPosition }}
      itemData={itemData}

      allCharacterData={allCharacterData}
      selectedCharacterId={this.state.selectedCharacterId}
      viewingVault={this.viewingVault()}
      onTransferRequested={async (toVault, characterId) => {

        let transferResult = await Destiny.transferItemFull(
          this.destinyData,
          characterId,
          Destiny.getMembershipType(),
          this.state.inspectItemData,
          toVault
        );

        if (transferResult.success) {
          this.setState(prevState => ({ inspectItemData: null, dataVersion: prevState.dataVersion + 1 }));
        } else {
          alert(transferResult.message);
        }

      }}
      onClose={() => this.setState({ inspectItemData: null })}
    />

  }

  // check if specified character is using darkness subclass
  getIsDarkTheme(characterId) {

    if (!this.destinyData || !characterId) return false;

    // console.log(this.destinyData);

    let equipmentItems = this.destinyData.characterEquipmentItems[characterId];
    let subclassItemData;

    // console.log(equipmentItems);

    // find equipped subclass item
    for (let itemData of equipmentItems) {
      // console.log("checked item");
      if (itemData.item.bucketHash !== Destiny.BUCKET_HASH_SUBCLASS) continue;
      subclassItemData = itemData;
      break;
    }

    if (!subclassItemData) return false;

    let subclassDefinition = Destiny.DestinyInventoryItemDefinition[subclassItemData.item.itemHash];
    let darkSubclassRegex = new RegExp(".*\\.dark_subclass");

    for (let key in subclassDefinition.traitIds) {
      // console.log("checked traitId");
      let traitId = subclassDefinition.traitIds[key];
      if (darkSubclassRegex.test(traitId)) {
        return true;
      }
    }

    return false;

  }

  getCharacterSelector() {

    let elements = [];

    let allCharacterData = this.bulkData.characters.data;

    let key = 0;

    for (let characterId in allCharacterData) {

      elements.push(

        <Character
          key={key++}
          selected={this.state.selectedCharacterId === characterId}
          characterData={allCharacterData[characterId]}
          onClick={() => {

            this.setState({
              selectedCharacterId: characterId,
              viewingVault: false,
              viewingLoadouts: false,
              inspectItemData: undefined,
            });

          }}
        />

      );
    }

    return elements;

  }



  getLoadoutDisplay() {

    if (!this.destinyData || !this.state.selectedCharacterId) return;

    let classType = this.getClassType(this.state.selectedCharacterId);

    let characterEquipmentItems = this.destinyData.characterEquipmentItems[this.state.selectedCharacterId];

    let currentLoadout = Destiny.createLoadout(characterEquipmentItems, classType);


    return <div className="loadouts-page-container">

      <LoadoutEquip
        title={this.state.loadoutEquipTitle}
        modsEquipped={this.state.modsEquipped}
        modsFailed={this.state.modsFailed}
        modCount={this.state.modCount}
        failedModHashes={this.state.failedModHashes}
        failedModEquips={this.state.failedModEquips}
      />

      <LoadoutDetails
        loadout={this.state.detailsLoadout}
      />

      {/* <div className="pager-details-container">
        <h3 className="subtitle">Loadouts</h3>
      </div> */}

      <LoadoutSelector
        classType={classType}
        currentLoadout={currentLoadout}
        loadouts={this.state.loadouts}

        onSave={(loadout) => {
          this.setState(prevState => {
            let newLoadouts = [...prevState.loadouts];
            newLoadouts.push(loadout);
            Destiny.saveLoadouts(newLoadouts);
            return { loadouts: newLoadouts };
          });
        }}

        onEquip={async (loadout) => {

          this.setState({
            modsEquipped: 0,
            modCount: 0,
            loadoutEquipTitle: "Transferring Items...",
            failedModHashes: [],
            failedModEquips: [],
          });

          let membershipType = Destiny.getMembershipType();

          let loadoutTransferResult = await Destiny.transferLoadout(
            this.destinyData,
            this.state.selectedCharacterId,
            membershipType,
            loadout
          );

          // if(this.refreshing) {
          //   this.setState({ loadoutEquipTitle: "Waiting..." });
          //   await Destiny.sleep(1000);
          // }

          this.setState({ loadoutEquipTitle: "Equipping Items..." });
          this.setState(prevState => ({ dataVersion: prevState.dataVersion + 1 }));


          let loadoutEquipResult = await Destiny.equipLoadout(
            this.destinyData,
            this.state.selectedCharacterId,
            membershipType,
            loadout
          );

          // update state after items equipped
          this.setState(prevState => ({ dataVersion: prevState.dataVersion + 1 }));

          this.setState({ loadoutEquipTitle: "Equipping Mods..." });

          let modEquipResult = await Destiny.equipLoadoutModsFast(
            this.destinyData,
            this.state.selectedCharacterId,
            membershipType,
            loadout,
            (num, length, equipSuccess, itemHash, itemInstanceId, plugItemHash) => {

              console.log(`progress: ${((num) / length * 100).toFixed(0)}%, success: ${equipSuccess}`);

              this.setState(prevState => {

                let failedModHashes = [...prevState.failedModHashes];
                let failedModEquips = [...prevState.failedModEquips];

                if (!equipSuccess) {
                  failedModHashes.push(plugItemHash);
                  failedModEquips.push({
                    itemHash,
                    itemInstanceId,
                    plugItemHash
                  });
                }

                return {
                  modsEquipped: num,
                  modCount: length,
                  failedModHashes,
                  failedModEquips,
                  loadoutEquipTitle: `Equipping Mods... (${num}/${length})`,
                  dataVersion: prevState.dataVersion + 1
                }

              });

            }
          );

          this.setState({ loadoutEquipTitle: "Loadout Equipped!" });

          this.setState(prevState => ({ dataVersion: prevState.dataVersion + 1 }));

          if (!loadoutEquipResult.success) {
            alert(loadoutEquipResult.failedInstanceIds.length + " item(s) could not be equipped:\n" + loadoutEquipResult.failedItemNames.join("\n"));
          }

        }}

        onExpand={(loadoutIndex) => {

          this.setState(prevState => {

            return { detailsLoadout: prevState.loadouts[loadoutIndex] };

          });

        }}

        onDelete={(loadoutIndex) => {

          this.setState(prevState => {
            let newLoadouts = [...prevState.loadouts];
            newLoadouts.splice(loadoutIndex, 1);

            Destiny.saveLoadouts(newLoadouts);

            return { loadouts: newLoadouts };

          });

        }}
      />

    </div>

  }

  // getInventoryDisplay() {

  //   if (!this.destinyData || !this.state.selectedCharacterId) return;

  //   let inventoryItems = this.destinyData.characterInventoryItems[this.state.selectedCharacterId];
  //   let equipmentItems = this.destinyData.characterEquipmentItems[this.state.selectedCharacterId];

  //   inventoryItems = Destiny.bucketize(inventoryItems);
  //   equipmentItems = Destiny.bucketize(equipmentItems);

  //   // console.log(items);

  //   let sectionKey = 0;
  //   let sectionElements = [];

  //   for (let section of App.CHARACTER_SECTIONS) {

  //     let bucketKey = 0;
  //     let bucketElements = [];

  //     for (let bucketHash of section.buckets) {

  //       let inventoryBucketItems = inventoryItems[bucketHash];
  //       let bucketEquippedItem = equipmentItems[bucketHash];

  //       let allBucketItems = [];

  //       if (bucketEquippedItem) allBucketItems.push(...bucketEquippedItem);
  //       if (inventoryBucketItems) allBucketItems.push(...inventoryBucketItems);


  //       // console.log(allItems);

  //       let itemKey = 0;
  //       let itemElements = [];

  //       for (let itemData of allBucketItems) {
  //         itemElements.push(this.getItemElement(itemData, itemKey, false, bucketHash));
  //         itemKey++;
  //       }

  //       let bucketDefinition = Destiny.getBucketDefinition(bucketHash);

  //       console.log(bucketDefinition.displayProperties);

  //       let bucketLabel = "/" + bucketDefinition.displayProperties.name;
  //       let capacityLabel;

  //       // console.log(label + ": " + bucketHash);

  //       if (bucketHash === Destiny.BUCKET_HASH_POSTMASTER) {

  //         if (allBucketItems.length < bucketDefinition.itemCount) {
  //           capacityLabel = ` (${allBucketItems.length}/${bucketDefinition.itemCount})`;
  //         } else {
  //           capacityLabel = " (FULL)";
  //         }

  //       }

  //       let isSubclass = bucketHash === Destiny.BUCKET_HASH_SUBCLASS;

  //       let bucketItemDragging = this.state.dragBucketHash === bucketHash;

  //       bucketElements.push(
  //         <div
  //           key={bucketKey++}
  //         >
  //           {/* <h2
  //             className="bucket-label"
  //           >{bucketLabel}
  //             {capacityLabel ? <span
  //               style={{ fontWeight: 'normal' }}
  //             >{capacityLabel}</span> : null}

  //           </h2> */}

  //           <div
  //             className="bucket-container"
  //           >
  //             {bucketEquippedItem ? <div
  //               className={"equipped" + (isSubclass ? " subclass" : "") + (bucketItemDragging ? " prompt" : "")}
  //               onDragOver={(e) => {
  //                 e.preventDefault();
  //               }}
  //               onDrop={(e) => {
  //                 e.preventDefault();
  //                 if (!bucketItemDragging) return;
  //                 this.equipOnRelease = true;
  //               }}
  //             >
  //               {itemElements[0]}
  //             </div> : null}
  //             <div
  //               className={"inventory" + (isSubclass ? " subclass" : "")}
  //             >
  //               {bucketEquippedItem ? itemElements.slice(1) : itemElements}
  //             </div>
  //           </div>
  //         </div>
  //       );

  //     }

  //     sectionElements.push(<div
  //       className="inventory-section-container"
  //     >
  //       <h2
  //         className="inventory-section-label"
  //       >{"/" + section.label}</h2>
  //       <div className="inventory-section-bucket-container">
  //         {bucketElements}
  //       </div>
  //     </div>);

  //   }

  //   return <div
  //     className="inventory-container"
  //   >

  //     {sectionElements}
  //   </div>;

  // }

  getInventoryDisplay2() {

    if (!this.destinyData || !this.state.selectedCharacterId) return;

    let characterItems = Destiny.destinyData.characterItems[this.state.selectedCharacterId];

    let sectionKey = 0;
    let sectionElements = [];

    for (let section of App.CHARACTER_SECTIONS) {

      let bucketKey = 0;
      let bucketElements = [];

      for (let bucketHash of section.buckets) {

        let inventoryBucket = characterItems[bucketHash];

        if (!inventoryBucket) continue;

        let allBucketItems = [];

        if (inventoryBucket.equippedItem) allBucketItems.push(inventoryBucket.equippedItem);
        if (inventoryBucket.inventoryItems) allBucketItems.push(...inventoryBucket.inventoryItems);

        // console.log(allItems);

        let itemKey = 0;
        let itemElements = [];

        for (let itemData of allBucketItems) {
          itemElements.push(this.getItemElement(itemData, itemKey, false, bucketHash));
          itemKey++;
        }

        let bucketDefinition = Destiny.getBucketDefinition(bucketHash);

        // console.log(bucketDefinition);

        let bucketLabel = "/" + bucketDefinition.displayProperties.name;
        let capacityLabel;

        // console.log(label + ": " + bucketHash);

        if (bucketHash === Destiny.BUCKET_HASH_POSTMASTER) {

          if (allBucketItems.length < bucketDefinition.itemCount) {
            capacityLabel = ` (${allBucketItems.length}/${bucketDefinition.itemCount})`;
          } else {
            capacityLabel = " (FULL)";
          }

        }

        let isSubclass = bucketHash === Destiny.BUCKET_HASH_SUBCLASS;

        let bucketItemDragging = this.state.dragBucketHash === bucketHash;

        bucketElements.push(
          <div
            key={bucketKey++}
          >
            {/* <h2
              className="bucket-label"
            >{bucketLabel}
              {capacityLabel ? <span
                style={{ fontWeight: 'normal' }}
              >{capacityLabel}</span> : null}

            </h2> */}

            <div
              className="bucket-container"
            >
              {inventoryBucket.equippedItem ? <div
                className={"equipped" + (isSubclass ? " subclass" : "") + (bucketItemDragging ? " prompt" : "")}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  if (!bucketItemDragging) return;
                  this.equipOnRelease = true;
                }}
              >
                {itemElements[0]}
              </div> : null}
              <div
                className={"inventory" + (isSubclass ? " subclass" : "")}
              >
                {inventoryBucket.equippedItem ? itemElements.slice(1) : itemElements}
              </div>
            </div>
          </div>
        );

      }

      sectionElements.push(<div
        className="inventory-section-container"
      >
        <h2
          className="inventory-section-label"
        >{"/" + section.label}</h2>
        <div className="inventory-section-bucket-container">
          {bucketElements}
        </div>
      </div>);

    }

    return <div
      className="inventory-container"
    >

      {/* {this.getRecentItemsDisplay()} */}
      {/* <Pager2

      /> */}

      {sectionElements}
    </div>;

  }

  getItemElement(itemData, key, isInVault, bucketHash) {

    let timeout;

    return <Item
      itemData={itemData}
      showPerkPreview={this.state.showDetails}
      showPower={this.state.showDetails}
      key={key}

      onClick={async (e) => {
        console.log(itemData);
        // console.log(this.destinyData);
        console.log(this.destinyData?.instanceItemStates[itemData.item.itemInstanceId]);
        // console.log(this.bulkData)


        let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
        console.log(definition);

        this.selectedItemElement = e.target;

        this.setState({
          inspectItemData: itemData,
          inspectItemRect: e.target.getBoundingClientRect()
        });
      }}

      onMouseDown={(e) => {
        let rect = e.target.getBoundingClientRect();
        this.dragOffset = {
          x: e.clientX - rect.x - rect.width / 2,
          y: e.clientY - rect.y - rect.height / 2
        };
      }}

      onMouseEnter={(e) => {

        timeout = setTimeout(() => {

          this.selectedItemElement = e.target;

          this.setState({
            inspectItemData: itemData,
            inspectItemRect: e.target.getBoundingClientRect()
          });

        }, 250);

      }}

      onMouseLeave={(e) => {

        clearTimeout(timeout);

      }}

      onDragStart={(e) => {

        this.dragStartPosition = { x: e.clientX, y: e.clientY };

        // console.log(this.dragOffset);

        let current = this.mouseElementRef.current;

        if (!current) return;

        current.style.top = (e.clientY - this.dragOffset.y) + "px";
        current.style.left = (e.clientX - this.dragOffset.x) + "px";
        current.style.visibility = "visible";

        current.children[0].innerHTML = "Drag to Transfer";
        current.children[1].style.width = "0%";

        this.equipOnRelease = false;
        this.setState({ dragBucketHash: bucketHash });

      }}

      onDrag={(e) => {

        let current = this.mouseElementRef.current;

        if (!current) return;

        current.style.top = (e.clientY - this.dragOffset.y) + "px";
        current.style.left = (e.clientX - this.dragOffset.x) + "px";

        // get distance of drag
        let dragPosition = { x: e.clientX, y: e.clientY };
        let dx = dragPosition.x - this.dragStartPosition.x;
        let dy = dragPosition.y - this.dragStartPosition.y;
        let dragDist = Math.sqrt(dx * dx + dy * dy);


        current.children[0].innerHTML = dragDist < 100 ? "Drag to Transfer" : "Release to Transfer";
        current.children[1].style.width = Math.min(dragDist, 100) + "%";

      }}

      onDragEnd={async (e) => {

        // e.stopPropagation();

        this.setState({ dragBucketHash: undefined });

        // get distance of drag
        let dragEndPosition = { x: e.clientX, y: e.clientY };
        let dx = dragEndPosition.x - this.dragStartPosition.x;
        let dy = dragEndPosition.y - this.dragStartPosition.y;
        let dragDist = Math.sqrt(dx * dx + dy * dy);

        let current = this.mouseElementRef.current;
        if (current) current.style.visibility = "hidden";

        if (this.equipOnRelease) { // equip item

          console.log("Equip " + itemData.item.itemInstanceId);

          let equipResult = await Destiny.equipItems(
            this.state.selectedCharacterId,
            Destiny.getMembershipType(),
            itemData.item.itemInstanceId
          );

          console.log(equipResult);

          if (equipResult && equipResult[0]?.equipStatus === 1) {

            Destiny.equipItemLocal(
              this.destinyData,
              this.state.selectedCharacterId,
              itemData.item.itemInstanceId
            );

            this.setState(prevState => ({ inspectItemData: null, dataVersion: prevState.dataVersion + 1 }));

          } else {
            alert("Unable to equip");
          }


        } else if (dragDist >= 100) { // transfer item

          let transferResult = await Destiny.transferItemFull(
            this.destinyData,
            this.state.selectedCharacterId,
            Destiny.getMembershipType(),
            itemData,
            !isInVault
          );

          if (transferResult.success) {
            if (isInVault) {
              Destiny.putRecentItem(itemData.item.itemInstanceId);
              console.log(Destiny.getRecentItems());
            }
            this.setState(prevState => ({ inspectItemData: null, dataVersion: prevState.dataVersion + 1 }));
          } else {
            alert(transferResult.message);
          }

        }

      }}



    />

  }

  filterVaultItems(items, classType) {
    // filter items by user selection
    // console.log("currentClassType: " + currentClassType);

    let filteredVaultItems = [];

    for (let itemData of items) {
      let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];

      if (!definition) {
        console.error("No definition for item hash " + itemData.item.itemHash);
        continue;
      }

      // only show items of the selected category
      if (this.state.vaultItemCategoryFilter !== undefined) {
        if (definition.itemSubType !== this.state.vaultItemCategoryFilter) continue;
      }

      // show only crafted items
      if (this.state.vaultItemCraftedFilter) {
        if (!(itemData.item.state & Destiny.STATE_CRAFTED)) continue;
      }

      // show only unlocked items
      if (this.state.vaultItemUnlockedFilter) {
        if ((itemData.item.state & Destiny.STATE_LOCKED)) continue;
      }

      // don't show armor for other classes
      if (
        classType !== undefined &&
        definition.itemType === Destiny.ITEM_TYPE_ARMOR &&
        definition.classType !== classType
      ) continue;

      if (this.state.armoryType === "weapons" && definition.itemType !== Destiny.ITEM_TYPE_WEAPON) continue;

      if (this.state.armoryType === "armor" && definition.itemType !== Destiny.ITEM_TYPE_ARMOR) continue;

      filteredVaultItems.push(itemData);


    }

    return filteredVaultItems;
  }

  getScrollableVaultDisplay() {

    let bucketKey = 0;
    let bucketElements = [];

    // get all items from the vault
    let armoryItems = [...this.destinyData.profileInventoryItems];

    // get all items from non-selected characters and add to the list
    if (this.state.selectedCharacterId) {
      for (let characterId in this.destinyData.characterInventoryItems) {
        if (characterId === this.state.selectedCharacterId) continue;
        let equipmentItems = this.destinyData.characterEquipmentItems[characterId];
        let inventoryItems = this.destinyData.characterInventoryItems[characterId];
        armoryItems.push(...equipmentItems);
        armoryItems.push(...inventoryItems);
      }
    }

    // filter items by user selection
    let currentClassType = this.getClassType(this.state.selectedCharacterId);
    armoryItems = this.filterVaultItems(armoryItems, currentClassType);

    // sort items by the selected sorting method
    let comparator = this.state.sortByPower ? Destiny.ComparatorInfusionVault : Destiny.ComparatorDefaultVault;
    armoryItems = Destiny.bucketize(armoryItems, comparator);

    // loop thru vault buckets and get elements to display
    for (let bucketHash of App.VAULT_BUCKETS) {

      let bucketItems = armoryItems[bucketHash];
      if (!bucketItems) continue;

      let itemElements = [];
      let itemKey = 0;

      // fill bucket grid with item elements
      for (let itemData of bucketItems) {
        itemElements.push(this.getItemElement(itemData, itemKey, true));
        itemKey++;
      }

      let bucketDefinition = Destiny.getBucketDefinition(bucketHash);

      let label = "/" + bucketDefinition.displayProperties.name;
      let capacityLabel;

      if (bucketHash === Destiny.BUCKET_HASH_POSTMASTER) {

        if (bucketItems.length < bucketDefinition.itemCount) {
          capacityLabel = ` ( ${bucketItems.length} / ${bucketDefinition.itemCount} )`;
        } else {
          capacityLabel = " ( FULL )";
        }

      }

      let isSubclass = bucketHash === Destiny.BUCKET_HASH_SUBCLASS;

      bucketElements.push(
        <div
          key={bucketKey++}
        >
          <h2
            className="bucket-label"
          >{label}
            {capacityLabel ? <span
              style={{ fontWeight: 'normal' }}
            >{capacityLabel}</span> : null}

          </h2>

          <div
            className="bucket-container"
          >
            <div
              className={"inventory" + (isSubclass ? " subclass" : "")}
            >
              {itemElements}
            </div>
          </div>
        </div>);


    }



    return <div
      className="inventory-container"
    >
      {bucketElements}
    </div>;

  }

  getPagedVaultDisplay() {

    if (!this.destinyData) return;

    let key = 0;
    let bucketKey = 0;
    let bucketElements = [];

    // get all items from the vault
    let armoryItems = [...this.destinyData.profileInventoryItems];

    // get all items from non-selected characters and add to the list
    if (this.state.selectedCharacterId) {
      for (let characterId in this.destinyData.characterInventoryItems) {
        if (characterId === this.state.selectedCharacterId) continue;
        let equipmentItems = this.destinyData.characterEquipmentItems[characterId];
        let inventoryItems = this.destinyData.characterInventoryItems[characterId];
        armoryItems.push(...equipmentItems);
        armoryItems.push(...inventoryItems);
      }
    }

    let currentClassType = this.getClassType(this.state.selectedCharacterId);

    armoryItems = this.filterVaultItems(armoryItems, currentClassType);

    // sort items by the selected sorting method
    let comparator = this.state.sortByPower ? Destiny.ComparatorInfusionVault : Destiny.ComparatorDefaultVault;
    armoryItems = Destiny.bucketize(armoryItems, comparator);

    let allItemElements = [];

    // loop thru vault buckets and get elements to display
    for (let bucketHash of App.VAULT_BUCKETS) {

      let bucketItems = armoryItems[bucketHash];
      if (!bucketItems) continue;

      let itemElements = [];
      let itemKey = 0;

      // fill bucket grid with item elements
      for (let itemData of bucketItems) {

        let itemElement = this.getItemElement(itemData, key, true);

        itemElements.push(itemElement);
        allItemElements.push(itemElement);
        itemKey++;
        key++;
      }

      let bucketDefinition = Destiny.getBucketDefinition(bucketHash);

      let label = "/" + bucketDefinition.displayProperties.name;
      let capacityLabel;

      if (bucketHash === Destiny.BUCKET_HASH_POSTMASTER) {

        if (bucketItems.length < bucketDefinition.itemCount) {
          capacityLabel = ` ( ${bucketItems.length} / ${bucketDefinition.itemCount} )`;
        } else {
          capacityLabel = " ( FULL )";
        }

      }

      let isSubclass = bucketHash === Destiny.BUCKET_HASH_SUBCLASS;

      bucketElements.push(
        <div
          key={bucketKey++}
        >
          <h2
            className="bucket-label"
          >{label}
            {capacityLabel ? <span
              style={{ fontWeight: 'normal' }}
            >{capacityLabel}</span> : null}

          </h2>

          <div
            className="bucket-container"
          >
            <div
              className={"inventory" + (isSubclass ? " subclass" : "")}
            >
              {itemElements}
            </div>
          </div>
        </div>);


    }

    return <div className="armory-page-container">

      {/* <div className="pager-details-container">

      </div> */}

      <ArmoryFilter
        type={this.state.armoryType}

        onItemTypeSelected={(type) => {
          this.setState({
            armoryType: type,
            vaultItemCategoryFilter: undefined,
            armoryPageIndex: 0,
            vaultItemCraftedFilter: false
          });
        }}

        selectedType={this.state.vaultItemCategoryFilter}

        craftedSelected={this.state.vaultItemCraftedFilter}
        unlockedSelected={this.state.vaultItemUnlockedFilter}

        onTypeSelected={(typeHash) => {
          this.setState({ vaultItemCategoryFilter: typeHash, armoryPageIndex: 0 });
        }}

        onCraftedSelected={(craftedSelected) => {
          this.setState({ vaultItemCraftedFilter: craftedSelected });
        }}

        onUnlockedSelected={(unlockedSelected) => {
          this.setState({ vaultItemUnlockedFilter: unlockedSelected });
        }}

      />



      <GridPager
        pageIndex={this.state.armoryPageIndex}
        elements={allItemElements}
        onPageChange={(prevPageIndex, newPageIndex) => {
          // console.log("gridpager page changed");
          this.setState({ armoryPageIndex: newPageIndex })
        }}
      />

    </div>

  }


  getMenuTabDisplay(...tabNames) {

    return <TabSelector
      style={{
        marginLeft: "auto"
      }}
      tabNames={tabNames}
      selectedTab={this.state.selectedTab}
      onTabSelected={index => {
        this.setState({ selectedTab: index })
        if (index === 3) {
          this.setState({ tool: undefined })
        }
      }}
    />

  }

  getTabControlsDisplay() {

    return <div
      className="tab-controls-container"
    >
      {this.viewingVault() ? <div
        className="tab-controls-content-container"
      ><ArmoryFilter
          type={this.state.armoryType}

          onItemTypeSelected={(type) => {
            this.setState({
              armoryType: type,
              vaultItemCategoryFilter: undefined,
              armoryPageIndex: 0,
              vaultItemCraftedFilter: false
            });
          }}

          selectedType={this.state.vaultItemCategoryFilter}

          craftedSelected={this.state.vaultItemCraftedFilter}

          onTypeSelected={(typeHash) => {
            this.setState({ vaultItemCategoryFilter: typeHash, armoryPageIndex: 0 });
          }}

          onCraftedSelected={(craftedSelected) => {
            this.setState({ vaultItemCraftedFilter: craftedSelected });
          }}

        /></div> : null}
    </div>
  }

  getUniquenessDisplay() {

    let uniqueElements = [];

    if (this.uniquenessData) {

      for (let itemHash in this.uniquenessData.weaponRolls) {

        let definition = Destiny.DestinyInventoryItemDefinition[itemHash];

        let powerCap = Destiny.DestinyPowerCapDefinition[definition.quality.versions[definition.quality.versions.length - 1].powerCapHash].powerCap;

        if (powerCap < 10000) continue;

        let rollData = this.uniquenessData.weaponRolls[itemHash];

        let rollNames = rollData.map(data => (data.plugHashes.map(hash => (Destiny.DestinyInventoryItemDefinition[hash].displayProperties.name)).join(" / ")));



        let element = <div
          className="unique-item-container"
          data-desc={definition.displayProperties.name + " " + powerCap}
          onClick={() => {

            console.log(rollNames.join("\n"));

          }}
        >
          <img
            alt=""
            src={Destiny.BNET + definition.displayProperties.icon}
          />
        </div>

        uniqueElements.push(element);

      }

    }

    return <div>

      <button
        onClick={() => {
          this.uniquenessData = Destiny.processItemUniqueness();
          this.setState(prevState => ({ dataVersion: prevState.dataVersion + 1 }));
        }}
      >Test</button>

      <div
        className="unique-container"
      >
        {uniqueElements}
      </div>

    </div>

  }

  getPerkSynergyDisplay() {

    let elements = [];

    if (this.state.searchResults) {
      for (let result of this.state.searchResults) {

        elements.push(<div>{result}</div>)

      }
    }


    return <div>
      <div>
        <input type="text" onInput={(e) => this.setState({ searchText: e.target.value })}></input>
        <button
          onClick={() => {

            let search = this.state.searchText.toLowerCase();

            let results = [];

            for (let itemHash in Destiny.DestinyInventoryItemDefinition) {

              let definition = Destiny.DestinyInventoryItemDefinition[itemHash];

              if (!definition.itemCategoryHashes || !definition.itemCategoryHashes.includes(3708671066)) continue;

              let description = definition.displayProperties.description;

              if (description && description.toLowerCase().includes(search)) {

                let name = definition.displayProperties.name;

                let categories = definition.itemCategoryHashes.map(hash => (Destiny.DestinyItemCategoryDefinition[hash].displayProperties.name + " " + hash));

                console.log(name + ": " + categories.join(", "));



                results.push(name);

              }

            }

            this.setState({ searchResults: results });

          }}
        >Search</button>
      </div>
      <div>
        {elements}
      </div>

    </div>

  }

  getVaultCleanerDisplay() {

    let vaultDefinition = Destiny.DestinyInventoryBucketDefinition[Destiny.BUCKET_HASH_VAULT];
    let vaultCount = 0;
    let characterCount = 0;
    let allItems = [];
    let filteredItems = [];

    if (!this.destinyData) return;

    for (let itemData of this.destinyData.profileInventoryItems) {
      allItems.push(itemData);
    }

    for (let characterId in this.destinyData.characterInventoryItems) {
      for (let itemData of this.destinyData.characterInventoryItems[characterId]) {
        allItems.push(itemData);
      }
    }

    for (let characterId in this.destinyData.characterEquipmentItems) {
      for (let itemData of this.destinyData.characterEquipmentItems[characterId]) {
        allItems.push(itemData);
      }
    }

    for (let itemData of allItems) {
      let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
      if (itemData.item.bucketHash === Destiny.BUCKET_HASH_VAULT) {
        vaultCount++;
      }
      if ((definition.itemType === Destiny.ITEM_TYPE_ARMOR && definition.inventory.tierTypeHash === Destiny.TIER_TYPE_HASH_EXOTIC) || definition.itemType === Destiny.ITEM_TYPE_WEAPON) {
        filteredItems.push(itemData);
      }
    }

    let itemElements = [];
    let itemKey = 0;

    let itemsByHash = {};

    for (let itemData of filteredItems) {
      if (itemsByHash[itemData.item.itemHash] === undefined) {
        itemsByHash[itemData.item.itemHash] = [itemData];
      } else {
        itemsByHash[itemData.item.itemHash].push(itemData);
      }
    }

    let sortedItemsByQuantity = [];

    for (let itemHash in itemsByHash) {
      let items = itemsByHash[itemHash];
      sortedItemsByQuantity.push({ itemHash, items });
    }

    sortedItemsByQuantity.sort((a, b) => b.items.length - a.items.length);

    for (let itemGroup of sortedItemsByQuantity) {

      let items = itemGroup.items;

      if (items.length <= 1) continue;

      let itemDefinition = Destiny.DestinyInventoryItemDefinition[itemGroup.itemHash];

      if (!itemDefinition) continue;

      itemElements.push(<div
        className="vault-cleaner-item-container"
        onClick={(e) => {
          this.setState(prevState => {
            return { vaultCleanerItemHash: itemGroup.itemHash }
          });
        }}
      >
        <img alt=""
          style={{
            width: 60,
            height: 60,
            /*border: "2px solid lightgrey"*/
          }}
          src={Destiny.BNET + itemDefinition.displayProperties.icon}
        />
        <div style={{
          flex: 1,
          flexShrink: 1,
          margin: "auto 0",
          padding: "0 10px",
          overflowWrap: "break-word",
          hyphens: "manual"
        }}>
          <div>{itemDefinition.displayProperties.name}</div>
        </div>
        <div style={{
          display: "flex",
          flexShrink: 0,
          width: 60,
          height: 60,
          background: "#303030"
        }}><div style={{ padding: 0, margin: "auto", fontSize: "1.25em" }}>{"x" + items.length}</div>
        </div>
      </div>);

    }

    let selectedItems = [];
    let selectedItemElements = [];

    let statBarElements = [];

    if (this.state.vaultCleanerItemHash) {

      selectedItems = itemsByHash[this.state.vaultCleanerItemHash];
      selectedItemElements = selectedItems.map(itemData => this.getItemElement(itemData, itemKey++, true, Destiny.BUCKET_HASH_VAULT));

      for (let i = 0; i < selectedItems.length; i++) {

        let stats = selectedItems[i].stats?.stats;
        let compareStats = selectedItems[0].stats?.stats;

        statBarElements.push(<StatBars
          noLabels={i > 0}
          style={{ width: i === 0 ? 500 : 100 }}
          stats={stats}
          compareStats={compareStats}
        />)

      }

    }





    return <div className="page-container">
      <h1 className="title-1">Vault Report</h1>
      <div className="hint">Select an item to compare your copies of that item</div>
      <br />
      <div className="pager-details-container">
        <h3 className="subtitle">{vaultCount + " / " + vaultDefinition.itemCount}</h3>
      </div>
      <div style={{ display: "grid", gap: 10, gridTemplateColumns: "repeat(auto-fit, var(--item-size)", marginBottom: 10 }}>
        {selectedItemElements}
      </div>
      <div style={{ display: "flex", gap: 10 }}>{statBarElements}</div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 5 }}>
        {itemElements}
      </div>
    </div>

  }

  getToolSelectorDisplay() {
    return <div class="page-container">
      <div className="tools-selector-container">
        <button className="tool-button" onClick={() => this.setState({ tool: "vault cleaner" })}>Vault Report</button>
        <button className="tool-button" onClick={() => this.setState({ tool: "uniqueness" })}>Unique Roll Finder</button>
        <button className="tool-button" onClick={() => this.setState({ tool: "3D" })}>Experimental 3D</button>
      </div>
    </div>
  }

  getTest3dDisplay() {

    return <button onClick={() => {
      Destiny.getGeometryData();
    }}> Test </button>

  }

  getToolsDisplay() {

    switch (this.state.tool) {
      case "vault cleaner": return this.getVaultCleanerDisplay();
      case "uniqueness": return this.getUniquenessDisplay();
      case "3D": return this.getTest3dDisplay();
      default: return this.getToolSelectorDisplay();
    }


  }

  getSettingsDisplay() {
    return <div
      className="settings-container"
    >

      <a
        href='https://ko-fi.com/E1E6F52B4'
        target='_blank'
        rel="noreferrer"
        className="donate-button"
      >Support on Ko-fi</a>

      <br />

      <a
        href='https://docs.google.com/forms/d/e/1FAIpQLSdKJ3VrlT3ka0iCDldLXLuENE8PH7hxHFpjSrkitucFgrKDkw/viewform?usp=sf_link'
        target='_blank'
        rel="noreferrer"
        className="feedback-button"
      >Report Bugs or Give Feedback</a>

      <br />
      <br />

      <h3 className="settings-section-title">Account and Preferences</h3>

      <div className="settings-section">

        <Setting
          primary
          label="Log Out"
          options={[
            { label: "Log Out" },
          ]}
          index={0}
          onSelect={(index, optionValue) => {
            Destiny.clearUserData();
            window.location.reload();
          }}
        />

        <Setting
          label="Item Details"
          options={[
            { label: "Visible (Default)", value: true },
            { label: "Hidden", value: false },
          ]}
          index={this.state.showDetails ? 0 : 1}
          onSelect={(index, optionValue) => {
            this.setState({ showDetails: optionValue });
          }}
        />

        <Setting
          label="Armory Sorting"
          options={[
            { label: "Type (Default)", value: false },
            { label: "Power Level", value: true },
          ]}
          index={this.state.sortByPower ? 1 : 0}
          onSelect={(index, optionValue) => {
            this.setState({ sortByPower: optionValue });
          }}
        />

        {/* <Setting
          label="Armory Layout"
          options={[
            { label: "Paged (Default)", value: true },
            { label: "Scrollable", value: false },
          ]}
          index={this.state.showPagedArmory ? 0 : 1}
          onSelect={(index, optionValue) => {
            this.setState({ showPagedArmory: optionValue });
          }}
        /> */}

      </div>




      {/* <h1>Developer Tools</h1>
      <div>

        <input type="text" onChange={(e) => this.setState({ authCode: e.target.value })}></input>

        <button onClick={async () => {

          let authResult = await Destiny.authenticateUser(this.state.authCode);

          if (authResult.success) {
            let profileData = await Destiny.getLinkedProfiles(-1, authResult.data.membership_id);
            console.log(profileData);
          } else {
            localStorage.removeItem("oauth");
          }

        }}>Login</button>

        <br />
        <br />

        <button onClick={async () => {

          let refreshSuccess = await Destiny.refreshAuthToken();

          console.log(refreshSuccess);

        }}>Refresh Auth</button>

        <br />
        <br />

        <button onClick={async () => {


          this.setState({ loading: true });

          let progressListener = (progress) => {
            this.setState({ loadingProgress: progress });
          }

          let manifestUpdateResult = await Destiny.updateManifest(progressListener);

          if (!manifestUpdateResult.success) {
            alert("Manifest download failed.");
          }

          this.setState({ loading: false, loadingProgress: undefined });

        }}>Get Manifest</button>

        <StatOrganizer/>

      </div> */}



    </div>
  }

  renderTabPage(pageIndex) {

    switch (pageIndex) {
      case 0: return this.getInventoryDisplay2();
      case 2: return this.getLoadoutDisplay();
      case 1: return (this.state.showPagedArmory ? this.getPagedVaultDisplay() : this.getScrollableVaultDisplay());
      case 4: return this.getSettingsDisplay();
      case 3: return this.getToolsDisplay();
      default: return undefined;
    }

  }

  viewingVault() {
    return this.state.selectedTab === 1;
  }

  getNavBar(backdropImageSrc, iconImageSrc) {

    if (!this.state.loggedIn) return;

    return <><div
      className="nav-bar"
      style={{
        backgroundImage: backdropImageSrc ? `url(${Destiny.BNET + backdropImageSrc})` : undefined,
      }}
    >

      {iconImageSrc ? <div className="character-overlay-icon-container">
        <img
          alt=""
          src={Destiny.BNET + iconImageSrc}
          className="character-overlay-icon"
        />
      </div> : null}

      {this.bulkData ? <div
        className="character-selector-container"
      >
        {this.getCharacterSelector()}
        <button
          className="refresh-button"
          onClick={() => this.refreshData()}
        >
          Refresh
        </button>
      </div> : null}
      {this.getMenuTabDisplay("Character", "Armory", "Loadouts", "Tools", "[Settings]")}

    </div>
      {/* {this.getTabControlsDisplay()} */}
    </>


  }


  getLoadingDisplay() {

    let label;

    if (this.state.loadingLabel) {
      label = this.state.loadingLabel;
    }

    if (this.state.loadingProgress !== undefined) {
      label += (this.state.loadingProgress * 100).toFixed(0) + "%";
    }

    return this.state.loading ? <div
      className="loading-display-container"
    >

      {label ? <div
        className="loading-progress-label"
      >{label}</div> : null}

      <div
        className="loading-icon-container"
      >
        <img
          className="loading-icon"
          alt="loading"
          src={loadingIcon}
        />
      </div>

    </div> : null;

  }

  getRecentItemsDisplay() {

    let itemElements = [];

    let recentItems = Destiny.getRecentItems();

    let key = 0;

    for (let recent of recentItems) {

      let itemData = Destiny.destinyData.itemsByInstanceId[recent.itemInstanceId];
      let itemState = Destiny.destinyData.instanceItemStates[recent.itemInstanceId];

      let itemElement = this.getItemElement(itemData, key++, itemState.inVault);

      itemElements.push(itemElement);

    }

    return <div
      className="inventory-section-container"
    >
      <h2
        className="inventory-section-label"
      >{"/Recently Transferred"}</h2>
      <div className="inventory-section-bucket-container">
        <div
          className="bucket-container"
        >
          <div
            className="inventory"
          >
            {itemElements}
          </div>
        </div>
      </div>
    </div>



  }

  render() {

    // TODO: see if this is slow
    let isDarkTheme = this.getIsDarkTheme(this.state.selectedCharacterId);

    // get current character emblem
    let backdropImageSrc;
    let iconImageSrc;
    if (this.bulkData && this.state.selectedCharacterId) {
      let emblemHash = this.bulkData.characters.data[this.state.selectedCharacterId].emblemHash;
      let emblemDef = Destiny.DestinyInventoryItemDefinition[emblemHash];
      backdropImageSrc = emblemDef.secondarySpecial;
      iconImageSrc = emblemDef.secondaryOverlay;
    }

    return <div
      className={"main " + (isDarkTheme ? "dark-theme" : "light-theme")}
    >

      <div className={"main-detail-container"}>
        <img
          className="main-detail"
          alt=""
          src={background_detail}
        />
      </div>

      {/* {this.state.dragBucketHash ? <div style={{
        pointerEvents: "none",
        zIndex: 2,
        position: "absolute",
        right: "0",
        left: "0",
        bottom: "0",
        height: "30%",
        background: "rgba(128, 128, 128, 0.75)",
        backdropFilter: "blur(var(--blur-px))",
        borderTop: "2px solid lightgrey",
      }}></div> : null} */}




      <div className="main-content-container" ref={this.mainRef}>

        {/* <div className={"main-bottom-controls-container"}>
          <button>Test</button>
        </div> */}

        {!this.state.loggedIn ? <Login
          onLogin={async (authCode) => {
            let authResult = await Destiny.authenticateUser(authCode);
            if (authResult.success) {
              let profileData = await Destiny.getLinkedProfiles(-1, authResult.data.membership_id);
              console.log(profileData);
            }
          }}
        /> : null}

        {this.state.loggedIn && !this.state.mainAccountSet ? <ProfileSelector
          profileData={this.state.profileData}
          onProfileSelected={(profile) => {
            console.log(profile);
            localStorage.setItem("membershipId", profile.membershipId);
            localStorage.setItem("membershipType", profile.membershipType);
            this.setState({ mainAccountSet: true });
          }}
          onCancel={() => {
            Destiny.clearUserData();
            window.location.reload();
          }}
        /> : null}

        {this.getLoadingDisplay()}

        {this.state.mainAccountSet ?

          <div className="main-content-grid">

            {this.getNavBar(backdropImageSrc, iconImageSrc)}


            <Pager
              pageIndex={this.state.selectedTab}
              renderPage={pageIndex => {
                return this.renderTabPage(pageIndex);
              }}
            />


          </div> : null}

        {this.state.inspectItemData ? this.getInspectCard() : null}

        <div
          ref={this.mouseElementRef}
          className="mouse-element"
        >
          <div>Drag to Transfer</div>
          <div className="progress-bar" />
        </div>

      </div>



    </div>

  }

}

export default App;
