import React from 'react';
import Destiny from '../Destiny.js';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            authCode: undefined,

        };

    }



    render() {

        return <div
            className="login-container"
        >
            <div className="login-content">
                <h1 className="login-title" >Destiny Second Screen</h1>
                <a
                    className="button login-button"
                    // target="_blank"
                    rel="noreferrer"
                    href={Destiny.AUTH_LINK}
                >Log In</a>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                }}
            >
                <input type="text" onInput={(e)=>this.setState({authCode: e.target.value})}></input>
                <button
                    onClick={() =>{
                        if(this.props.onLogin) this.props.onLogin(this.state.authCode);
                    }}
                >Dev Log In</button>
            </div>
        </div>

    }

}

export default Login;