class DestinyUtils {

    static StatDisplayProperties = {
        // Power
        "1935470627": {
            "index": 0,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "1885944937": {
            "index": 1,
            "isBar": true,
            "showInPreview": true
        },
        // Defense
        "3897883278": {
            "index": 2,
            "isBar": true,
            "showInPreview": true
        },
        // Mobility
        "2996146975": {
            "index": 3,
            "isBar": true,
            "showInPreview": true
        },
        // Resilience
        "392767087": {
            "index": 4,
            "isBar": true,
            "showInPreview": true
        },
        // Recovery
        "1943323491": {
            "index": 5,
            "isBar": true,
            "showInPreview": true
        },
        // Discipline
        "1735777505": {
            "index": 6,
            "isBar": true,
            "showInPreview": true
        },
        // Intellect
        "144602215": {
            "index": 7,
            "isBar": true,
            "showInPreview": true
        },
        // Strength
        "4244567218": {
            "index": 8,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3784226438": {
            "index": 9,
            "isBar": true,
            "showInPreview": true
        },
        // Durability
        "360359141": {
            "index": 10,
            "isBar": true,
            "showInPreview": true
        },
        // Speed
        "1501155019": {
            "index": 11,
            "isBar": true,
            "showInPreview": true
        },
        // Boost
        "3017642079": {
            "index": 12,
            "isBar": true,
            "showInPreview": true
        },
        // Attack
        "1480404414": {
            "index": 13,
            "isBar": true,
            "showInPreview": true
        },
        // Impact
        "4043523819": {
            "index": 14,
            "isBar": true,
            "showInPreview": true
        },
        // Blast Radius
        "3614673599": {
            "index": 15,
            "isBar": true,
            "showInPreview": true
        },
        // Swing Speed
        "2837207746": {
            "index": 16,
            "isBar": true,
            "showInPreview": true
        },
        // Charge Rate
        "3022301683": {
            "index": 17,
            "isBar": true,
            "showInPreview": true
        },
        // Guard Resistance
        "209426660": {
            "index": 18,
            "isBar": true,
            "showInPreview": true
        },
        // Guard Efficiency
        "2762071195": {
            "index": 19,
            "isBar": true,
            "showInPreview": true
        },
        // Guard Endurance
        "3736848092": {
            "index": 20,
            "isBar": true,
            "showInPreview": true
        },
        // Shield Duration
        "1842278586": {
            "index": 21,
            "isBar": true,
            "showInPreview": true
        },
        // Range
        "1240592695": {
            "index": 22,
            "isBar": true,
            "showInPreview": true
        },
        // Accuracy
        "1591432999": {
            "index": 23,
            "isBar": true,
            "showInPreview": true
        },
        // Velocity
        "2523465841": {
            "index": 24,
            "isBar": true,
            "showInPreview": true
        },
        // Stability
        "155624089": {
            "index": 25,
            "isBar": true,
            "showInPreview": true
        },
        // Handling
        "943549884": {
            "index": 26,
            "isBar": true,
            "showInPreview": true
        },
        // Reload Speed
        "4188031367": {
            "index": 27,
            "isBar": true,
            "showInPreview": true
        },
        // Aim Assistance
        "1345609583": {
            "index": 28,
            "isBar": true,
            "showInPreview": false
        },
        // Zoom
        "3555269338": {
            "index": 29,
            "isBar": true,
            "showInPreview": false
        },
        // Airborne Effectiveness
        "2714457168": {
            "index": 30,
            "isBar": true,
            "showInPreview": true
        },
        // Draw Time
        "447667954": {
            "index": 31,
            "isBar": false,
            "showInPreview": true
        },
        // Charge Time
        "2961396640": {
            "index": 32,
            "isBar": false,
            "showInPreview": true
        },
        // Precision Damage
        "3597844532": {
            "index": 33,
            "isBar": true,
            "showInPreview": false
        },
        // Rounds Per Minute
        "4284893193": {
            "index": 34,
            "isBar": false,
            "showInPreview": true
        },
        // Magazine
        "3871231066": {
            "index": 35,
            "isBar": false,
            "showInPreview": true
        },
        // Ammo Capacity
        "925767036": {
            "index": 36,
            "isBar": false,
            "showInPreview": true
        },
        // Inventory Size
        "1931675084": {
            "index": 37,
            "isBar": false,
            "showInPreview": false
        },
        // Recoil Direction
        "2715839340": {
            "index": 38,
            "isBar": false,
            "showInPreview": false
        },
        // Time to Aim Down Sights
        "3988418950": {
            "index": 39,
            "isBar": false,
            "showInPreview": false
        },
        // Move Speed
        "3907551967": {
            "index": 40,
            "isBar": true,
            "showInPreview": false
        },
        // 
        "3291498656": {
            "index": 41,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3291498659": {
            "index": 42,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3291498658": {
            "index": 43,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3291498661": {
            "index": 44,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3409715177": {
            "index": 45,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "953546184": {
            "index": 46,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "2299076437": {
            "index": 47,
            "isBar": true,
            "showInPreview": true
        },
        // 
        "3123546339": {
            "index": 48,
            "isBar": true,
            "showInPreview": true
        },
        // Score Multiplier
        "2733264856": {
            "index": 49,
            "isBar": true,
            "showInPreview": true
        },
        // Handicap
        "2341766298": {
            "index": 50,
            "isBar": true,
            "showInPreview": true
        },
        // Heroic Resistance
        "1546607977": {
            "index": 51,
            "isBar": true,
            "showInPreview": true
        },
        // Arc Damage Resistance
        "1546607978": {
            "index": 52,
            "isBar": true,
            "showInPreview": true
        },
        // Solar Damage Resistance
        "1546607979": {
            "index": 53,
            "isBar": true,
            "showInPreview": true
        },
        // Void Damage Resistance
        "1546607980": {
            "index": 54,
            "isBar": true,
            "showInPreview": true
        },
        // Arc Energy Capacity
        "3625423501": {
            "index": 55,
            "isBar": true,
            "showInPreview": true
        },
        // Solar Energy Capacity
        "2018193158": {
            "index": 56,
            "isBar": true,
            "showInPreview": true
        },
        // Void Energy Capacity
        "16120457": {
            "index": 57,
            "isBar": true,
            "showInPreview": true
        },
        // Stasis Cost
        "3950461274": {
            "index": 58,
            "isBar": true,
            "showInPreview": true
        },
        // Aspect Energy Capacity
        "2223994109": {
            "index": 59,
            "isBar": true,
            "showInPreview": true
        },
        // Ghost Energy Capacity
        "237763788": {
            "index": 60,
            "isBar": true,
            "showInPreview": true
        },
        // Arc Cost
        "3779394102": {
            "index": 61,
            "isBar": true,
            "showInPreview": true
        },
        // Solar Cost
        "3344745325": {
            "index": 62,
            "isBar": true,
            "showInPreview": true
        },
        // Void Cost
        "2399985800": {
            "index": 63,
            "isBar": true,
            "showInPreview": true
        },
        // Stasis Cost
        "998798867": {
            "index": 64,
            "isBar": true,
            "showInPreview": true
        },
        // Any Energy Type Cost
        "3578062600": {
            "index": 65,
            "isBar": true,
            "showInPreview": true
        },
        // Fragment Cost
        "119204074": {
            "index": 66,
            "isBar": true,
            "showInPreview": true
        },
        // Mod Cost
        "514071887": {
            "index": 67,
            "isBar": true,
            "showInPreview": true
        },
        // Power Bonus
        "3289069874": {
            "index": 68,
            "isBar": true,
            "showInPreview": true
        }
    }


}

export default DestinyUtils;