import React from 'react';
import Destiny from '../Destiny.js';
import Item from './Item.js';

class Loadout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    getItemIcon(itemData, definition) {
        let iconSrc;
        if (itemData.item.overrideStyleItemHash) {
            let styleItemDefinition = Destiny.getItemDefinition(itemData.item.overrideStyleItemHash);
            iconSrc = Destiny.BNET + styleItemDefinition.displayProperties.icon;
        } else if (definition.displayProperties.hasIcon) {
            iconSrc = Destiny.BNET + definition.displayProperties.icon;
        }
        return iconSrc;
    }

    getLoadoutItemElement(itemData, definition) {

        let iconSrc = this.getItemIcon(itemData, definition);

        return <div className={"loadout-item" + (itemData.item.state & Destiny.STATE_MASTERWORK ? " masterwork" : "")} style={{ margin: "auto 0" }}>
            <img alt="" style={{ width: '100%', height: '100%' }} src={iconSrc}></img>
        </div>
    }

    getSubclassItemElement(itemData, definition) {

        // get subclass data
        let subclassSockets = Destiny.getSubclassSockets(itemData, definition);

        // get super data so we can display the super rather than just the subclass
        let superDefinition = Destiny.DestinyInventoryItemDefinition[subclassSockets.superSocket.plugHash];

        return <div className="loadout-2-subclass" style={{ width: 80, height: 80 }}>
            <div className="loadout-2-subclass-img-wrapper">
                <img
                    alt=""
                    style={{ width: '100%', height: '100%' }}
                    src={Destiny.BNET + superDefinition.displayProperties.icon}
                ></img>
            </div>
        </div>
    }

    render() {

        let loadout = this.props.loadout;

        let itemElements = [];

        let subclass;
        let weapons = [];
        let armor = [];

        let subclassDefinition;
        let superDefinition;

        let key = 0;

        for (let itemData of loadout.items) {

            let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];

            if (itemData.item.bucketHash === Destiny.BUCKET_HASH_SUBCLASS) {

                subclass = { itemData, definition };

            } else if (definition.itemType === Destiny.ITEM_TYPE_ARMOR) {

                armor.push({ itemData, definition });

            } else if (definition.itemType === Destiny.ITEM_TYPE_WEAPON) {

                weapons.push({ itemData, definition });

            }

        }

        return <div
            className="loadout-2-container"
            onDoubleClick={(e) => {
                if (this.props.onEquip) this.props.onEquip(loadout);
            }}
        >
            <div className={"loadout-2-header" + (this.props.isCurrentLoadout ? " current" : "")}>
                <div className="loadout-2-title">
                    {this.props.isCurrentLoadout ? "Current Loadout" : (loadout.name ? loadout.name : "Loadout")}
                </div>
                <div className="loadout-2-subtitle">Custom Loadout</div>
            </div>
            <div className="loadout-2-content">
                {/* <div className="loadout-container-2-row">{this.getLoadoutItemElement(subclassItem)}</div> */}
                <div className="loadout-container-2-row">
                    {this.getSubclassItemElement(subclass.itemData, subclass.definition)}
                    {weapons.map(weaponData => this.getLoadoutItemElement(weaponData.itemData, weaponData.definition))}
                </div>
                <div className="loadout-container-2-row">
                    {armor.map(armorData => this.getLoadoutItemElement(armorData.itemData, armorData.definition))}
                </div>
            </div>
            <div className="loadout-2-footer">
                {this.props.onSave ? <button
                    onClick={() => {
                        if (this.props.onSave) this.props.onSave(loadout);
                    }}
                >Save</button> : null}
                {this.props.onExpand ? <button
                    onClick={() => {
                            this.props.onExpand?.(this.props.loadoutIndex);
                    }}
                >Expand</button> : null}
                {this.props.onDelete ? <button
                    onClick={() => {

                        let confirm = window.confirm("Are you sure you want to delete this loadout?");

                        if (confirm && this.props.onDelete) {
                            this.props.onDelete(this.props.loadoutIndex);
                        }

                    }}
                >Delete</button> : null}
                {this.props.onEquip ? <button
                    onClick={() => {
                        if (this.props.onEquip) this.props.onEquip(loadout)
                    }}
                >Equip</button> : null}
            </div>
        </div>

    }

    // render() {

    //     let loadout = this.props.loadout;

    //     let itemElements = [];
    //     let superElement;
    //     let subclassDefinition;
    //     let superDefinition;

    //     let key = 0;

    //     for (let itemData of loadout.items) {

    //         let element = <Item
    //             key={key++}
    //             showPower={true}
    //             showPerkPreview={true}
    //             itemData={itemData}
    //         />

    //         if (itemData.item.bucketHash === Destiny.BUCKET_HASH_SUBCLASS) {

    //             // get subclass data
    //             subclassDefinition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash]
    //             let subclassSockets = Destiny.getSubclassSockets(itemData, subclassDefinition);

    //             // get super data so we can display the super rather than just the subclass
    //             superDefinition = Destiny.DestinyInventoryItemDefinition[subclassSockets.superSocket.plugHash];

    //             // display the super in the same way as the subclass item
    //             superElement = <div className="item-wrapper subclass">
    //                 <div className="item">
    //                     <img
    //                         className="item-icon"
    //                         alt=""
    //                         src={Destiny.BNET + superDefinition.displayProperties.icon}
    //                     />
    //                 </div>
    //             </div>

    //         } else {
    //             itemElements.push(element);
    //         }

    //     }

    //     return <div
    //         className="loadout-container"
    //     >
    //         <div className="loadout-header">
    //             <h1
    //                 className="loadout-title"
    //             >{this.props.isCurrentLoadout ? "Current Loadout" : (loadout.name ? loadout.name : "Loadout")}</h1>
    //             {/* <h2 className="loadout-subtitle">Custom Loadout</h2> */}
    //         </div>

    //         {/* <div className="loadout-details-container">
    //             {superElement}
    //         </div> */}


    //         <div
    //             className="loadout-equipment-container"
    //         >
    //             {superElement}
    //             <div
    //                 className="loadout-items-container"
    //             >{itemElements}</div>
    //         </div>
    //         <div
    //             className="loadout-controls-container"
    //         >
    //             {this.props.onSave ? <button
    //                 onClick={() => {
    //                     if (this.props.onSave) this.props.onSave(loadout);
    //                 }}
    //             >Save</button> : null}
    //             {this.props.onDelete ? <button
    //                 onClick={() => {

    //                     let confirm = window.confirm("Are you sure you want to delete this loadout?");

    //                     if (confirm && this.props.onDelete) {
    //                         this.props.onDelete(this.props.loadoutIndex);
    //                     }

    //                 }}
    //             >Delete</button> : null}
    //             {this.props.onEquip ? <button
    //                 onClick={() => {
    //                     if (this.props.onEquip) this.props.onEquip(loadout)
    //                 }}
    //             >Equip</button> : null}

    //         </div>
    //     </div >

    // }

}

export default Loadout;