import React from 'react';
import Destiny from '../Destiny.js';
import auto_rifle_icon from '../icons/weapon_types/auto_rifle.webp'
import combat_bow_icon from '../icons/weapon_types/combat_bow.webp'
import fusion_rifle_icon from '../icons/weapon_types/fusion_rifle.webp'
import glaive_icon from '../icons/weapon_types/glaive.webp'
import grenade_launcher_icon from '../icons/weapon_types/grenade_launcher.webp'
import hand_cannon_icon from '../icons/weapon_types/hand_cannon.webp'
import linear_fusion_rifle_icon from '../icons/weapon_types/linear_fusion_rifle.webp'
import machinegun_icon from '../icons/weapon_types/machinegun.webp'
import pulse_rifle_icon from '../icons/weapon_types/pulse_rifle.webp'
import rocket_launcher_icon from '../icons/weapon_types/rocket_launcher.webp'
import scout_rifle_icon from '../icons/weapon_types/scout_rifle.webp'
import shotgun_icon from '../icons/weapon_types/shotgun.webp'
import sidearm_icon from '../icons/weapon_types/sidearm.webp'
import sniper_rifle_icon from '../icons/weapon_types/sniper_rifle.webp'
import submachinegun_icon from '../icons/weapon_types/submachinegun.webp'
import sword_icon from '../icons/weapon_types/sword.webp'
import trace_rifle_icon from '../icons/weapon_types/trace_rifle.webp'

class ArmoryFilter extends React.Component {

    static category_order = [
        5,
        6,
        7,
        8,
        14,
        3954685534,
        3317538576,
        10,
        11,
        9,
        3871742104,
        2489664120,
        12,
        13,
        54,
        153950757,
        1504945536,
    ];

    // static category_order = [
    //     3922299008,	// auto_rifle
    //     4283176446,	// pulse_rifle
    //     4232286703,	// scout_rifle
    //     2463632503,	// hand_cannon
    //     1117493175,	// submachinegun
    //     3139631195,	// sidearm
    //     1893651977,	// fusion_rifle
    //     512274955,	// grenade_launcher
    //     2007318660,	// sniper_rifle
    //     2161169688,	// shotgun
    //     906486325,	// sword
    //     1513972773,	// rocket_launcher
    //     3102396114,	// trace_rifle
    //     3647376570,	// bow
    //     2821919357,	// machinegun
    //     1714469987,	// linear_fusion_rifle
    //     3790857370,	// glaive
    // ];

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    getWeaponIcon(categoryHash) {

        switch (categoryHash) {

            // case 3922299008: return auto_rifle_icon;
            // case 4283176446: return pulse_rifle_icon;
            // case 4232286703: return scout_rifle_icon;
            // case 2463632503: return hand_cannon_icon;
            // case 1117493175: return submachinegun_icon;
            // case 3139631195: return sidearm_icon;
            // case 1893651977: return fusion_rifle_icon;
            // case 512274955: return grenade_launcher_icon;
            // case 2007318660: return sniper_rifle_icon;
            // case 2161169688: return shotgun_icon;
            // case 906486325: return sword_icon;
            // case 1513972773: return rocket_launcher_icon;
            // case 3102396114: return trace_rifle_icon;
            // case 3647376570: return combat_bow_icon;
            // case 2821919357: return machinegun_icon;
            // case 1714469987: return linear_fusion_rifle_icon;
            // case 3790857370: return glaive_icon;

            case 5: return auto_rifle_icon;
            case 6: return hand_cannon_icon;
            case 7: return pulse_rifle_icon;
            case 8: return scout_rifle_icon;
            case 14: return sidearm_icon;
            case 3954685534: return submachinegun_icon;
            case 3317538576: return combat_bow_icon;

            case 10: return sniper_rifle_icon;
            case 11: return shotgun_icon;
            case 9: return fusion_rifle_icon;
            case 3871742104: return glaive_icon;
            case 2489664120: return trace_rifle_icon;

            case 12: return machinegun_icon;
            case 13: return rocket_launcher_icon;
            case 54: return sword_icon;
            case 153950757: return grenade_launcher_icon;
            case 1504945536: return linear_fusion_rifle_icon;

            default: return undefined;

        }

    }

    getTypeButton(title, type) {
        return <button
            className={"armory-filter-button item-type" + (this.props.type === type ? " selected" : "")}
            onClick={() => {
                if (this.props.onItemTypeSelected) this.props.onItemTypeSelected(type);
            }}
        >
            {title}
        </button>
    }

    getStaticControls() {
        return <div
            className="armory-filter-static-controls-container"
        >

            {this.getTypeButton("All Items", "all")}
            {this.getTypeButton("Weapons", "weapons")}
            {this.getTypeButton("Armor", "armor")}

        </div>
    }

    getWeaponTypeElement(categoryHash, key) {

        let categoryDefinition = Destiny.DestinyItemCategoryDefinition?.[categoryHash];

        // if(!categoryDefinition) return;

        let subType = categoryDefinition.grantDestinySubType;

        return <div
            key={key}
            className={"armory-filter-button weapon-type " + (this.props.selectedType === subType ? " selected" : "")}
            onClick={() => {
                if (!this.props.onTypeSelected) return;
                if (this.props.selectedType === subType) {
                    this.props.onTypeSelected(undefined);
                } else {
                    this.props.onTypeSelected(subType);
                }
            }}
        >

            <img
                className="weapon-type-icon"
                alt=""
                src={this.getWeaponIcon(categoryHash)}
            >
            </img>

        </div>

    }

    getWeaponTypeElements() {

        let elements = [];
        let key = 0;

        for (let categoryHash of ArmoryFilter.category_order) {

            elements.push(this.getWeaponTypeElement(categoryHash, key++));

        }

        return <div
            className="weapon-type-row-container"
        >
            {/* <div
                key={key++}
                className={"weapon-type-container " + (this.props.selectedType === undefined ? "selected" : "")}
                onClick={() => {
                    if (this.props.onTypeSelected) this.props.onTypeSelected(undefined);
                }}
            >
                <div
                    style={{
                        margin: "auto",
                        // border: "1px solid red",
                        width: "fit-content",
                        fontSize: "0.8em"
                    }}
                >
                    ALL
                </div>
            </div> */}

            <div
                key={key++}
                className={"armory-filter-button weapon-type " + (this.props.craftedSelected ? " selected" : "")}
                onClick={() => {
                    this.props.onCraftedSelected?.(!this.props.craftedSelected);
                }}
            >
                <div
                    style={{
                        margin: "auto",
                        // border: "1px solid red",
                        width: "fit-content",
                        fontSize: "0.8em"
                    }}
                >
                    CRAFTED
                </div>
            </div>

            <div
                key={key++}
                className={"armory-filter-button weapon-type " + (this.props.unlockedSelected ? " selected" : "")}
                onClick={() => {
                    this.props.onUnlockedSelected?.(!this.props.unlockedSelected);
                }}
            >
                <div
                    style={{
                        margin: "auto",
                        // border: "1px solid red",
                        width: "fit-content",
                        fontSize: "0.8em"
                    }}
                >
                    UNLOCKED
                </div>
            </div>
            
            {elements}
        </div>
    }

    getArmorTypeElements() {

    }

    render() {

        return <div className="armory-filter-controls-container">
            {this.getStaticControls()}
            {this.props.type === "weapons" ? this.getWeaponTypeElements() : null}
        </div>

    }

}

export default ArmoryFilter;