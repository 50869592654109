import Destiny from "./Destiny";

class DestinyData {

    constructor(props) {
        this.instanceItemStates = {}; // map from instanceId to state
        this.itemsByInstanceId = {}; // map from instanceId to itemData
        this.characterItems = {}; // map from characterId to bucketHash to { equippedItem: Object, inventoryItems: Array }
        this.profileItems = {}; // map from bucketHash to { inventoryItems: Array }
    }

    update(bulkData) {

        if(!bulkData) {
            console.error("Unable to read bulkData");
            return;
        }

        console.log("Processing DestinyData");

        // character inventories
        for (let characterId in bulkData.characterInventories.data) {
            let items = bulkData.characterInventories.data[characterId].items;
            let inventoryBuckets = {}
            for (let item of items) {
                let itemData = Destiny.getItemData(item, bulkData);
                let currentBucket = itemData.item.bucketHash;
                // create new character bucket if one doesn't exist
                if (!inventoryBuckets[currentBucket]) {
                    inventoryBuckets[currentBucket] = { inventoryItems: [] };
                }
                inventoryBuckets[currentBucket].inventoryItems.push(itemData);
                if (item.itemInstanceId) {
                    this.instanceItemStates[item.itemInstanceId] = {
                        inVault: false,
                        equipped: false,
                        characterId: characterId
                    }
                    this.itemsByInstanceId[item.itemInstanceId] = itemData;
                }
            }
            this.characterItems[characterId] = inventoryBuckets;
        }

        // character equipment
        for (let characterId in bulkData.characterEquipment.data) {
            let items = bulkData.characterEquipment.data[characterId].items;
            let inventoryBuckets = this.characterItems[characterId];
            for (let item of items) {
                let itemData = Destiny.getItemData(item, bulkData);
                let currentBucket = itemData.item.bucketHash;
                // create new character bucket if one doesn't exist
                if (!inventoryBuckets[currentBucket]) {
                    inventoryBuckets[currentBucket] = { inventoryItems: [] };
                }
                inventoryBuckets[currentBucket].equippedItem = itemData;
                if (item.itemInstanceId) {
                    this.instanceItemStates[item.itemInstanceId] = {
                        inVault: false,
                        equipped: true,
                        characterId: characterId
                    }
                    this.itemsByInstanceId[item.itemInstanceId] = itemData;
                }
            }
        }

        // account items
        let items = bulkData.profileInventory.data.items;
        for (let item of items) {
            let itemData = Destiny.getItemData(item, bulkData);
            let currentBucket = itemData.item.bucketHash;
            if (!this.profileItems[currentBucket]) {
                this.profileItems[currentBucket] = { inventoryItems: [] };
            }
            this.profileItems[currentBucket].inventoryItems.push(itemData);
            if (item.itemInstanceId) {
                this.instanceItemStates[item.itemInstanceId] = {
                    inVault: true,
                }
                this.itemsByInstanceId[item.itemInstanceId] = itemData;
            }
        }

    }

    printInventory() {
        for (let characterId in this.characterItems) {

            console.log("Character: " + characterId);

            let inventoryBuckets = this.characterItems[characterId];

            for (let bucketHash in inventoryBuckets) {

                let bucketDefinition = Destiny.DestinyInventoryBucketDefinition[bucketHash];
                console.log("\tBucket: " + bucketDefinition.displayProperties.name + " " + bucketHash);

                let bucketData = inventoryBuckets[bucketHash];

                if (bucketData.equippedItem) {
                    let equippedDefinition = Destiny.DestinyInventoryItemDefinition[bucketData.equippedItem.item.itemHash];
                    console.log("\t\tEquipped: " + equippedDefinition.displayProperties.name + " " + bucketData.equippedItem.item.itemInstanceId);
                }

                if (bucketData.inventoryItems.length > 0) {
                    console.log("\t\tInventory: " + bucketData.inventoryItems.length + " item(s)");
                    for (let itemData of bucketData.inventoryItems) {
                        let itemDefinition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
                        console.log("\t\t\t" + itemDefinition.displayProperties.name + " " + itemData.item.itemInstanceId);
                    }
                }


            }

        }
    }

    getItemState(itemInstanceId) {
        return this.instanceItemStates[itemInstanceId];
    }

    equipItemLocal(characterId, itemInstanceId) {

        let itemState = this.instanceItemStates[itemInstanceId];
        let itemData = this.itemsByInstanceId[itemInstanceId];

        if (!itemState) {
            console.error("DestinyData Equip: Could not find itemState. itemInstanceId: " + itemInstanceId);
            return;
        }

        if (!itemData) {
            console.error("DestinyData Equip: Could not find itemData. itemInstanceId: " + itemInstanceId);
            return;
        }

        if (itemState.characterId === characterId && itemState.equipped) {
            console.warn("DestinyData Equip: Item already equipped");
            return;
        }

        // get definition and bucketHash of the item we want to equip
        let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
        let equipBucketHash = definition.inventory.bucketTypeHash;

        // get inventory bucket for character and the item currently equipped
        let inventoryBucket = this.characterItems[characterId][equipBucketHash];
        let currentEquippedItem = inventoryBucket.equippedItem;

        if (!currentEquippedItem) {
            console.warn("DestinyData Equip: No item currently equipped. bucketHash: " + equipBucketHash);
        }

        let itemToEquip;
        let equipIndex;

        // find item to equip by instanceId
        for (let i = 0; i < inventoryBucket.inventoryItems.length; i++) {
            let itemData = inventoryBucket.inventoryItems[i];
            if (itemData.item.itemInstanceId === itemInstanceId) {
                itemToEquip = itemData;
                equipIndex = i;
                break;
            }
        }

        if (!itemToEquip) {
            console.error("DestinyData Equip: Could not find item to equip in bucket. itemInstanceId: " + itemInstanceId);
            return;
        }

        // set the new item as equipped
        inventoryBucket.equippedItem = itemToEquip;
        this.instanceItemStates[itemInstanceId] = { inVault: false, characterId, equipped: true };

        // if there was an old equipped item, swap them, else just remove
        // the newly equipped item from the inventory
        if (currentEquippedItem) {
            inventoryBucket.inventoryItems[equipIndex] = currentEquippedItem;
            this.instanceItemStates[currentEquippedItem.item.itemInstanceId] = { inVault: false, characterId, equipped: false };
        } else {
            inventoryBucket.inventoryItems.splice(equipIndex, 1);
        }

        console.log("DestinyData Equip: Success");

    }

    transferItemLocal(characterId, itemInstanceId, toVault) {

        let itemState = this.instanceItemStates[itemInstanceId];
        let itemData = this.itemsByInstanceId[itemInstanceId];

        if (!itemState) {
            console.error("DestinyData Transfer: Could not find itemState. itemInstanceId: " + itemInstanceId);
            return;
        }

        if (!itemData) {
            console.error("DestinyData Transfer: Could not find itemData. itemInstanceId: " + itemInstanceId);
            return;
        }

        if (itemState.characterId === characterId && itemState.equipped) {
            console.warn("DestinyData Transfer: Item already equipped");
            return;
        }

        // get definition and bucketHash of the item we want to equip
        let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
        let inventoryBucketHash = definition.inventory.bucketTypeHash;

        let characterBucket = this.characterItems[characterId][inventoryBucketHash];
        let vaultBucket = this.profileItems[Destiny.BUCKET_HASH_VAULT];

        let itemToTransfer;
        let itemIndex;

        // choose source and destination
        let sourceBucket = toVault ? characterBucket : vaultBucket;
        let destinationBucket = toVault ? vaultBucket : characterBucket;

        // find item
        for (let i = 0; i < sourceBucket.inventoryItems.length; i++) {
            let itemData = sourceBucket.inventoryItems[i];
            if (itemData.item.itemInstanceId === itemInstanceId) {
                itemToTransfer = itemData;
                itemIndex = i;
                break;
            }
        }

        if (!itemToTransfer) {
            console.error("DestinyData Transfer: Could not find item to transfer. itemInstanceId: " + itemInstanceId);
            return;
        }

        // remove item from source and add to definition
        sourceBucket.inventoryItems.splice(itemIndex, 1);
        destinationBucket.inventoryItems.push(itemToTransfer);

        // update state
        if (toVault) {
            this.instanceItemStates[itemInstanceId] = { inVault: true };
        } else {
            this.instanceItemStates[itemInstanceId] = { inVault: false, characterId, equipped: false };
        }

        console.log("DestinyData Transfer: Success");

    }


}

export default DestinyData;