import React from 'react';

class Pager extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageIndex: this.props.pageIndex,
            displayedPageIndex: 0,
            cachedPage: undefined,
        }

        this.containerRef = React.createRef();

        // for tracking whether the animation end listener is 
        // fired when the old pade is finished animating out
        this.animatingExit = false;

        this.nextPageIndex = undefined;
        this.cachedPage = undefined;
        this.showCachedPage = false;

        // watch for animation end events and swap content when needed
        this.animationListener = (e) => {
            if (this.animatingExit) {
                this.animatingExit = false;

                // mid animation, the page content is swapped for the
                // cached page
                this.showCachedPage = true;

                this.animateTransition(false, this.state.pageIndex, this.nextPageIndex);
                this.setState({
                    pageIndex: this.nextPageIndex,
                    displayedPageIndex: this.nextPageIndex,
                    cachedPage: this.cachedPage
                });
            }
        }

    }


    componentDidMount() {
        this.containerRef.current.addEventListener("animationend", this.animationListener);
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener("animationend", this.animationListener);
    }

    // request the page content from the parent component
    renderPage(pageIndex) {
        // console.log("Pager renderPage()");
        if (this.props.renderPage) {
            return this.props.renderPage(pageIndex);
        }
        return undefined;
    }

    // animate the page transition given a current and next page,
    // and a boolean for whether the transition is exiting the old page,
    // as opposed to entering the new page
    animateTransition(isExit, fromPage, toPage) {
        let pageForward = toPage > fromPage;
        let element = this.containerRef.current;
        if (isExit) {
            element.style["animation-name"] = pageForward ? "pager-page-out-left" : "pager-page-out-right";
            element.style["animation-timing-function"] = "cubic-bezier(0.5, 0, 1, 0.5)";
        } else {
            element.style["animation-name"] = pageForward ? "pager-page-in-right" : "pager-page-in-left";
            element.style["animation-timing-function"] = "cubic-bezier(0, 0.5, 0.5, 1)";
        }
    }

    // use shouldComponentUpdate to start animation of page change
    // when page index is changed
    shouldComponentUpdate(nextProps) {
        if (nextProps.pageIndex !== this.props.pageIndex) {
            // console.log(`pageChange: ${this.props.pageIndex} -> ${nextProps.pageIndex}`);

            this.animatingExit = true;

            // render the next page content BEFORE the anim,
            // cache it, then swap it out mid anim for most 
            // smooth animation
            this.nextPageIndex = nextProps.pageIndex;
            this.cachedPage = this.renderPage(nextProps.pageIndex);

            // animate current page exit and defer render to after animation is over
            this.animateTransition(true, this.props.pageIndex, nextProps.pageIndex);

            // TODO: check if this is bad
            return false;

        }
        return true;
    }

    render() {

        // console.log("Pager render()");

        // the cached page is only shown while there have been no
        // page updates. if there are updates, the new page content will
        // be rendered here
        let pageContent;
        if (this.showCachedPage) {
            pageContent = this.state.cachedPage;
            this.showCachedPage = false;
        } else {
            pageContent = this.renderPage(this.state.displayedPageIndex);
        }

        return <div
            className="pager-content-container"
            ref={this.containerRef}
            style={{ ...this.props.style }}
        >
            {pageContent}
        </div>

    }


}

export default Pager;