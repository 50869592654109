import React from 'react';

class Setting extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    render() {

        let options = this.props.options;
        let index = this.props.index;

        let currentOption = options?.[index];

        return <div className={"setting-container " + (this.props.primary ? "primary" : "")}>
            <div className="setting-label">{this.props.label || ""}</div>
            <div
                className="setting-option"
                onClick={() => {

                    if (!this.props.onSelect) return;

                    let newIndex = index + 1;
                    if (newIndex >= options.length) newIndex = 0;

                    this.props.onSelect(newIndex, options[newIndex].value);

                }}
            >{currentOption ? currentOption.label : "No Options"}</div>
        </div>

    }

}

export default Setting;