import React from 'react';
import Loadout from './Loadout.js';

class LoadoutSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    // render() {

    //     let elements = [];

    //     for (let i = 0; i < 10; i++) {

    //         elements.push(<div
    //             className="loadout-2"
    //             style={{
    //                 height: Math.random() * 400 + 300,
    //             }}
    //         >Test</div>)

    //     }

    //     return <div className="loadout-container-2">
    //         {elements}
    //     </div>

    // }

    render() {

        let key = 0;
        let loadoutElements = [];

        // show current loadout at the top of the component
        loadoutElements.push(<Loadout
            key={key++}
            loadout={this.props.currentLoadout}
            isCurrentLoadout={true}
            loadoutIndex={-1}
            onSave={this.props.onSave}
        />);

        if (this.props.loadouts) {

            // loop thru all loadouts and add them to the component
            for (let i = 0; i < this.props.loadouts.length; i++) {

                let loadout = this.props.loadouts[i];

                // show loadouts only for the same class
                if (this.props.classType !== undefined && loadout.classType !== this.props.classType) continue;

                loadoutElements.push(<Loadout
                    key={key++}
                    loadout={loadout}
                    loadoutIndex={i}
                    onExpand={(loadoutIndex) => {
                        this.props.onExpand?.(loadoutIndex);
                    }}
                    onEquip={(loadout) => {
                        if (this.props.onEquip) this.props.onEquip(loadout)
                    }}
                    onDelete={(loadoutIndex) => {
                        if (this.props.onDelete) this.props.onDelete(loadoutIndex)
                    }}
                />);
            }
        }

        return <div
            className="loadout-selector-container-2"
        >
            {loadoutElements}
        </div>

    }

}

export default LoadoutSelector;