import React from "react";
import Destiny from "../Destiny";

class LoadoutDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let loadout = this.props.loadout;

        if (!loadout) return null;

        let itemElements = [];

        for (let itemData of loadout.items) {

            let itemDefinition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];

            let sockets = itemData.sockets?.sockets;

            if (!sockets) continue;

            let modElements = [];

            for (let socket of sockets) {

                if (!socket.isVisible || !socket.plugHash) continue;

                let plugDefinition = Destiny.DestinyInventoryItemDefinition[socket.plugHash];

                modElements.push(<div
                    className="loadout-details-plug-img-container"
                    data-name={plugDefinition.displayProperties.name}
                >
                    <img
                        alt=""
                        className="loadout-details-plug-img"
                        src={Destiny.BNET + plugDefinition.displayProperties.icon}
                    ></img>
                </div>)

            }

            itemElements.push(<div>
                <img alt="" className="loadout-details-item-img" src={Destiny.BNET + itemDefinition.displayProperties.icon}></img>
                <h3 style={{ display: "inline" }}>{itemDefinition.displayProperties.name}</h3>
                <div style={{display: "flex", gap: 10}}>{modElements}</div>
            </div>);

        }

        return <div className="loadout-details-component-container">
            {itemElements}
        </div>

    }

}

export default LoadoutDetails;