import React from 'react';
import Destiny from '../Destiny.js';
import DestinyUtils from '../DestinyUtils.js';

class StatBars extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    // getStatBar(stat, compareStat, isArmor) {

    //     let statDisplayProperties = DestinyUtils.StatDisplayProperties[stat.statHash];
    //     let definition = Destiny.DestinyStatDefinition[stat.statHash];

    //     let totalStat = stat.value + diff;

    //     let statAbs = Math.abs(stat.value);
    //     let diffAbs = Math.abs(diff);
    //     let totalAbs = Math.abs(totalStat);

    //     let maxStat = isArmor ? 42 : 100;

    //     let statPercent = statAbs * 100 / maxStat;
    //     let diffPercent = diffAbs * 100 / maxStat;
    //     let totalPercent = totalAbs * 100 / maxStat;

    //     let goodColor = "lightgreen";
    //     let badColor = "#eb4034";

    //     return <div
    //         className="stat-container"
    //     >
    //         <div
    //             className="stat-title"
    //         >
    //             {definition.displayProperties.name}
    //         </div>

    //         {statDisplayProperties.isBar ? <div
    //             className="stat-bar-container"
    //         >
    //             <div
    //                 style={{
    //                     width: (diffIsCompare ? statPercent : totalPercent) + "%",
    //                     backgroundColor: (diffIsCompare ? ("white") : (totalStat < 0 ? badColor : undefined))
    //                 }}
    //                 className="stat-bar"
    //             ></div>

    //             {diffIsCompare || (diff !== 0 && totalStat > 0) ? <div
    //                 style={{
    //                     position: "absolute",
    //                     left: (diffIsCompare ? (diff > 0 ? statPercent - diffPercent : statPercent) : (statPercent + (diff < 0 ? -diffPercent : 0))) + "%",
    //                     width: (diffIsCompare ? (diffPercent) : Math.min(diffPercent, 101 - statPercent)) + "%",
    //                     backgroundColor: diff < 0 ? badColor : goodColor
    //                 }}
    //                 className="stat-bar"
    //             ></div> : null}
    //         </div> : null}

    //         <div
    //             className="stat-value"
    //             style={{
    //                 color: diff < 0 ? badColor : diff > 0 ? goodColor : undefined
    //             }}
    //         >
    //             {diffIsCompare ?
    //                 ((isArmor ? "+" : "") + (statDisplayProperties.isBar ? Math.min(stat.value, maxStat) : stat.value)) :
    //                 ((isArmor ? "+" : "") + (statDisplayProperties.isBar ? Math.min(totalStat, maxStat) : totalStat))
    //             }
    //         </div>


    //     </div>

    // }

    getStatBar(stat, diff, diffIsCompare, isArmor, showLabel) {

        let statDisplayProperties = DestinyUtils.StatDisplayProperties[stat.statHash];
        let definition = Destiny.DestinyStatDefinition[stat.statHash];

        let totalStat = stat.value + diff;

        let statAbs = Math.abs(stat.value);
        let diffAbs = Math.abs(diff);
        let totalAbs = Math.abs(totalStat);

        let maxStat = isArmor ? 42 : 100;

        let statPercent = statAbs * 100 / maxStat;
        let diffPercent = diffAbs * 100 / maxStat;
        let totalPercent = totalAbs * 100 / maxStat;

        let goodColor = "lightgreen";
        let badColor = "#eb4034";

        return <div
            className="stat-container"
        >
            {showLabel ? <div
                className="stat-title"
            >
                {definition.displayProperties.name}
            </div> : null}

            {statDisplayProperties.isBar ? <div
                className="stat-bar-container"
            >
                <div
                    style={{
                        width: (diffIsCompare ? statPercent : totalPercent) + "%",
                        backgroundColor: (diffIsCompare ? ("white") : (totalStat < 0 ? badColor : undefined))
                    }}
                    className="stat-bar"
                ></div>

                {diffIsCompare || (diff !== 0 && totalStat > 0) ? <div
                    style={{
                        position: "absolute",
                        left: (diffIsCompare ? (diff > 0 ? statPercent - diffPercent : statPercent) : (statPercent + (diff < 0 ? -diffPercent : 0))) + "%",
                        width: (diffIsCompare ? (diffPercent) : Math.min(diffPercent, 101 - statPercent)) + "%",
                        backgroundColor: diff < 0 ? badColor : goodColor
                    }}
                    className="stat-bar"
                ></div> : null}
            </div> : null}

            <div
                className="stat-value"
                style={{
                    color: diff < 0 ? badColor : diff > 0 ? goodColor : undefined
                }}
            >
                {diffIsCompare ?
                    ((isArmor ? "+" : "") + (statDisplayProperties.isBar ? Math.min(stat.value, maxStat) : stat.value)) :
                    ((isArmor ? "+" : "") + (statDisplayProperties.isBar ? Math.min(totalStat, maxStat) : totalStat))
                }
            </div>


        </div>

    }

    getStatElements(stats, compareStats, isArmor, showLabel) {

        let elements = [];
        let sortedStats = [];

        // add all stats to array to sort them
        for (let statHash in stats) {
            sortedStats.push(stats[statHash]);
        }

        // sort stats by assigned index
        sortedStats.sort((stat1, stat2) => {
            let stat1Props = DestinyUtils.StatDisplayProperties[stat1.statHash];
            let stat2Props = DestinyUtils.StatDisplayProperties[stat2.statHash];
            return stat1Props.index - stat2Props.index;
        });


        // create stat bar elements
        for (let stat of sortedStats) {

            let diff = 0;
            if (compareStats?.[stat.statHash]) {
                diff = stat.value - compareStats[stat.statHash].value;
            }

            elements.push(this.getStatBar(stat, diff, true, isArmor, showLabel));
        }

        return elements;

    }

    render() {

        if (!this.props.stats) return null;

        console.log(this.props.stats);
        console.log(this.props.compareStats);

        return <div
            className="stat-display-container"
            style={this.props.style}
        >
            {this.getStatElements(this.props.stats, this.props.compareStats, this.props.isArmor, !this.props.noLabels)}
        </div>;

    }

}

export default StatBars;