import React from 'react';
import Destiny from '../Destiny.js';

class LoadoutEquip extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        let progressBarProp = this.props.modCount === 0 ? 0 : this.props.modsEquipped / this.props.modCount;
        // let failedModHashes = this.props.failedModHashes || [];
        let failedModEquips = this.props.failedModEquips || [];
        let failedModCount = failedModEquips.length;

        let failedModElements = [];

        for (let i = 0; i < failedModEquips.length; i++) {

            let failedModEquip = failedModEquips[i];

            let itemDefinition = Destiny.DestinyInventoryItemDefinition[failedModEquip.itemHash];
            let modDefinition = Destiny.DestinyInventoryItemDefinition[failedModEquip.plugItemHash];
            let bucketDefinition = Destiny.DestinyInventoryBucketDefinition[itemDefinition.equippingBlock?.equipmentSlotTypeHash];

            let bucketName;
            if (bucketDefinition) {
                bucketName = bucketDefinition.displayProperties.name;
            }

            let modName = modDefinition?.displayProperties?.name || "Unknown Mod";
            let modIcon = modDefinition?.displayProperties?.icon;

            let failedModElement = <div
                key={i}
                className="loadout-equip-mod"
                data-name={modName + (bucketName ? ` (${bucketName})` : "")}
            >
                {modIcon ? <img
                    className="loadout-equip-mod-icon"
                    alt=""
                    src={Destiny.BNET + modIcon}
                /> : null}
            </div>

            failedModElements.push(failedModElement);

        }

        return <div className="loadout-equip-container">

            <div className="loadout-equip-header">
                <h1 className="loadout-equip-title">
                    {this.props.title || "Equip a Loadout"}
                </h1>

            </div>

            <div className="loadout-equip-content">
                <h3 className="loadout-equip-subtitle">
                    {"You must be in Orbit or a Social Space to equip a Loadout"}
                </h3>
                {failedModCount > 0 ? <>
                    <div>{failedModCount + " mod(s) timed out. Some may not be equipped:"}</div>
                    <div className="loadout-equip-mods-container">{failedModElements}</div>
                </> : null}
            </div>

            <div className="loadout-equip-progress-bar-container">
                <div
                    className="loadout-equip-progress-bar"
                    style={{
                        width: (progressBarProp * 100) + "%",
                    }}
                />
            </div>

        </div>

    }

}

export default LoadoutEquip;