import React from 'react';
import settings_icon from '../icons/settings.svg';

class TabSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    getTabContent(name) {
        if (name.toLowerCase() === "[settings]") {
            return <img
                alt=""
                src={settings_icon}
            />
        } else {
            return <div>{name}</div>
        }
    }

    render() {

        let tabNames = this.props.tabNames;

        let tabElements = [];

        // loop thru tab names and add elements for each
        for (let i = 0; i < tabNames.length; i++) {

            // inner element may be icon or text
            let tabContent = this.getTabContent(tabNames[i]);

            let tabElement = <div
                key={i}
                className={"menu-tab" + (this.props.selectedTab === i ? " selected" : "")}
                onClick={() => {
                    if (this.props.onTabSelected) this.props.onTabSelected(i);
                }}
            >
                {tabContent}
            </div>

            tabElements.push(tabElement);

        }

        return <div
            className="menu-tabs-container"
            // style={{ ...this.props.style }}
        >
            <div className="hotkey">A</div>
            {tabElements}
            <div className="hotkey">D</div>
        </div>

    }

}

export default TabSelector;