import React from 'react';
import Destiny from '../Destiny.js';
import titanIcon from "../icons/classes/titan.svg";
import warlockIcon from "../icons/classes/warlock.svg";
import hunterIcon from "../icons/classes/hunter.svg";

class Character extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    getClassIcon(classHash) {

        switch (classHash) {
            case 3655393761: return titanIcon;
            case 671679327: return hunterIcon;
            case 2271682572: return warlockIcon;
            default: return undefined;
        }

    }

    render() {

        let characterData = this.props.characterData;

        return <div
            className={"character-container" + (this.props.selected ? " selected" : "")}
            onClick={this.props.onClick}
        >

            <img
                alt=""
                className="character-class-icon"
                src={this.getClassIcon(characterData.classHash)}
            />


            {/* <div
                className="character-info-container"
            >
                <img
                    alt=""
                    className="character-class-icon"
                    src={this.getClassIcon(characterData.classHash)}
                />

                 <h2
                    className="character-class-title"
                >{this.props.special ? this.props.title : Destiny.DestinyClassDefinition[characterData.classHash].displayProperties.name}</h2> 

                 {!this.props.special ? <div>{characterData.light}</div> : null} 

            </div>

             <img
                alt=""
                className="char-img"
                src={this.props.special ?
                    Destiny.BNET + "/common/destiny2_content/icons/c9045e341668eb434b68cd8d02e3ab21.jpg" :
                    Destiny.BNET + characterData.emblemBackgroundPath
                }
            />  */}
        </div>

    }

}

export default Character;