import React from 'react';
import Destiny from '../Destiny.js';

class ProfileSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedId: undefined,
            selectedProfile: undefined,
        }
    }

    render() {

        let profileData = this.props.profileData;

        let profileElements = [];

        if (profileData) {

            for (let profile of profileData.profiles) {

                console.log(profile);

                profileElements.push(<div
                    className={"profile-card" + (this.state.selectedId === profile.membershipId ? " selected" : "")}
                    onClick={() => this.setState({ selectedId: profile.membershipId, selectedProfile: profile })}
                >
                    <h3
                        className="profile-title"
                    >{profile.displayName}</h3>
                    <div>{Destiny.getMembershipTypeName(profile.membershipType)}</div>
                </div>);

            }

        }

        return <div
            className="full-page-container"
        >
            <div className="profile-selector-container">

                <h2 className="profile-selector-title">Choose Your Profile</h2>

                <div className="profile-selector-content">
                    {profileElements.length === 0 ? "Loading Profiles..." : ""}
                    {profileElements}
                </div>

                <div className="profile-selector-controls">

                    <button
                        onClick={() => {
                            if (this.props.onCancel) {
                                this.props.onCancel();
                            }
                        }}
                    >Cancel</button>

                    <button
                        disabled={!this.state.selectedId}
                        onClick={() => {
                            if (this.props.onProfileSelected) {
                                this.props.onProfileSelected(this.state.selectedProfile);
                            }
                        }}
                    >Choose Profile</button>

                </div>

            </div>
        </div>

    }

}

export default ProfileSelector;