import React from 'react';
import Destiny from '../Destiny.js';
import DestinyUtils from '../DestinyUtils.js';
import craftedIcon from '../icons/crafted.png'
import masterwork_overlay from '../icons/masterwork_overlay.png'
import StatBars from "./StatBars";

class Item extends React.Component {

    static TOP_STAT_COUNT = 2;

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    getPerkPreview(itemData, definition) {

        let itemSockets = itemData.sockets?.sockets;
        let reusablePlugs = itemData.reusablePlugs?.plugs;
        let socketCategories = definition.sockets?.socketCategories;
        let socketEntries = definition.sockets?.socketEntries;

        let isLegacy = false;

        // find socket category for weapon perks
        let weaponPerksCategory;
        for (let category of socketCategories) {
            if (category.socketCategoryHash === Destiny.SOCKET_CATEGORY_HASH_WEAPON_PERKS) {
                weaponPerksCategory = category;
                break;
            }
        }

        if (!weaponPerksCategory) return;

        // loop thru weapon perk columns
        for (let i = 0; i < weaponPerksCategory.socketIndexes.length; i++) {

            let columnIndex = weaponPerksCategory.socketIndexes[i];
            let socketEntry = socketEntries[columnIndex];

            if (socketEntry.socketTypeHash === Destiny.SOCKET_TYPE_HASH_TRACKERS) continue;

            let uniquePerkHashes = new Set();

            if (socketEntry.randomizedPlugSetHash) {

                let randomPlugSetDefinition = Destiny.DestinyPlugSetDefinition[socketEntry.randomizedPlugSetHash];

                let possiblePlugItems = [...randomPlugSetDefinition.reusablePlugItems];

                // possiblePlugItems.sort((p1, p2) => {
                //     return (p2.currentlyCanRoll ? 1 : 0) - (p1.currentlyCanRoll ? 1 : 0);
                // });

                for (let plugItem of possiblePlugItems) {

                    if (uniquePerkHashes.has(plugItem.plugItemHash)) continue; // some items display the same perk twice, so skip them
                    uniquePerkHashes.add(plugItem.plugItemHash);

                    let perkDef = Destiny.DestinyInventoryItemDefinition[plugItem.plugItemHash];
                    let perkName = perkDef?.displayProperties?.name;

                    let perkIsLegacy = plugItem.currentlyCanRoll === false;

                    // TODO: find a better way to check for enhanced perks
                    let perkIsEnhanced = Destiny.isEnhancedPerk(perkDef);

                    // check if perk is rolled and selected
                    let perkIsSelected = itemSockets[columnIndex].plugHash === plugItem.plugItemHash;

                    // check if perk is rolled
                    let perkIsRolled = false;
                    if (reusablePlugs[columnIndex]) {
                        for (let rollPlugItem of reusablePlugs[columnIndex]) {
                            if (rollPlugItem.plugItemHash === plugItem.plugItemHash) {
                                perkIsRolled = true;
                                break;
                            }
                        }
                    }

                    // skip enhanced perks that are not rolled
                    if (!this.state.showEnhancedPerks && perkIsEnhanced && !perkIsSelected && !perkIsRolled) continue;

                    // skip perks that are not able to roll and are not on the current roll
                    if (!perkIsSelected && !perkIsRolled && perkIsLegacy) continue;

                    if (perkIsRolled && perkIsLegacy) {
                        isLegacy = true;
                        // console.log(definition.displayProperties.name + ": " + perkName + " (legacy)")
                        break;
                    }

                }

            }
        }

        let perkElements = [];

        let key = 0;

        for (let socketIndex = 0; socketIndex < itemSockets.length; socketIndex++) {

            let socket = itemSockets[socketIndex];

            let columnSize = reusablePlugs?.[socketIndex]?.length || 1;

            if (!socket.isEnabled || !socket.isVisible) continue;

            let plugDef = Destiny.getItemDefinition(socket.plugHash);

            if (!plugDef) {
                console.error("No plug definition for " + socket.plugHash);
                continue;
            }

            // only show main perks
            if (plugDef.plug.plugCategoryHash !== Destiny.PLUG_CATEGORY_HASH_FRAMES) continue;

            let iconSrc = plugDef.displayProperties.icon;

            perkElements.push(<div
                className={"item-perk-preview-icon" + (columnSize > 1 ? " multi" : "")}
            ><img
                key={key++}
                alt=""
                src={Destiny.BNET + iconSrc}
            ></img></div>)

        }

        if (perkElements.length === 0) return null;

        return <div
            className={"item-perk-preview-container" + (isLegacy ? " legacy" : "")}
        >{perkElements}</div>;

    }

    getWeaponPreview() {

        let itemSockets = this.props.itemData.sockets?.sockets;

        if (!itemSockets) return null;

        let perkElements = [];

        let key = 0;

        for (let socketIndex = 0; socketIndex < itemSockets.length; socketIndex++) {

            let socket = itemSockets[socketIndex];

            if (!socket.isEnabled || !socket.isVisible) continue;

            let plugDef = Destiny.getItemDefinition(socket.plugHash);

            if (!plugDef) {
                console.error("No plug definition for " + socket.plugHash);
                continue;
            }

            // only show main perks
            if (plugDef.plug.plugCategoryHash !== Destiny.PLUG_CATEGORY_HASH_FRAMES) continue;

            let iconSrc = plugDef.displayProperties.icon;

            perkElements.push(<img
                key={key++}
                alt=""
                className="item-perk-preview-icon"
                src={Destiny.BNET + iconSrc}
            ></img>)

        }

        if (perkElements.length === 0) return null;

        return <div
            className="item-perk-preview-container"
        >{perkElements}</div>;

    }

    getArmorPreview() {

        if (!this.props.itemData.stats) return null;

        let elements = [];

        let stats = this.props.itemData.stats.stats;
        let statsSorted = [];
        let totalStat = 0;

        // get total stat roll
        for (let id in stats) {
            let stat = stats[id];
            totalStat += stat.value;
            statsSorted.push(stat);
        }

        // display total stat roll
        elements.push(<div
            key={0}
            className="item-stat-preview"
            style={{ fontWeight: 'bold' }}
        >
            {`+${totalStat}`}
        </div>)

        // sort stats by descending value
        statsSorted.sort((stat1, stat2) => {
            return stat2.value - stat1.value;
        })


        // get limited number of top stats sorted by in-game stat order
        // let topStats = statsSorted.slice(0, Item.TOP_STAT_COUNT);
        // topStats.sort((stat1, stat2) => {
        //     let stat1Index = DestinyUtils.StatDisplayProperties[stat1.statHash].index;
        //     let stat2Index = DestinyUtils.StatDisplayProperties[stat2.statHash].index;
        //     return stat1Index - stat2Index;
        // })

        // for (let i = 0; i < topStats.length; i++) {

        //     let stat = topStats[i];

        //     // skip minimum stat values
        //     if(stat.value <= 2) continue;

        //     let definition = Destiny.DestinyStatDefinition[stat.statHash];
        //     // let name = definition.displayProperties.name;
        //     let iconSrc = definition.displayProperties.icon;
        //     elements.push(<div
        //         key={i + 1}
        //         className="item-stat-preview"
        //     >
        //         {"+" + stat.value}
        //         <img
        //             alt=""
        //             src={Destiny.BNET + iconSrc}
        //             style={{
        //                 width: 18,
        //                 height: 18,
        //                 display: "inline-block"
        //             }}
        //         ></img>
        //     </div>)
        // }

        return <div
            className="item-stat-preview-container"
        >{elements}</div>;

    }

    getItemIcon(itemData, definition) {
        let iconSrc;
        if (itemData.item.overrideStyleItemHash) {
            let styleItemDefinition = Destiny.getItemDefinition(itemData.item.overrideStyleItemHash);
            iconSrc = Destiny.BNET + styleItemDefinition.displayProperties.icon;
        } else if (definition.displayProperties.hasIcon) {
            iconSrc = Destiny.BNET + definition.displayProperties.icon;
        }
        return iconSrc;
    }

    getItemPower(itemData) {
        if (itemData.instances && itemData.instances.primaryStat) {
            let primaryStat = itemData.instances.primaryStat;
            if (primaryStat.statHash === Destiny.STAT_HASH_WEAPON_POWER || primaryStat.statHash === Destiny.STAT_HASH_ARMOR_POWER) {
                return primaryStat.value;
            }
        }
    }

    getStackSizeDisplay(itemData, definition) {
        // do not show if item is single
        if (itemData.item.quantity <= 1) return;

        let isMax = definition.inventory.maxStackSize === itemData.item.quantity;

        return <div className={"stack-size-display" + (isMax ? " max" : "")}>
            {itemData.item.quantity}
        </div>
    }

    getPowerDisplay(itemData) {

        let power = this.getItemPower(itemData);

        let itemInstance = itemData.instances;
        let damageType
        let damageTypeDisplayProps;

        if (itemInstance) {
            damageType = itemInstance.damageType;
            let def = Destiny.DestinyDamageTypeDefinition[itemInstance.damageTypeHash];
            if (def) damageTypeDisplayProps = def.displayProperties;
        }

        return this.props.showPower && (power !== undefined) ? <div
            className="item-power-display"
        // style={{
        //     color: Destiny.DamageTypeColors[damageType]
        // }}
        >
            {damageType !== 1 && damageTypeDisplayProps ?
                <img
                    alt=""
                    className="item-power-energy-icon"
                    src={Destiny.BNET + damageTypeDisplayProps.icon}
                ></img>
                : null}
            {power}
        </div> : null;
    }

    getDeepsightDisplay(deepsightData) {

        if (!deepsightData) return;

        return <div
            style={{
                position: "absolute",
                height: "10px",
                width: "100%",
                bottom: 0,
                left: 0,
            }}
        >
            <div
                style={{
                    backgroundColor: "rgb(22, 170, 0)",
                    width: (deepsightData.progress * 100) + "%",
                    height: "100%"
                }}
            />
        </div>
    }

    getCraftedDisplay(crafted) {

        if (!crafted) return;

        return <div
            className="item-crafted-background"
        >
            <img
                alt=""
                src={craftedIcon}
                className="item-crafted-icon"
            />
        </div>
    }

    render() {

        // console.log("item render");

        let itemData = this.props.itemData;

        // get definition
        let definition = Destiny.getItemDefinition(itemData.item.itemHash);

        let isWeapon = definition.itemType === Destiny.ITEM_TYPE_WEAPON;
        let isArmor = definition.itemType === Destiny.ITEM_TYPE_ARMOR;

        // pick icon to show
        let iconSrc = this.getItemIcon(itemData, definition);

        // get watermark
        let watermarkSrc = definition.iconWatermark ? Destiny.BNET + definition.iconWatermark : undefined;

        // extract deepsight info

        let isMasterworked = itemData.item.state & Destiny.STATE_MASTERWORK;
        let isCrafted = itemData.item.state & Destiny.STATE_CRAFTED;
        let isDeepsight = itemData.item.state & Destiny.STATE_HIGHLIGHTED_OBJECTIVE;

        let isSubclass = itemData.item.bucketHash === Destiny.BUCKET_HASH_SUBCLASS;

        let deepsightData;
        if (isDeepsight) {
            deepsightData = Destiny.getDeepsightData(itemData);
        }

        return <div
            className={"item-wrapper" + (isSubclass ? " subclass" : "")}
        >
            <div
                className={
                    "item" +
                    (isMasterworked ? " masterwork" : "") +
                    (isDeepsight ? " deepsight" : "")
                }
                draggable={!definition.nonTransferrable}
                onClick={this.props.onClick}
                onDragStart={this.props.onDragStart}
                onDrag={this.props.onDrag}
                onDragEnd={this.props.onDragEnd}
                onMouseDown={this.props.onMouseDown}
                // onMouseEnter={this.props.onMouseEnter}
                // onMouseLeave={this.props.onMouseLeave}
            >

                {iconSrc ? <img
                    alt=""
                    className="item-icon"
                    src={iconSrc}
                /> : null}

                {watermarkSrc ? <img
                    alt=""
                    className="item-watermark"
                    src={watermarkSrc}
                /> : null}

                <div
                    className="item-details-container"
                    style={{
                        visibility: (this.props.showPower || this.props.showPerkPreview) ? "visible" : "hidden"
                    }}
                >
                    {this.getPowerDisplay(itemData)}
                    {/* {this.props.showPerkPreview && isWeapon ? this.getWeaponPreview() : null} */}
                    {this.props.showPerkPreview && isWeapon ? this.getPerkPreview(itemData, definition) : null}
                    {this.props.showPerkPreview && isArmor ? this.getArmorPreview() : null}
                    {this.getDeepsightDisplay(deepsightData)}
                    {this.getCraftedDisplay(isCrafted)}
                </div>

                {this.getStackSizeDisplay(itemData, definition)}

                {/* {masterwork ? <img
                    alt=""
                    className="item-masterwork-overlay"
                    // src={masterwork_overlay}
                /> : null} */}

            </div>

        </div>

    }

}

export default Item;