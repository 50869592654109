import DestinyData from "./DestinyData.js";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";

class Destiny {

    // actual static variables
    static isRefreshing = false;
    static cachedItemUpdates = [];
    static destinyData = new DestinyData();

    static RECENT_ITEMS_SIZE = 20;
    static recentItems = [];

    // constants ---------------------------------------------------------------------------------------------------------------------------------
    static AUTH_LINK = "https://www.bungie.net/en/oauth/authorize?client_id=35739&response_type=code&state=6i0mkLx79Hp91nzWVeHrzHG4";
    static BNET = "https://www.bungie.net";

    static API_KEY = "ffeacd85f2cf4fa3a5553bae633c66e7";

    // item state
    static STATE_LOCKED = 1;
    static STATE_TRACKED = 2;
    static STATE_MASTERWORK = 4;
    static STATE_CRAFTED = 8;
    static STATE_HIGHLIGHTED_OBJECTIVE = 16; // deepsight

    static PLUG_CATEGORY_HASH_FRAMES = 7906839;
    static PLUG_CATEGORY_HASH_INTRINSICS = 1744546145;
    static PLUG_CATEGORY_HASH_ORIGIN_TRAITS = 164955586;
    // static PLUG_CATEGORY_HASH_MASTERWORKS = 199786516;
    // static PLUG_CATEGORY_HASH_WEAPON_MODS = 3945646995;


    static PLUG_HASH_DEEPSIGHT = 3394691176;
    static PLUG_HASH_CRAFTING_LEVEL = 1922808508;

    // bucket hashes
    static BUCKET_HASH_VAULT = 138197802;
    static BUCKET_HASH_POSTMASTER = 215593132;
    static BUCKET_HASH_SUBCLASS = 3284755031;

    // item types
    static ITEM_TYPE_ARMOR = 2;
    static ITEM_TYPE_WEAPON = 3;

    // ammo types
    static AMMO_TYPE_PRIMARY = 1;
    static AMMO_TYPE_SPECIAL = 2;
    static AMMO_TYPE_HEAVY = 3;

    // damage types
    static DAMAGE_TYPE_KINETIC = 1;

    // class types
    static CLASS_TYPE_TITAN = 0;
    static CLASS_TYPE_HUNTER = 1;
    static CLASS_TYPE_WARLOCK = 2;

    // stat hashes
    static STAT_HASH_WEAPON_POWER = 1480404414;
    static STAT_HASH_ARMOR_POWER = 3897883278;

    // socket category hashes
    static SOCKET_CATEGORY_HASH_ARMOR_MODS = 590099826;

    static SOCKET_CATEGORY_HASH_WEAPON_PERKS = 4241085061;
    static SOCKET_CATEGORY_HASH_WEAPON_INTRINSICS = 3956125808;
    static SOCKET_CATEGORY_HASH_WEAPON_COSMETICS = 2048875504;
    static SOCKET_CATEGORY_HASH_WEAPON_MODS = 2685412949;

    static SOCKET_CATEGORY_HASH_ABILITIES_1 = 309722977;
    static SOCKET_CATEGORY_HASH_ABILITIES_2 = 3218807805;
    static SOCKET_CATEGORY_HASH_SUPER = 457473665;
    static SOCKET_CATEGORY_HASH_ASPECTS_1 = 2140934067;
    static SOCKET_CATEGORY_HASH_ASPECTS_2 = 3400923910;
    static SOCKET_CATEGORY_HASH_FRAGMENTS_1 = 1313488945;
    static SOCKET_CATEGORY_HASH_FRAGMENTS_2 = 2819965312;

    static SOCKET_TYPE_HASH_TRACKERS = 1282012138;

    static TIER_TYPE_HASH_EXOTIC = 2759499571;

    static LOADOUT_BUCKETS = [
        3284755031, // subclass
        1498876634, // kinetic weapons
        2465295065, // energy weapons
        953998645, // power weapons
        3448274439, // helmet
        3551918588, // gauntlets
        14239492, // chest armor
        20886954, // leg armor
        1585787867, // class armor
        // 4023194814, // ghost
    ]

    static DamageTypeColors = {
        "1": "white", // kinetic
        "2": "rgb(121 235 243)", // arc
        "3": "rgb(243 111 38)", // solar
        "4": "rgb(176 130 203)", // void
        "5": "white", // raid ?
        "6": "rgb(78 135 255)" // stasis
    }

    static EnergyTypeColors = {
        "1": "rgb(121 235 243)", // arc
        "2": "rgb(243 111 38)", // solar
        "3": "rgb(176 130 203)", // void
        "6": "rgb(78 135 255)", // stasis
    }

    static ItemTierColors = {
        "4": "#4f77a4", // rare
        "5": "rgba(82, 47, 100)", // legendary
        "6": "rgb(205, 174, 50)", // exotic
    }

    static ClassHashNames = {
        "671679327": "Hunter",
        "2271682572": "Warlock",
        "3655393761": "Titan",
    }

    static ComparatorDefaultVault = (itemData1, itemData2) => {

        // group identical items
        if (itemData1.item.itemHash === itemData2.item.itemHash) return 0;

        let def1 = Destiny.getItemDefinition(itemData1.item.itemHash);
        let def2 = Destiny.getItemDefinition(itemData2.item.itemHash);

        // separate by rarity (higher first)
        if (def1.inventory && def2.inventory) {
            let diff = def2.inventory.tierType - def1.inventory.tierType;
            if (diff !== 0) return diff;
        }

        // separate by name
        if (def1.displayProperties && def2.displayProperties) {
            return def1.displayProperties.name.localeCompare(def2.displayProperties.name);
        }

        return 0;

    }

    static ComparatorInfusionVault = (itemData1, itemData2) => {

        let itemPower1 = Destiny.getItemPower(itemData1);
        let itemPower2 = Destiny.getItemPower(itemData2);

        // console.log(itemPower1);
        // console.log(itemPower2);

        if (itemPower1 === undefined) {
            return 1;
        }
        if (itemPower2 === undefined) {
            return -1;
        }

        return itemPower2 - itemPower1;

    }

    static async sleep(ms) {

        return new Promise((resolve) => {

            setTimeout(() => {
                resolve();
            }, ms);

        });

    }

    static getItemNames(destinyData, ...items) {
        return items.map(itemData => (Destiny.getNameFromInstanceId(destinyData, itemData.item.itemInstanceId) + " " + itemData.item.bucketHash));
    }

    static getItemPower(itemData) {
        if (itemData.instances && itemData.instances.primaryStat) {
            let primaryStat = itemData.instances.primaryStat;
            if (primaryStat.statHash === Destiny.STAT_HASH_ARMOR_POWER || primaryStat.statHash === Destiny.STAT_HASH_WEAPON_POWER) {
                return primaryStat.value;
            }
        }
        return undefined;
    }


    static async initIndexedDB() {

        return new Promise((resolve) => {

            // In the following line, you should include the prefixes of implementations you want to test.
            Destiny.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

            if (!Destiny.indexedDB) {
                console.log("Your browser doesn't support a stable version of IndexedDB.");
                return false;
            }

            let request = Destiny.indexedDB.open("testDatabase", 1);

            request.onupgradeneeded = (event) => {

                console.log("Creating objectStore");

                const db = event.target.result;

                // Create an objectStore for this database
                const objectStore = db.createObjectStore("testObjectStore", { keyPath: "key" });

                // create index
                objectStore.createIndex("key", "key", { unique: false });

            }

            request.onsuccess = (event) => {

                console.log("indexedDB init success");

                // set db to the event target
                Destiny.db = event.target.result;

                resolve(true);

            }

            request.onerror = (event) => {

                console.log(event);

                resolve(false);
            }

        });

    }

    // initialize important definition variables
    static async initMemory() {

        try {

            let platformData = localStorage.getItem("membershipType");
            if (platformData) {
                Destiny.membershipType = parseInt(platformData);
            }

            Destiny.DestinyInventoryBucketDefinition = await this.dbGetNotUndefined("DestinyInventoryBucketDefinition");
            Destiny.DestinyInventoryItemDefinition = await this.dbGetNotUndefined("DestinyInventoryItemDefinition");
            Destiny.DestinyItemCategoryDefinition = await this.dbGetNotUndefined("DestinyItemCategoryDefinition");
            Destiny.DestinySandboxPerkDefinition = await this.dbGetNotUndefined("DestinySandboxPerkDefinition");
            Destiny.DestinyPlugSetDefinition = await this.dbGetNotUndefined("DestinyPlugSetDefinition");
            // Destiny.DestinyClassDefinition = await this.dbGetNotUndefined("DestinyClassDefinition");
            // Destiny.DestinyRaceDefinition = await this.dbGetNotUndefined("DestinyRaceDefinition");
            Destiny.DestinyStatDefinition = await this.dbGetNotUndefined("DestinyStatDefinition");
            Destiny.DestinyDamageTypeDefinition = await this.dbGetNotUndefined("DestinyDamageTypeDefinition");
            Destiny.DestinyEnergyTypeDefinition = await this.dbGetNotUndefined("DestinyEnergyTypeDefinition");
            Destiny.DestinyPlugSetDefinition = await this.dbGetNotUndefined("DestinyPlugSetDefinition");
            Destiny.DestinySocketCategoryDefinition = await this.dbGetNotUndefined("DestinySocketCategoryDefinition");
            Destiny.DestinySocketTypeDefinition = await this.dbGetNotUndefined("DestinySocketTypeDefinition");

            Destiny.DestinyPowerCapDefinition = await this.dbGetNotUndefined("DestinyPowerCapDefinition");

            Destiny.test = false;

            console.log("memory init success");

            return true;

        } catch (e) {

            console.error(e);

            return false;
        }

    }

    static async initFirebase(firebaseApp) {
        Destiny.auth = getAuth(firebaseApp);
        Destiny.firebaseStorage = getStorage(firebaseApp);
        let signInResult = await signInAnonymously(Destiny.auth);
        // console.log(signInResult);
    }

    static transaction() {
        return Destiny.db.transaction(["testObjectStore"], "readwrite").objectStore("testObjectStore");
    }

    static getMembershipId() {
        return localStorage.getItem("membershipId");
    }

    static getMembershipType() {
        return Destiny.membershipType || localStorage.getItem("membershipType");
    }

    static getMembershipTypeName(membershipType) {

        switch (membershipType) {

            case -1:
                return "All";
            case 1:
                return "Xbox";
            case 2:
                return "PSN";
            case 3:
                return "Steam";
            case 4:
                return "Blizzard";
            case 5:
                return "Stadia";
            default:
                return "Unknown Platform";

        }

    }

    static bucketize(itemDataList, comparator) {

        let buckets = {};

        for (let itemData of itemDataList) {

            let bucketHash = itemData.item.bucketHash;

            let definition = Destiny.getItemDefinition(itemData.item.itemHash);

            if (bucketHash === Destiny.BUCKET_HASH_VAULT && definition && definition.equippingBlock) {
                bucketHash = definition.equippingBlock.equipmentSlotTypeHash;
            }

            if (!buckets[bucketHash]) {
                buckets[bucketHash] = [];
            }

            buckets[bucketHash].push(itemData);

        }

        if (comparator) {

            for (let bucketHash in buckets) {

                // console.log(bucketHash)

                buckets[bucketHash].sort(comparator);

            }

        }

        return buckets;

    }

    static getItemData(item, bulkData) {

        let ret = { item };

        // look for available item components and add them to the data
        for (let itemComponentName in bulkData.itemComponents) {
            let componentData = bulkData.itemComponents[itemComponentName].data[item.itemInstanceId];
            if (componentData) {
                ret[itemComponentName] = componentData;
            }
        }

        return ret;

    }

    static getItemDataList(itemList, bulkData) {

        return itemList.map(item => {

            return Destiny.getItemData(item, bulkData);

        });

    }

    static getNameFromInstanceId(destinyData, itemInstanceId) {
        let itemData = destinyData.itemsByInstanceId[itemInstanceId];
        return Destiny.DestinyInventoryItemDefinition[itemData?.item.itemHash]?.displayProperties.name;
    }

    // preprocess all account data into easily usable format
    static async processAccountData(bulkData) {

        Destiny.destinyData.update(bulkData);

        // console.log(Destiny.destinyData);
        // Destiny.destinyData.printInventory();

        console.log("Processing account data...");

        let instanceItemStates = {};
        let characterInventoryItems = {};
        let characterEquipmentItems = {};
        let profileInventoryItems = [];
        let itemsByInstanceId = {};

        // character inventories
        for (let characterId in bulkData.characterInventories.data) {
            let items = bulkData.characterInventories.data[characterId].items;
            characterInventoryItems[characterId] = [];
            for (let item of items) {
                let itemData = Destiny.getItemData(item, bulkData);
                characterInventoryItems[characterId].push(itemData);
                if (item.itemInstanceId) {
                    instanceItemStates[item.itemInstanceId] = {
                        inVault: false,
                        equipped: false,
                        characterId: characterId
                    }
                    itemsByInstanceId[item.itemInstanceId] = itemData;
                }
            }
        }

        // character equipment
        for (let characterId in bulkData.characterEquipment.data) {
            let items = bulkData.characterEquipment.data[characterId].items;
            characterEquipmentItems[characterId] = [];
            for (let item of items) {
                let itemData = Destiny.getItemData(item, bulkData);
                characterEquipmentItems[characterId].push(itemData);
                if (item.itemInstanceId) {
                    instanceItemStates[item.itemInstanceId] = {
                        inVault: false,
                        equipped: true,
                        characterId: characterId
                    }
                    itemsByInstanceId[item.itemInstanceId] = itemData;
                }
            }
        }

        // account items
        let items = bulkData.profileInventory.data.items;
        profileInventoryItems = [];
        for (let item of items) {
            let itemData = Destiny.getItemData(item, bulkData);
            profileInventoryItems.push(itemData);
            if (item.itemInstanceId) {
                instanceItemStates[item.itemInstanceId] = {
                    inVault: true,
                }
                itemsByInstanceId[item.itemInstanceId] = itemData;
            }
        }

        let destinyData = {
            instanceItemStates,
            characterInventoryItems,
            characterEquipmentItems,
            profileInventoryItems,
            itemsByInstanceId
        };

        // console.log("Equipment from refresh:");
        // for (let characterId in characterEquipmentItems) {

        //     let items = characterEquipmentItems[characterId];
        //     console.log(characterId);
        //     for (let itemData of items) {
        //         console.log("\t" + Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash].displayProperties.name);
        //     }
        // }

        Destiny.isRefreshing = false;

        // apply cached updates
        console.log("Cached Updates: " + Destiny.cachedItemUpdates.length);
        for (let update of Destiny.cachedItemUpdates) {

            let name = Destiny.getNameFromInstanceId(destinyData, update.itemInstanceId);

            console.log("Performing cached " + update.type + ": " + name);

            if (update.type === "transfer") {
                Destiny.transferItemLocal(
                    destinyData,
                    update.characterId,
                    update.itemInstanceId,
                    update.toVault
                );
            } else if (update.type === "equip") {
                Destiny.equipItemLocal(
                    destinyData,
                    update.characterId,
                    update.itemInstanceId,
                );
            }

        }

        // if (Destiny.cachedItemUpdates.length > 0) {

        //     console.log("Equipment after cached update: ");
        //     for (let characterId in characterEquipmentItems) {

        //         let items = characterEquipmentItems[characterId];
        //         console.log(characterId);
        //         for (let itemData of items) {
        //             console.log("\t" + Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash].displayProperties.name + " " + itemData.item.bucketHash);
        //         }

        //         console.log("Inventory items: " + Destiny.getItemNames(destinyData, ...destinyData.characterInventoryItems[characterId]));
        //     }

        //     // console.log("States:");
        //     // console.log("\t" + items.map(itemData => (Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash].displayProperties.name)))
        //     // console.log(destinyData.instanceItemStates)


        // }

        // clear cached updates after applying
        Destiny.cachedItemUpdates = [];


        return destinyData;

    }

    static getBucketDefinition(bucketHash) {
        return Destiny.DestinyInventoryBucketDefinition[bucketHash];
    }

    static getPerkDefinition(perkHash) {
        return Destiny.DestinySandboxPerkDefinition[perkHash];
    }

    static getItemDefinition(itemHash) {
        return Destiny.DestinyInventoryItemDefinition[itemHash];
    }

    static getPlugDefinition(plugHash) {
        return Destiny.DestinyPlugSetDefinition[plugHash];
    }


    static async searchPlayers(name) {

        // let client_id = "35739";
        // let client_secret = "aHrodjqF8hJ97eCZKoXgYHNQjC8Nf4JiWKH0VWpUABk";

        let params = {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "X-API-Key": Destiny.API_KEY,
            },

            body: JSON.stringify({
                displayName: name,
                displatNameCode: "2400"
            })

        }

        let url = "https://www.bungie.net/Platform/Destiny2/SearchDestinyPlayerByBungieName/All/";

        let response = await fetch(url, params);

        return response.json();


    }

    static async getCharacterInventory(platform, destinyId, characterId) {
        return this.webGet(Destiny.BNET + "/Platform/Destiny2/" + platform + "/Profile/" + destinyId + "/Character/" + characterId + "?components=CharacterEquipment,CharacterInventories,ItemInstances")
    }

    static async refreshAccountData(platform, destinyId) {

        Destiny.isRefreshing = true;

        // Destiny.test = !Destiny.test

        let response;
        let bulkData;

        // try to fetch the data from Bungie
        try {
            response = await this.webGet(
                Destiny.BNET + "/Platform/Destiny2/" + platform + "/Profile/" + destinyId + "/?components=Profiles,ProfileInventories,ProfileCurrencies,Characters,CharacterInventories,CharacterEquipment,ItemInstances,ItemObjectives,ItemSockets,ItemStats,ItemTalentGrids,ItemCommonData,ItemPlugStates,ItemReusablePlugs,ItemPlugObjectives,StringVariables,ProfileProgression,Craftables,Transitory,PresentationNodes",
                true
            );

            bulkData = response.Response;

        } catch (error) {
            localStorage.setItem("lastRefresh", "failed");
            return {
                success: false,
                alert: false,
                message: "Unable to get your account data from Bungie.net. The servers may be down."
            };
        }


        if (bulkData) {

            // process the data
            let destinyData = await Destiny.processAccountData(bulkData);

            // save the data locally
            let saveSuccess = Destiny.dbPut("refreshData", JSON.stringify(bulkData));

            // update the timestamp on the last saved data
            if (saveSuccess) {
                localStorage.setItem("lastRefresh", JSON.stringify(Date.now()));
            } else {
                localStorage.setItem("lastRefresh", "failed");
                return {
                    success: false,
                    alert: true,
                    message: "Failed to save account data locally"
                };
            }

            return {
                success: true,
                alert: false,
                bulkData,
                destinyData
            };

        } else {

            localStorage.setItem("lastRefresh", "failed");

            return {
                success: false,
                alert: true,
                message: "Unable to get your account data from Bungie.net."
            };

        }

    }

    static async getItemInstance(platform, destinyId, instanceId) {
        return this.webGet(Destiny.BNET + "/Platform/Destiny2/" + platform + "/Profile/" + destinyId + "/Item/" + instanceId + "?components=300,302,304,307,ItemSockets,ItemReusablePlugs");
    }

    // fetch data from bungie api using oauth
    static async webGet(url, noCache) {

        let key = "ffeacd85f2cf4fa3a5553bae633c66e7";

        let oauth = JSON.parse(window.localStorage.getItem("oauth"));

        let token = oauth.access_token;

        let params = {
            method: "GET",
            mode: "cors",
            headers: {
                "X-API-Key": key,
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
            }
        }

        if (noCache) {
            params["cache"] = "no-cache";
            params["cache-control"] = "no-cache";
            params["pragma"] = "no-cache";
        }

        return new Promise((resolve, reject) => {

            (async () => {

                try {

                    let response = await fetch(url, params);

                    let data = response.json();

                    resolve(data);

                } catch (error) {

                    reject(error);

                }

            })();

        });





    }

    // get data from local indexedDB
    static async dbGet(key) {

        return new Promise((resolve) => {

            let request = Destiny.transaction().get(key);

            request.onsuccess = (event) => {

                if (event.target.result) {
                    // return the value only
                    resolve(event.target.result.value);
                }

                resolve(undefined);

            };

            request.onerror = (event) => {
                console.error(event);
                resolve(undefined);
            }

        });

    }

    static async dbGetNotUndefined(key) {
        let res = await Destiny.dbGet(key);
        if (res === undefined) {
            throw new Error("No database value for key: " + key);
        }
        return res;
    }

    // put data in local indexedDB
    static async dbPut(key, value) {

        return new Promise((resolve) => {

            let request = Destiny.transaction().put({ key, value });

            request.onsuccess = (event) => {
                resolve(true);
            };

            request.onerror = (event) => {
                console.error(event);
                resolve(false);
            }

        });

    }


    static async getManifestData() {

        let response = await this.webGet("https://www.bungie.net/Platform/Destiny2/Manifest/");

        if (response) {
            return response.Response;
        } else {
            return undefined;
        }

    }

    // return true if manifest is up to date
    static async checkManifestStatus() {

        let manifestData = await Destiny.getManifestData();

        let currentVersion = localStorage.getItem("manifestVersion");

        console.log(`current manifest version: ${currentVersion}`);

        if (manifestData.version === currentVersion) {

            return { status: true, manifestData };

        } else {

            return { status: false, manifestData };

        }


    }

    // download and store a single item from the manifest
    static async downloadManifestItem(key, url) {

        let response;
        let data;

        let params = {
            method: "GET",
            // mode: "no-cors",
            cache: "no-cache", // TODO: decide whether caching is acceptable or not here
        }

        try {
            response = await fetch(url, params);
            data = await response.json();
        } catch (error) {
            // console.error(error);
            console.warn("Unable to download " + key);
            return false;
        }

        let storeResult = await Destiny.dbPut(key, data);

        if (storeResult) return true;

        return false;

    }

    // download and store full english manifest in local browser storage
    static async updateManifest(progressListener) {

        // get current manifest data from Bungie
        let manifestData = await Destiny.getManifestData();

        if (!manifestData) {
            console.error("Unable to get current manifest from Bungie");
            return { success: false };
        }

        // get object containing current versions of each manifest item, or create new one
        let manifestVersions = await this.dbGet("manifestVersions") || {};

        // get english content paths
        let paths = manifestData.jsonWorldComponentContentPaths.en;
        let keys = Object.keys(paths);

        let failedUpdates = 0;

        for (let i = 0; i < keys.length; i++) {

            // get manifest content key and path
            let key = keys[i];
            let url = Destiny.BNET + paths[key];

            // skip up to date content
            if (manifestVersions[key] === manifestData.version) continue;


            console.log(`updating item ${i}/${keys.length - 1}...`);

            let downloadSuccess = await Destiny.downloadManifestItem(key, url);

            if (downloadSuccess) {

                console.log(`\tdone`);

                // update version of manifest item so it can be skipped if the page is refreshed
                manifestVersions[key] = manifestData.version;

                // update the progressListener
                if (progressListener) {
                    progressListener((i + 1) / keys.length);
                }

            } else {
                console.log(`\tfailed`);
                failedUpdates++;
            }

        }

        await Destiny.dbPut("manifestVersions", manifestVersions);

        if (failedUpdates > 0) {
            return { success: false };
        } else {
            return { success: true };
        }


    }

    // download and store full english manifest in local browser storage
    static async updateManifestFast() {

        console.log("Getting manifest...");

        // get current manifest data from Bungie
        let manifestData = await Destiny.getManifestData();

        if (!manifestData) {
            console.error("Unable to get current manifest from Bungie");
            return { success: false };
        }

        // get object containing current versions of each manifest item, or create new one
        let manifestVersions = await this.dbGet("manifestVersions") || {};

        // get english content paths
        let paths = manifestData.jsonWorldComponentContentPaths.en;
        let keys = Object.keys(paths);

        let failedUpdates = 0;
        let promises = [];

        // loop thru all items and download them at the same time
        for (let i = 0; i < keys.length; i++) {

            // get manifest content key and path
            let key = keys[i];
            let url = Destiny.BNET + paths[key];

            // skip up to date content
            if (manifestVersions[key] === manifestData.version) continue;

            // create a promise to download the manifest item
            let promise = new Promise(async (resolve) => {
                let downloadSuccess = await Destiny.downloadManifestItem(key, url);
                resolve({ key, success: downloadSuccess });
            });

            promises.push(promise);

        }

        // wait for all downloads
        let promiseResults = await Promise.all(promises);

        // check results and update versions
        for (let promiseResult of promiseResults) {

            if (promiseResult.success) {
                manifestVersions[promiseResult.key] = manifestData.version;
            } else {
                failedUpdates++;
            }

        }

        console.log(`Done. ${failedUpdates} failure(s)`);

        await Destiny.dbPut("manifestVersions", manifestVersions);

        if (failedUpdates > 0) {
            return { success: false };
        } else {
            return { success: true };
        }


    }

    // get an authentication token
    static async authenticateUser(authCode) {

        let client_id = "35739";
        let client_secret = "aHrodjqF8hJ97eCZKoXgYHNQjC8Nf4JiWKH0VWpUABk";

        let params = {
            method: "POST",

            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },

            body: "client_id=" + client_id + "&grant_type=authorization_code&code=" + authCode + "&client_secret=" + client_secret

        }

        let url = "https://www.bungie.net/platform/app/oauth/token/";

        return new Promise((resolve, reject) => {

            (async () => {

                try {
                    let response = await fetch(url, params);
                    let authResponse = await response.json();

                    if (authResponse && !authResponse.error) {
                        localStorage.setItem("oauth", JSON.stringify(authResponse));
                        resolve({ success: true, data: authResponse });
                    } else {
                        resolve({ success: false });
                    }

                } catch (error) {
                    console.error(error);
                    resolve({ success: false });
                }

            })();

        });

    }

    // remove all local data relating to authentication and user
    static clearUserData() {
        localStorage.removeItem("oauth");
        localStorage.removeItem("membershipId");
        localStorage.removeItem("membershipType");
    }

    // return true if authentication must be refreshed
    static authRefreshNeeded() {
        let tokenRefreshDue = localStorage.getItem("tokenRefreshDue");
        return !tokenRefreshDue || (JSON.parse(tokenRefreshDue) <= Date.now());
    }

    static async refreshAuthToken() {

        let client_id = "35739";
        let client_secret = "aHrodjqF8hJ97eCZKoXgYHNQjC8Nf4JiWKH0VWpUABk";

        let auth = JSON.parse(window.localStorage.getItem("oauth"));
        if (!auth) {
            return false;
        }

        let refresh_token = auth.refresh_token;
        if (!refresh_token) {
            return false;
        }

        let params = {
            method: "POST",

            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },

            body: "client_id=" + client_id + "&grant_type=refresh_token&refresh_token=" + refresh_token + "&client_secret=" + client_secret

        }

        let url = "https://www.bungie.net/platform/app/oauth/token/";

        try {
            let response = await fetch(url, params);
            if (response.ok) {
                let oauth = await response.json();
                localStorage.setItem("oauth", JSON.stringify(oauth));
                let tokenRefreshDue = Date.now() + (oauth.expires_in - 60) * 1000;
                localStorage.setItem("tokenRefreshDue", tokenRefreshDue);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log("Unable to refresh authentication token");
            console.error(error);
            return false;
        }

    }

    static async getLinkedProfiles(membershipType, membershipId) {
        let response = await this.webGet(Destiny.BNET + "/Platform/Destiny2/" + membershipType + "/Profile/" + membershipId + "/LinkedProfiles/");
        return response.Response;
    }

    static async transferItem(itemHash, stackSize, transferToVault, itemId, characterId, membershipType) {

        let auth = JSON.parse(window.localStorage.getItem("oauth"));
        let token = auth.access_token;


        let params = {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "X-API-Key": Destiny.API_KEY,
                "Authorization": "Bearer " + token,
            },

            body: JSON.stringify({
                "itemReferenceHash": itemHash,
                "stackSize": stackSize,
                "transferToVault": transferToVault,
                "itemId": itemId,
                "characterId": characterId,
                "membershipType": membershipType
            })

        }

        let url = "https://www.bungie.net/Platform/Destiny2/Actions/Items/TransferItem/";

        let response = await fetch(url, params);

        return response.json();

    }

    static async equipItems(characterId, membershipType, ...itemInstanceIds) {

        return new Promise((resolve, reject) => {

            (async () => {

                let auth = JSON.parse(window.localStorage.getItem("oauth"));
                let token = auth.access_token;

                let params = {
                    method: "POST",

                    headers: {
                        "Content-Type": "application/json",
                        "X-API-Key": Destiny.API_KEY,
                        "Authorization": "Bearer " + token,
                    },

                    body: JSON.stringify({
                        "itemIds": itemInstanceIds,
                        "characterId": characterId,
                        "membershipType": membershipType
                    })

                }

                let url = "https://www.bungie.net/Platform/Destiny2/Actions/Items/EquipItems/";

                let response = await fetch(url, params);

                if (!response.ok) reject(undefined);

                let data = await response.json();

                resolve(data.Response.equipResults);

            })();

        });

    }

    static async insertSocketPlugFree(characterId, membershipType, itemInstanceId, socketIndex, socketArrayType, plugItemHash) {

        let auth = JSON.parse(window.localStorage.getItem("oauth"));
        let token = auth.access_token;

        let params = {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "X-API-Key": Destiny.API_KEY,
                "Authorization": "Bearer " + token,
            },

            body: JSON.stringify({
                "plug": {
                    "socketIndex": socketIndex,
                    "socketArrayType": socketArrayType,
                    "plugItemHash": plugItemHash,
                },
                "itemId": itemInstanceId,
                "characterId": characterId,
                "membershipType": membershipType
            })

        }

        let url = "https://www.bungie.net/Platform/Destiny2/Actions/Items/InsertSocketPlugFree/";

        let response = await fetch(url, params);

        // console.log(response);

        let data = await response.json();

        // 1679 error indicates correct plug already inserted
        let success = data.ErrorCode === 1 || data.ErrorCode === 1679;

        return { success, data: data.Response };

    }

    // save loadouts to local browser storage
    static async saveLoadouts(loadouts) {

        console.log("Saving Loadouts...");

        let saveSuccess = await Destiny.dbPut("loadouts", loadouts);

        let loadoutsRef = ref(Destiny.firebaseStorage, "users/" + Destiny.getMembershipId() + "/loadouts.json");
        uploadString(loadoutsRef, JSON.stringify(loadouts)).then((result) => {
            console.log(result);
        });

        return saveSuccess;

    }

    // get saved loadouts from local browser storage
    static async loadLoadouts() {

        console.log("Loading Loadouts...");

        try {
            let url = await getDownloadURL(ref(Destiny.firebaseStorage, "users/" + Destiny.getMembershipId() + "/loadouts.json"));
            let cloudLoadouts = await (await fetch(url)).json();
            return cloudLoadouts;
        } catch (error) {
            let localLoadouts = await Destiny.dbGet("loadouts");
            return localLoadouts;
        }

    }

    // update an item's state to reflect that it has been transferred
    static transferItemLocal(destinyData, characterId, itemInstanceId, toVault) {

        Destiny.destinyData.transferItemLocal(characterId, itemInstanceId, toVault);

        // cache state update when refresh is pending
        if (Destiny.isRefreshing) {
            Destiny.cachedItemUpdates.push({
                type: "transfer",
                characterId,
                itemInstanceId,
                toVault
            });
            console.log("Transfer cached");
        }

        console.log("Local transfer: " + Destiny.getNameFromInstanceId(destinyData, itemInstanceId));

        let profileItems = destinyData.profileInventoryItems;
        let inventoryItems = destinyData.characterInventoryItems[characterId];


        if (toVault) {

            for (let i = 0; i < inventoryItems.length; i++) {
                let itemData = inventoryItems[i];
                if (itemData.item.itemInstanceId === itemInstanceId) {
                    inventoryItems.splice(i, 1);
                    profileItems.push(itemData);
                    destinyData.instanceItemStates[itemInstanceId] = {
                        inVault: true
                    }
                    console.log("Local transfer success");
                    break;
                }
            }

        } else {

            let transferItemData;
            let transferItemIndex;

            for (let i = 0; i < profileItems.length; i++) {
                let itemData = profileItems[i];
                if (itemData.item.itemInstanceId === itemInstanceId) {
                    transferItemData = itemData;
                    transferItemIndex = i;
                    break;
                }
            }

            if (transferItemData) {

                profileItems.splice(transferItemIndex, 1);
                inventoryItems.push(transferItemData);
                destinyData.instanceItemStates[itemInstanceId] = {
                    inVault: false,
                    characterId: characterId,
                    equipped: false
                }

                console.log("Local transfer success");

                let definition = Destiny.DestinyInventoryItemDefinition[transferItemData.item.itemHash];

                // console.log(definition);
                let newBucketHash = definition.inventory.bucketTypeHash;

                // console.log("newBucketHash: " + newBucketHash);

                transferItemData.item.bucketHash = newBucketHash;

            }


            // console.log("Inventory items: " + Destiny.getItemNames(destinyData, ...inventoryItems));

        }



    }

    // update an item's state to reflect that it has been equipped,
    // and update any replaced items to reflect that they have been unequipped
    static equipItemLocal(destinyData, characterId, itemInstanceId) {

        Destiny.destinyData.equipItemLocal(characterId, itemInstanceId);
        // Destiny.destinyData.printInventory();

        // cache state update when refresh is pending
        if (Destiny.isRefreshing) {
            Destiny.cachedItemUpdates.push({
                type: "equip",
                characterId,
                itemInstanceId,
            });
            console.log("Equip cached");
        }

        console.log("Local equip: " + Destiny.getNameFromInstanceId(destinyData, itemInstanceId));

        let equipmentItems = destinyData.characterEquipmentItems[characterId];
        let inventoryItems = destinyData.characterInventoryItems[characterId];

        let itemToEquip;
        let equipIndex;

        let itemToUnequip;
        let unequipIndex;

        // find the item we want to equip
        for (let i = 0; i < inventoryItems.length; i++) {
            let itemData = inventoryItems[i];
            if (itemData.item.itemInstanceId === itemInstanceId) {
                itemToEquip = itemData;
                equipIndex = i;
                break;
            }
        }

        if (!itemToEquip) {

            let itemState = destinyData.instanceItemStates[itemInstanceId];

            if (itemState.equipped === true && itemState.characterId === characterId) {
                console.warn("Item already equipped");
            } else {
                console.warn("Item to equip not found. State:");
                console.warn(itemState);
            }

            return;
        }

        let sameBucketItems = [];
        let itemIndexes = [];

        // find the currently equipped item for this bucketHash and remove it
        for (let i = 0; i < equipmentItems.length; i++) {

            let itemData = equipmentItems[i];

            if (itemData.item.bucketHash === itemToEquip.item.bucketHash) {
                sameBucketItems.push(itemData);
                itemIndexes.push(i);
            }

        }


        let sameBucketNames = Destiny.getItemNames(destinyData, ...sameBucketItems);

        console.log("Same bucket items: " + sameBucketNames.join(", "));

        if (sameBucketItems.length > 0) {
            itemToUnequip = sameBucketItems[0];
            unequipIndex = itemIndexes[0];
        }

        // remove the item to equip from inventory and add it to equipment
        inventoryItems.splice(equipIndex, 1);
        equipmentItems.push(itemToEquip);

        // update state of equipped item
        destinyData.instanceItemStates[itemToEquip.item.itemInstanceId] = { characterId, equipped: true, inVault: false };

        if (itemToUnequip) {

            // remove the item to unequip from equipment and add it to inventory
            equipmentItems.splice(unequipIndex, 1);
            inventoryItems.push(itemToUnequip);

            // update state of unequipped item
            destinyData.instanceItemStates[itemToUnequip.item.itemInstanceId] = { characterId, equipped: false, inVault: false };

            console.log("Local equip success")

        } else {

            console.warn("No item to unequip");

        }

    }

    // transfer an item from any destination to any destination
    static async transferItemFull(destinyData, characterId, membershipType, itemData, toVault) {

        let itemState = destinyData.instanceItemStates[itemData.item.itemInstanceId];

        if (!toVault && !itemState.inVault) { // transfer from one character to another

            let sourceCharacterId = itemState.characterId;

            console.log(`Request transfer from characterId ${sourceCharacterId} to characterId ${characterId}`);

            if (itemState.equipped) {

                console.log("Item is equipped");

                let requiredBucketHash = itemData.item.bucketHash;
                let characterInventoryItems = destinyData.characterInventoryItems[sourceCharacterId];
                let replacementItemData;

                // find a replacement item for the item to unequip
                for (let charItemData of characterInventoryItems) {

                    // skip items in the wrong bucket and the original item (not sure why this would happen...)
                    if (charItemData.item.bucketHash !== requiredBucketHash) continue;
                    if (charItemData.item.itemInstanceId === itemData.item.itemInstanceId) continue;

                    let def = Destiny.DestinyInventoryItemDefinition[charItemData.item.itemHash];

                    // skip items that are exotic
                    // TODO: update this to try using an exotic as a last resort rather than just skipping them
                    if (def.inventory.tierTypeHash === Destiny.TIER_TYPE_HASH_EXOTIC) continue;

                    replacementItemData = charItemData;
                    break;

                }

                if (!replacementItemData) {
                    return { success: false, message: "Could not transfer item from other character. There are no items available on the character to swap for it." };
                }

                let replacementItemName = Destiny.DestinyInventoryItemDefinition[replacementItemData.item.itemHash]?.displayProperties?.name;
                console.log("replacement item: " + replacementItemName);

                // equip replacement item on source character to free the desired item to transfer
                let equipResults = await Destiny.equipItems(
                    sourceCharacterId,
                    membershipType,
                    replacementItemData.item.itemInstanceId
                );

                let failedInstanceIds = [];
                for (let equipResult of equipResults) {
                    if (equipResult.equipStatus !== 1) {
                        failedInstanceIds.push(equipResult.itemInstanceId);
                    } else {

                        try {
                            Destiny.equipItemLocal(
                                destinyData,
                                sourceCharacterId,
                                equipResult.itemInstanceId
                            );
                        } catch (e) {
                            console.error(e);
                        }

                    }
                }

                if (failedInstanceIds.length > 0) {
                    alert("Could not transfer item from other character. Could not equip replacement item: " + replacementItemName);
                } else {
                    console.log("Unequip success");
                }

                // refresh item state
                itemState = destinyData.instanceItemStates[itemData.item.itemInstanceId];

            }

            // transfer the item from the source character to the vault
            let response = await Destiny.transferItem(
                itemData.item.itemHash,
                1,
                true,
                itemData.item.itemInstanceId,
                sourceCharacterId,
                membershipType
            );

            if (response.ErrorCode === 1) {
                Destiny.transferItemLocal(destinyData, sourceCharacterId, itemData.item.itemInstanceId, true);
                // return { success: true };
            } else {
                console.warn("transfer from source to vault failed");
                return { success: false, message: response.Message };
            }

        }

        // transfer from vault to character or character to vault
        let response = await Destiny.transferItem(
            itemData.item.itemHash,
            1,
            toVault,
            itemData.item.itemInstanceId,
            characterId,
            membershipType
        );

        if (response.ErrorCode === 1) {
            Destiny.transferItemLocal(destinyData, characterId, itemData.item.itemInstanceId, toVault);
            console.log("transfer success");
            return { success: true };
        } else {
            console.warn("transfer from vault to destination failed");
            return { success: false, message: response.Message };
        }



    }

    // extract deepsight resonance information from an item
    static getDeepsightData(itemData) {

        let deepsightComplete = false;
        let deepsightProgress; // 0 to 1

        if (!itemData.plugObjectives) return undefined;

        let deepsightObjectives = itemData.plugObjectives.objectivesPerPlug[Destiny.PLUG_HASH_DEEPSIGHT];

        if (!deepsightObjectives) return undefined;

        if (deepsightObjectives[0]) {
            deepsightComplete = deepsightObjectives[0].complete;
            deepsightProgress = deepsightObjectives[0].progress / deepsightObjectives[0].completionValue;
        }


        return {
            complete: deepsightComplete,
            progress: deepsightProgress
        };

    }

    // extract crafting information from an item
    static getCraftingData(itemData) {

        if (!itemData.plugObjectives?.objectivesPerPlug) return undefined;

        // loop thru plug objectives to find the plug used for crafting data
        let craftingPlugHash;
        for (let plugHash in itemData.plugObjectives.objectivesPerPlug) {

            let definition = Destiny.DestinyInventoryItemDefinition[plugHash]

            // TODO: fix magic number
            if (definition.plug.plugCategoryHash === 3425085882) {
                craftingPlugHash = plugHash;
                break;
            }

        }

        if (!craftingPlugHash) return undefined;


        let craftingLevel;
        let craftingProgress; // 0 to 1

        if (!itemData.plugObjectives) return undefined;

        let craftingObjectives = itemData.plugObjectives.objectivesPerPlug[craftingPlugHash];

        if (!craftingObjectives) return undefined;

        craftingProgress = craftingObjectives[0].progress / craftingObjectives[0].completionValue;
        craftingLevel = craftingObjectives[1].progress;

        return {
            level: craftingLevel,
            progress: craftingProgress
        };

    }

    static createLoadoutName(loadoutItems) {

        let exoticArmorName;
        let subclassSockets;

        for (let itemData of loadoutItems) {
            let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
            let tierTypeHash = definition.inventory.tierTypeHash;
            if (itemData.item.bucketHash === Destiny.BUCKET_HASH_SUBCLASS) {
                subclassSockets = Destiny.getSubclassSockets(itemData, definition);
            } else if (definition.itemType === Destiny.ITEM_TYPE_ARMOR && tierTypeHash === Destiny.TIER_TYPE_HASH_EXOTIC) {
                exoticArmorName = definition.displayProperties.name;
            }
        }

        let loadoutNameParts = [];

        if (subclassSockets) {
            let superDefinition = Destiny.DestinyInventoryItemDefinition[subclassSockets.superSocket.plugHash];
            loadoutNameParts.push(superDefinition.displayProperties.name);
        }

        if (exoticArmorName) {
            loadoutNameParts.push(exoticArmorName);
        }

        if (loadoutNameParts.length === 0) {
            loadoutNameParts.push("Loadout");
        }

        return loadoutNameParts.join(" // ");

    }

    // extract a loadout object from a list of equipped items
    static createLoadout(characterEquipmentItems, classType) {

        let loadoutItems = [];

        // inefficient way of doing this but I was lazy
        for (let bucketHash of Destiny.LOADOUT_BUCKETS) {
            for (let itemData of characterEquipmentItems) {

                if (itemData.item.bucketHash !== bucketHash) continue;

                loadoutItems.push(itemData);

            }
        }

        let loadoutName = Destiny.createLoadoutName(loadoutItems);

        return {
            version: 1,
            name: loadoutName,
            items: loadoutItems,
            classType
        }

    }

    static createLoadoutV2(items, classType) {

        let loadoutItems = [];

        // inefficient way of doing this but I was lazy
        for (let bucketHash of Destiny.LOADOUT_BUCKETS) {

            for (let itemData of items) {

                if (itemData.item.bucketHash !== bucketHash) continue;

                let itemHash = itemData.item.itemHash;
                let itemInstanceId = itemData.item.itemInstanceId;

                if (itemInstanceId === undefined) continue;

                loadoutItems.push({
                    itemHash,
                    itemInstanceId,
                    sockets: itemData.item.sockets.sockets
                });

            }

        }

        let loadoutName = Destiny.createLoadoutName(loadoutItems);

        return {
            version: 2,
            name: loadoutName,
            items: loadoutItems,
            classType
        }

    }


    // equip an entire loadout, given that all items are already in the
    // character's inventory
    static async equipLoadout(destinyData, characterId, membershipType, loadout) {

        console.log(loadout);

        // get the version of the loadout
        let loadoutVersion = loadout.version || 1;

        let itemInstanceIds = [];
        let itemsByInstanceId = {};
        let loadoutItemsSorted = [...loadout.items];

        // sort items by rarity so exotics get equipped last
        if (loadoutVersion === 1) {

            loadoutItemsSorted.sort((item1, item2) => {
                let def1 = Destiny.DestinyInventoryItemDefinition[item1.item.itemHash];
                let def2 = Destiny.DestinyInventoryItemDefinition[item2.item.itemHash];
                return def1.inventory.tierType - def2.inventory.tierType;
            });

        } else if (loadoutVersion === 2) {

            loadoutItemsSorted.sort((item1, item2) => {
                let def1 = Destiny.DestinyInventoryItemDefinition[item1.itemHash];
                let def2 = Destiny.DestinyInventoryItemDefinition[item2.itemHash];
                return def1.inventory.tierType - def2.inventory.tierType;
            });

        } else {
            console.error("Invalid loadout version: " + loadoutVersion);
            return;
        }


        // key items by instanceId for easy lookup later
        for (let itemData of loadoutItemsSorted) {
            if (itemData.item.itemInstanceId) {
                itemInstanceIds.push(itemData.item.itemInstanceId);
                itemsByInstanceId[itemData.item.itemInstanceId] = itemData;
            }
        }


        let equipResults = await Destiny.equipItems(
            characterId,
            membershipType,
            ...itemInstanceIds
        );

        console.log(equipResults);

        let failedInstanceIds = [];
        for (let equipResult of equipResults) {

            if (equipResult.equipStatus === 1) {

                Destiny.equipItemLocal(
                    destinyData,
                    characterId,
                    equipResult.itemInstanceId
                );

            } else {

                failedInstanceIds.push(equipResult.itemInstanceId);

            }

        }

        let failedItemNames = [];
        if (failedInstanceIds.length > 0) {

            for (let failedInstanceId of failedInstanceIds) {
                let itemData = itemsByInstanceId[failedInstanceId];
                let definition = Destiny.DestinyInventoryItemDefinition[itemData.item.itemHash];
                failedItemNames.push(definition.displayProperties.name);
            }

            //   alert(failedInstanceIds.length + " item(s) could not be equipped:\n" + failedItemNames.join("\n"));
        }

        let loadoutEquipResult = {
            success: failedInstanceIds.length === 0,
            failedItemNames,
            failedInstanceIds
        }

        return loadoutEquipResult;

    }

    // transfer an entire loadout to a specified character
    static async transferLoadout(destinyData, characterId, membershipType, loadout) {

        console.log(destinyData.instanceItemStates);

        let failedInstanceIds = [];

        for (let itemData of loadout.items) {

            let itemState = destinyData.instanceItemStates[itemData.item.itemInstanceId];

            // the item cannot be found
            if (!itemState) {
                console.warn("Could not find item with itemInstanceId: " + itemData.item.itemInstanceId);
                continue;
            }

            // console.log(itemData);
            // console.log(itemState);

            // console.log(`${itemState.characterId} -> ${characterId} : ${itemState.characterId === characterId}`);

            // transfer items that are not on the character
            if (itemState.inVault || itemState.characterId !== characterId) {

                let transferResult = await Destiny.transferItemFull(
                    destinyData,
                    characterId,
                    membershipType,
                    itemData,
                    false
                );

                if (!transferResult.success) {
                    console.error("Failed to transfer " + itemData.item.itemInstanceId);
                    failedInstanceIds.push(itemData.item.itemInstanceId);
                }

            }

        }

        return {
            success: failedInstanceIds.length === 0,
            failedInstanceIds
        };


    }

    // get itemData from the object returned by insertSocketPlugFree
    static getItemDataFromPlugResponse(plugResponse) {

        if (!plugResponse?.data) return undefined;

        let item = plugResponse.data.item;

        return {
            item: item.item.data,
            instances: item.instance.data, // important: instance[S] not instance
            objectives: item.objectives.data,
            perks: item.perks.data,
            plugObjectives: item.plugObjectives.data,
            reusablePlugs: item.reusablePlugs.data,
            sockets: item.sockets.data,
            stats: item.stats.data,
        }

    }

    static getItemsByInstanceId(itemList) {
        let itemsByInstanceId = {};
        // key items by instanceId for easy lookup later
        for (let itemData of itemList) {
            if (itemData.item.itemInstanceId) {
                itemsByInstanceId[itemData.item.itemInstanceId] = itemData;
            }
        }
        return itemsByInstanceId;
    }

    // this function assumes that all items from the loadout are equipped
    static async equipLoadoutMods(destinyData, characterId, membershipType, loadout, progressListener) {

        // get loadout items mapped by instanceId and
        // current equipped items on character
        let loadoutById = Destiny.getItemsByInstanceId(loadout.items);
        let equipmentItems = destinyData.characterEquipmentItems[characterId];

        // we will populate this list with objects
        // that indicate what plugs to insert and where
        let plugRequests = [];

        // loop thru equipment items and look for mismatched
        // plugs between loadout item and equipped item
        for (let equipmentItem of equipmentItems) {
            // console.log(equipmentItem);
            let loadoutItem = loadoutById[equipmentItem.item.itemInstanceId];

            // sometimes this is an error, sometimes not
            if (!loadoutItem) continue;

            // skip items without sockets
            if (!equipmentItem.sockets) continue;

            let loadoutSockets = loadoutItem.sockets.sockets;
            let equipmentSockets = equipmentItem.sockets.sockets;

            // loop thru sockets and find unmatced plugs
            for (let i = 0; i < loadoutSockets.length; i++) {
                // skip disabled sockets
                if (!equipmentSockets[i].isEnabled) continue;

                // skip if correct plug is already inserted
                if (equipmentSockets[i].plugHash === loadoutSockets[i].plugHash) continue;

                console.log(equipmentSockets[i].plugHash + " -> " + loadoutSockets[i].plugHash);
                let plugRequest = {
                    itemHash: equipmentItem.item.itemHash,
                    itemInstanceId: equipmentItem.item.itemInstanceId,
                    socketIndex: i,
                    socketArrayType: 0, // TODO: is 0 always correct?
                    plugItemHash: loadoutSockets[i].plugHash
                }
                plugRequests.push(plugRequest);
            }

        }

        // loop thru plug requests and insert them
        for (let i = 0; i < plugRequests.length; i++) {

            let plugRequest = plugRequests[i];

            console.log(`plug ${plugRequest.plugItemHash} -> socket ${plugRequest.socketIndex}: ${plugRequest.itemInstanceId}`);

            // need a timeout promise to keep the mod equip stage from taking forever
            let timeoutMs = 5000;
            let timeoutPromise = new Promise(resolve => setTimeout(() => resolve({ timeout: true }), timeoutMs));

            // wrap the plug insert request in a promise as well
            let plugInsert = new Promise(async resolve => {
                let response = await Destiny.insertSocketPlugFree(
                    characterId,
                    membershipType,
                    plugRequest.itemInstanceId,
                    plugRequest.socketIndex,
                    plugRequest.socketArrayType,
                    plugRequest.plugItemHash,
                );
                resolve({ timeout: false, response });
            });

            // run the plug insert request with timeout
            let raceResult = await Promise.race([plugInsert, timeoutPromise]);

            if (raceResult.timeout) console.warn("TIMEOUT");

            let success = raceResult.timeout ? false : raceResult.response.success;

            if (success) {

                let itemData = Destiny.getItemDataFromPlugResponse(raceResult.response);

                console.log("success");

                // console.log(itemData);

                let currentItemData = destinyData.itemsByInstanceId[itemData.item.itemInstanceId];

                // replace all relevant item components with the returned versions
                // TODO: make a smarter way of doing this
                currentItemData.item = itemData.item;
                currentItemData.instances = itemData.instances;
                currentItemData.perks = itemData.perks;
                currentItemData.reusablePlugs = itemData.reusablePlugs;
                currentItemData.sockets = itemData.sockets;
                currentItemData.stats = itemData.stats;

            }

            // report back on equipping progress
            if (progressListener) progressListener(i, plugRequests.length, success, plugRequest.itemHash, plugRequest.itemInstanceId, plugRequest.plugItemHash);

        }

        return { success: true };

    }

    // this function assumes that all items from the loadout are equipped
    static async equipLoadoutModsFast(destinyData, characterId, membershipType, loadout, progressListener) {

        // get loadout items mapped by instanceId and
        // current equipped items on character
        let loadoutById = Destiny.getItemsByInstanceId(loadout.items);
        let equipmentItems = destinyData.characterEquipmentItems[characterId];

        // we will populate this list with objects
        // that indicate what plugs to insert and where
        let plugRequests = [];

        // loop thru equipment items and look for mismatched
        // plugs between loadout item and equipped item
        for (let equipmentItem of equipmentItems) {
            // console.log(equipmentItem);
            let loadoutItem = loadoutById[equipmentItem.item.itemInstanceId];

            // sometimes this is an error, sometimes not
            if (!loadoutItem) continue;

            let loadoutSockets = loadoutItem?.sockets?.sockets;
            let equipmentSockets = equipmentItem?.sockets?.sockets;

            // skip items without sockets
            if (!loadoutSockets || !equipmentSockets) continue;

            // loop thru sockets and find unmatced plugs
            for (let i = 0; i < loadoutSockets.length && i < equipmentSockets.length; i++) {
                // skip disabled sockets
                if (!equipmentSockets[i].isEnabled) continue;

                // skip if correct plug is already inserted
                if (equipmentSockets[i].plugHash === loadoutSockets[i].plugHash) continue;

                console.log(equipmentSockets[i].plugHash + " -> " + loadoutSockets[i].plugHash);
                let plugRequest = {
                    itemHash: equipmentItem.item.itemHash,
                    itemInstanceId: equipmentItem.item.itemInstanceId,
                    socketIndex: i,
                    socketArrayType: 0, // TODO: is 0 always correct?
                    plugItemHash: loadoutSockets[i].plugHash
                }
                plugRequests.push(plugRequest);
            }

        }

        let equipPromises = [];
        let resolvedIndices = [];

        // loop thru plug requests and insert them
        for (let i = 0; i < plugRequests.length; i++) {

            let plugRequest = plugRequests[i];

            console.log(`plug ${plugRequest.plugItemHash} -> socket ${plugRequest.socketIndex}: ${plugRequest.itemInstanceId}`);

            // wrap the plug insert request in a promise as well
            let plugInsert = new Promise(async resolve => {

                let response = await Destiny.insertSocketPlugFree(
                    characterId,
                    membershipType,
                    plugRequest.itemInstanceId,
                    plugRequest.socketIndex,
                    plugRequest.socketArrayType,
                    plugRequest.plugItemHash,
                );

                resolvedIndices.push(i);

                if (response.success) {

                    let itemData = Destiny.getItemDataFromPlugResponse(response);

                    if (itemData) {

                        console.log("success");

                        // console.log(itemData);

                        let currentItemData = destinyData.itemsByInstanceId[itemData.item.itemInstanceId];

                        // replace all relevant item components with the returned versions
                        // TODO: make a smarter way of doing this
                        currentItemData.item = itemData.item;
                        currentItemData.instances = itemData.instances;
                        currentItemData.perks = itemData.perks;
                        currentItemData.reusablePlugs = itemData.reusablePlugs;
                        currentItemData.sockets = itemData.sockets;
                        currentItemData.stats = itemData.stats;

                    } else {

                        console.error("Unable to get item data from mod equip response:", response);

                    }

                }

                // report back on equipping progress
                if (progressListener) progressListener(resolvedIndices.length, plugRequests.length, response.success, plugRequest.itemHash, plugRequest.itemInstanceId, plugRequest.plugItemHash);

                resolve(response.success);

            });

            equipPromises.push(plugInsert);

            // wait 0.55 sec between making new mod requests (request throttles to 0.5 sec, so 0.55 to be safe)
            await new Promise(resolve => setTimeout(() => resolve(), 550));

        }

        let equipResults = await Promise.all(equipPromises);

        return { success: true };

    }

    // get an object with plugHashes of super, abilities, aspects and fragments
    // from itemData and definition of a subclass item
    static getSubclassSockets(itemData, definition) {

        let superSocket;
        let abilitySockets = [];
        let aspectSockets = [];
        let fragmentSockets = [];

        // console.log(itemData);
        // console.log(definition);

        // let subclassDefSockets = definition.sockets.socketEntries;
        let itemSockets = itemData.sockets.sockets;
        let subclassDefSocketCategories = definition.sockets.socketCategories;

        for (let socketCategory of subclassDefSocketCategories) {

            // let categoryDefinition = Destiny.DestinySocketCategoryDefinition[socketCategory.socketCategoryHash];

            // console.log(categoryDefinition.displayProperties.name + ": " + socketCategory.socketCategoryHash);

            // console.log(socketCategory.socketCategoryHash);

            switch (socketCategory.socketCategoryHash) {

                case Destiny.SOCKET_CATEGORY_HASH_SUPER: {
                    // console.log("super: " + socketCategory.socketIndexes);
                    superSocket = itemSockets[socketCategory.socketIndexes[0]];
                    break;
                }
                case Destiny.SOCKET_CATEGORY_HASH_ABILITIES_1:
                case Destiny.SOCKET_CATEGORY_HASH_ABILITIES_2: {
                    // console.log("abilities: " + socketCategory.socketIndexes);
                    for (let index of socketCategory.socketIndexes) {
                        abilitySockets.push(itemSockets[index]);
                    }
                    break;
                }
                case Destiny.SOCKET_CATEGORY_HASH_ASPECTS_1:
                case Destiny.SOCKET_CATEGORY_HASH_ASPECTS_2: {
                    // console.log("aspects: " + socketCategory.socketIndexes);
                    for (let index of socketCategory.socketIndexes) {
                        aspectSockets.push(itemSockets[index]);
                    }
                    break;
                }
                case Destiny.SOCKET_CATEGORY_HASH_FRAGMENTS_1:
                case Destiny.SOCKET_CATEGORY_HASH_FRAGMENTS_2: {
                    // console.log("fragments: " + socketCategory.socketIndexes);
                    for (let index of socketCategory.socketIndexes) {
                        fragmentSockets.push(itemSockets[index]);
                    }
                    break;
                }

                default: {
                    let definition = Destiny.DestinySocketCategoryDefinition?.[socketCategory.socketCategoryHash];
                    let name = definition?.displayProperties?.name || "[unknown]"
                    console.error("unknown socket category hash: " + socketCategory.socketCategoryHash + ", name: " + name);
                    // console.error("definition", definition);
                    break;
                }

            }

        }

        return {
            superSocket,
            abilitySockets,
            aspectSockets,
            fragmentSockets
        }

    }




    // go thru manifest and precompute data about each item
    // and how they may be unique
    static processItemUniqueness() {

        // console.log(Destiny.DestinySocketTypeDefinition);

        let ret = {};
        let hashes = Object.keys(Destiny.DestinyInventoryItemDefinition);

        let perkMap = {};

        for (let hash of hashes) {

            let definition = Destiny.DestinyInventoryItemDefinition[hash];

            // only process weapons
            if (definition.itemType !== Destiny.ITEM_TYPE_WEAPON) continue;

            // only process legendaries
            if (definition.inventory.tierType !== 5) continue;

            console.log(definition.displayProperties.name);

            // console.log(definition.sockets.socketCategories);

            // Destiny.SOCKET_CATEGORY_HASH_WEAPON_PERKS

            let socketColumns = [];


            // get all randomly rolled perks in main columns
            for (let i = 0; i < definition.sockets.socketEntries.length; i++) {
                let socketEntry = definition.sockets.socketEntries[i];


                // only look at main perks
                // if (socketEntry.socketTypeHash !== 2614797986) continue;

                let initialPlugDef = Destiny.DestinyInventoryItemDefinition[socketEntry.singleInitialItemHash];

                if (!initialPlugDef) continue;

                if (initialPlugDef.plug.plugCategoryHash !== Destiny.PLUG_CATEGORY_HASH_FRAMES) continue;

                // if(socketEntry.singleInitialItemHash)

                // only look at sockets that have random perks
                if (!socketEntry.randomizedPlugSetHash) continue;

                let socketItemDefinition = Destiny.DestinyInventoryItemDefinition[socketEntry.singleInitialItemHash];

                if (!socketItemDefinition) continue;

                // console.log(socketEntry.socketTypeHash);

                // console.log(socketItemDefinition.displayProperties.name);

                let randomPlugSetDefinition = Destiny.DestinyPlugSetDefinition[socketEntry.randomizedPlugSetHash];

                let possiblePlugItems = [];

                for (let plugItem of randomPlugSetDefinition.reusablePlugItems) {

                    // console.log(plugItem);

                    let plugDefinition = Destiny.DestinyInventoryItemDefinition[plugItem.plugItemHash];

                    // skip enhanced perks
                    if (Destiny.isEnhancedPerk(plugDefinition)) continue;

                    if (plugItem.currentlyCanRoll) possiblePlugItems.push(plugItem);
                }

                socketColumns.push(possiblePlugItems);

            }

            // console.log(socketColumns);

            if (socketColumns.length !== 2) {
                if (socketColumns.length > 2) console.error(`${definition.displayProperties.name} has ${socketColumns.length} randomized perk column(s)`);
                continue
            };

            // go thru all possible rolls and add them to the main map
            for (let i = 0; i < socketColumns[0].length; i++) {

                let plugHash1 = socketColumns[0][i].plugItemHash;

                for (let j = 0; j < socketColumns[1].length; j++) {

                    let plugHash2 = socketColumns[1][j].plugItemHash;

                    let perkKey = plugHash1 + "," + plugHash2;

                    if (perkMap[perkKey] === undefined) {
                        perkMap[perkKey] = [];
                    }

                    perkMap[perkKey].push(hash);

                }
            }

        }

        console.log(perkMap);

        let itemMap = {};

        let perkKeys = Object.keys(perkMap);

        for (let perkKey of perkKeys) {

            let itemHashes = perkMap[perkKey];
            let plugHashes = perkKey.split(",");

            if (itemHashes.length === 1) {

                let itemHash = itemHashes[0];

                if (itemMap[itemHash] === undefined) itemMap[itemHash] = [];

                let rollData = {
                    numItems: itemHashes.length,
                    plugHashes
                }

                itemMap[itemHash].push(rollData);

            }

        }



        for (let itemHash in itemMap) {

            let itemRolls = itemMap[itemHash];

            let definition = Destiny.DestinyInventoryItemDefinition[itemHash];

            console.log(definition.displayProperties.name);

            for (let itemRoll of itemRolls) {

                // only show unique rolls
                if (itemRoll.numItems !== 1) continue;

                let perkNames = itemRoll.plugHashes.map(hash => (Destiny.DestinyInventoryItemDefinition[hash].displayProperties.name));

                console.log("\t" + perkNames.join(", "));

            }

        }

        ret.weaponRolls = itemMap;

        return ret;

    }


    static isEnhancedPerk(plugDefinition) {
        return plugDefinition.inventory.tierType === 3;
    }


    static async getGeometryData() {

        let res = await fetch("https://www.bungie.net/common/destiny2_content/geometry/platform/mobile/geometry/bc8bc769b9261e88a65dd4c38843838a.tgxm");

        console.log(res);

    }

    

    static putRecentItem(itemInstanceId) {
        
        for(let item of this.recentItems) {
            if(item.itemInstanceId === itemInstanceId) {
                item.time = Date.now();
                return;
            }
        }

        this.recentItems.push({
            itemInstanceId,
            time: Date.now()
        });

        this.recentItems.sort((a, b)=>(b.time - a.time));

        if(this.recentItems.length > this.RECENT_ITEMS_SIZE) {
            this.recentItems.splice(this.recentItems.length - 1, 1);
        }

    }

    static getRecentItems() {
        return this.recentItems;
    }


}


export default Destiny;