import React from 'react';
import Destiny from '../Destiny.js';
import DestinyUtils from '../DestinyUtils.js';

import primaryAmmoIcon from '../icons/primary_ammo.svg';
import specialAmmoIcon from '../icons/special_ammo.svg';
import heavyAmmoIcon from '../icons/heavy_ammo.svg';
import StatBars from './StatBars';

class ItemCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showPossibleRolls: false,
            showEnhancedPerks: false,
        }

    }

    getPowerDisplay(definition, itemInstance) {

        let isWeapon = definition.itemType === Destiny.ITEM_TYPE_WEAPON;
        let isArmor = definition.itemType === Destiny.ITEM_TYPE_ARMOR;

        // console.log(Destiny.DestinyDamageTypeDefinition);

        let damageType;
        let damageTypeDisplayProps;

        let energyType;
        let energyTypeDisplayProps;

        let ammoIconSrc;
        let ammoTypeName;

        if (isWeapon) {

            damageType = itemInstance.damageType;

            let def = Destiny.DestinyDamageTypeDefinition[itemInstance.damageTypeHash];
            if (def) damageTypeDisplayProps = def.displayProperties;

            switch (definition.equippingBlock.ammoType) {
                case Destiny.AMMO_TYPE_PRIMARY: {
                    ammoIconSrc = primaryAmmoIcon;
                    ammoTypeName = "Primary";
                    break;
                }
                case Destiny.AMMO_TYPE_SPECIAL: {
                    ammoIconSrc = specialAmmoIcon;
                    ammoTypeName = "Special";

                    break;
                }
                case Destiny.AMMO_TYPE_HEAVY: {
                    ammoIconSrc = heavyAmmoIcon;
                    ammoTypeName = "Heavy";
                    break;
                }
                default: {

                }
            }

        }

        if (isArmor) {
            energyType = itemInstance.energy.energyType;
            let def = Destiny.DestinyEnergyTypeDefinition[itemInstance.energy.energyTypeHash];
            if (def) energyTypeDisplayProps = def.displayProperties;
        }

        return <div className="inspect-power-display-container">

            {damageType !== Destiny.DAMAGE_TYPE_KINETIC && damageTypeDisplayProps && damageTypeDisplayProps.hasIcon ? <img
                className="inspect-damage-type-icon"
                alt=""
                src={Destiny.BNET + damageTypeDisplayProps.icon}
            /> : null}

            <h1
                style={{
                    color: Destiny.DamageTypeColors[damageType]
                }}
                className="inspect-power-display"
            >{itemInstance.primaryStat.value}</h1>

            {energyTypeDisplayProps && energyTypeDisplayProps.hasIcon ? <>
                <img
                    className="inspect-energy-type-icon"
                    alt=""
                    src={Destiny.BNET + energyTypeDisplayProps.icon}
                />
                <div
                    style={{
                        color: Destiny.EnergyTypeColors[energyType]
                    }}
                    className="inspect-energy-capacity"
                >{itemInstance.energy.energyCapacity}</div>
                <div
                    className="inspect-energy-capacity-label"
                >{"Energy"}</div>

            </> : null}

            {ammoIconSrc ? <>
                <img
                    alt=""
                    className="inspect-ammo-type-icon"
                    src={ammoIconSrc}
                ></img>
                <div
                    className="inspect-ammo-type-label"
                >{ammoTypeName}</div>
            </> : null}
        </div>

    }

    getStatDisplay(stats, isArmor) {

        let elements = [];

        let sortedStats = [];

        for (let statHash in stats) {
            sortedStats.push(stats[statHash]);
        }

        sortedStats.sort((stat1, stat2) => {

            let stat1Props = DestinyUtils.StatDisplayProperties[stat1.statHash];
            let stat2Props = DestinyUtils.StatDisplayProperties[stat2.statHash];

            return stat1Props.index - stat2Props.index;

        });



        for (let stat of sortedStats) {

            let statDisplayProperties = DestinyUtils.StatDisplayProperties[stat.statHash];
            let definition = Destiny.DestinyStatDefinition[stat.statHash];

            let absValue = Math.abs(stat.value);

            elements.push(<div
                className="stat-container"
            >
                <div
                    className="stat-title"
                >
                    {definition.displayProperties.name}
                </div>

                {statDisplayProperties.isBar ? <div
                    className="stat-bar-container"
                >
                    <div
                        style={{
                            width: (isArmor ? absValue * (100 / 42) : absValue) + "%",
                            backgroundColor: stat.value < 0 ? "red" : undefined
                        }}
                        className="stat-bar"
                    ></div>
                </div> : null}

                <div
                    className="stat-value"
                >
                    {stat.value}
                </div>


            </div>)

        }

        return <div
            className="stat-display-container"
        >{elements}</div>;

    }

    getArmorModDisplay(sockets, reusablePlugs) {


        let elements = [];

        let key = 0;

        for (let socket of sockets) {

            if (!socket.isEnabled || !socket.isVisible) continue;

            let definition = Destiny.getItemDefinition(socket.plugHash);

            // if (definition.plug.plugCategoryHash !== Destiny.PLUG_CATEGORY_FRAMES) continue;

            elements.push(<div
                key={key++}
                className="inspect-perk-container"
            >
                <img
                    alt=""
                    className="inspect-perk-icon"
                    src={Destiny.BNET + definition.displayProperties.icon}
                />
                <div
                    className="inspect-perk-name"
                >
                    {definition.displayProperties.name}
                </div>
            </div>)

        }

        return <div
            className="perk-display-container"
        >{elements}</div>;


    }

    getPerkDisplay(definitionSockets, itemSockets, reusablePlugs) {

        let elements = [];
        let key = 0;

        // console.log(reusablePlugs);

        let socketCategories = definitionSockets.socketCategories;
        let definitionSocketEntries = definitionSockets.socketEntries;

        // console.log(socketCategories);

        // for(let socketEntry of itemSockets) {
        //     let plugDef = Destiny.DestinyInventoryItemDefinition[socketEntry.plugHash];
        //     if(!plugDef) continue;
        // }

        // find socket category for weapon perks
        let weaponPerksCategory;
        let weaponIntrinsicsCategory;
        let weaponModsCategory;
        for (let category of socketCategories) {
            if (category.socketCategoryHash === Destiny.SOCKET_CATEGORY_HASH_WEAPON_PERKS) {
                weaponPerksCategory = category;
            } else if (category.socketCategoryHash === Destiny.SOCKET_CATEGORY_HASH_WEAPON_INTRINSICS) {
                weaponIntrinsicsCategory = category;
            } else if (category.socketCategoryHash === Destiny.SOCKET_CATEGORY_HASH_WEAPON_MODS) {
                weaponModsCategory = category;
            }
            if (weaponPerksCategory && weaponIntrinsicsCategory && weaponModsCategory) break;
        }

        //if (!weaponPerksCategory) return;

        // console.log(weaponModsCategory);



        // display intrinsic traits
        if (weaponIntrinsicsCategory) {
            for (let socketIndex of weaponIntrinsicsCategory.socketIndexes) {
                let socketEntry = itemSockets[socketIndex];
                let definition = Destiny.DestinyInventoryItemDefinition[socketEntry.plugHash];
                if (definition) {
                    let enhanced = false;
                    elements.push(<div
                        key={key++}
                        className={"inspect-perk-container intrinsic" + (enhanced ? " enhanced" : "")}
                    >
                        <img
                            alt=""
                            className="inspect-perk-icon"
                            src={Destiny.BNET + definition.displayProperties.icon}
                        />
                        <div
                            className="inspect-perk-name"
                        >
                            {definition.displayProperties.name}
                        </div>
                    </div>)
                }
            }
        }

        if (weaponPerksCategory && reusablePlugs) {

            for (let columnIndex of weaponPerksCategory.socketIndexes) {

                let socketEntry = itemSockets[columnIndex];
                let definitionSocketEntry = definitionSocketEntries[columnIndex];

                let activePerkDefinition;

                let perkIconElements = [];

                // skip kill trackers
                if (definitionSocketEntry?.socketTypeHash === Destiny.SOCKET_TYPE_HASH_TRACKERS) continue;

                let reusablePlugItems = reusablePlugs[columnIndex];
                // console.log(socketEntry)
                // console.log(reusablePlugItems)

                if (!reusablePlugItems) continue;

                for (let plugItem of reusablePlugItems) {

                    let definition = Destiny.DestinyInventoryItemDefinition[plugItem.plugItemHash];

                    let isActive = socketEntry.plugHash === plugItem.plugItemHash

                    if (isActive) {
                        activePerkDefinition = definition;
                    }

                    // TODO: find a better way to check for enhanced perks

                    perkIconElements.push(<img
                        alt=""
                        className={"inspect-perk-icon" + (!isActive ? " inactive" : "")}
                        src={Destiny.BNET + definition.displayProperties.icon}
                    />);

                }

                let enhanced = Destiny.isEnhancedPerk(activePerkDefinition);

                elements.push(<div
                    key={key++}
                    className={"inspect-perk-container" + (enhanced ? " enhanced" : "")}
                >
                    {perkIconElements}
                    <div
                        className="inspect-perk-name"
                    >
                        {activePerkDefinition.displayProperties.name}
                    </div>
                </div>)

            }
        }

        if (weaponModsCategory) {

            let masterworkPlugDef;
            let modPlugDef;

            let modElements = [];

            for (let socketIndex of weaponModsCategory.socketIndexes) {
                let socketEntry = itemSockets[socketIndex];
                let definition = Destiny.DestinyInventoryItemDefinition[socketEntry.plugHash];
                // console.log(definition.displayProperties.name + " " + socketEntry.plugHash);
                if (!definition) continue;

                // if (definition.plug.plugCategoryHash === Destiny.PLUG_CATEGORY_HASH_MASTERWORKS) {
                //     masterworkPlugDef = definition;
                // } else if(definition.plug.plugCategoryHash === Destiny.PLUG_CATEGORY_HASH_WEAPON_MODS) {
                //     modPlugDef = definition;
                // }

                // if(masterworkPlugDef && modPlugDef) break;

                if (!definition.displayProperties.icon) continue;

                modElements.push(<img
                    className="inspect-weapon-mod"
                    alt=""
                    src={Destiny.BNET + definition.displayProperties.icon}
                ></img>);

            }

            elements.push(<div
                className="inspect-weapon-mod-container"
            >{modElements}</div>)

            if (masterworkPlugDef) {
                elements.push(<div>{masterworkPlugDef.displayProperties.name}</div>);
            }

            if (modPlugDef) {
                elements.push(<div>{modPlugDef.displayProperties.name}</div>);
            }


        }


        return <div
            className="perk-display-container"
        >{elements}</div>;

    }

    getPossibleRollsDisplay(itemData, definition) {

        let itemSockets = itemData.sockets?.sockets;
        let reusablePlugs = itemData.reusablePlugs?.plugs;
        let socketCategories = definition.sockets?.socketCategories;
        let socketEntries = definition.sockets?.socketEntries;

        let colElements = [];

        // find socket category for weapon perks
        let weaponPerksCategory;
        for (let category of socketCategories) {
            if (category.socketCategoryHash === Destiny.SOCKET_CATEGORY_HASH_WEAPON_PERKS) {
                weaponPerksCategory = category;
                break;
            }
            // let categoryDefinition = Destiny.DestinySocketCategoryDefinition[category.socketCategoryHash];
            // console.log(categoryDefinition.displayProperties.name + ": " + category.socketIndexes.length);
        }

        if (!weaponPerksCategory) return;

        // loop thru weapon perk columns
        for (let i = 0; i < weaponPerksCategory.socketIndexes.length; i++) {

            let columnIndex = weaponPerksCategory.socketIndexes[i];
            let socketEntry = socketEntries[columnIndex];

            // console.log(Destiny.DestinySocketTypeDefinition[socketEntry.socketTypeHash]);

            if (socketEntry.socketTypeHash === Destiny.SOCKET_TYPE_HASH_TRACKERS) continue;

            let perkElements = [];
            let uniquePerkHashes = new Set();

            if (socketEntry.randomizedPlugSetHash) {

                let randomPlugSetDefinition = Destiny.DestinyPlugSetDefinition[socketEntry.randomizedPlugSetHash];

                let possiblePlugItems = [...randomPlugSetDefinition.reusablePlugItems];

                // sort perks where unrollable perks are at the bottom
                // possiblePlugItems.sort((p1, p2) => {
                //     return (p2.currentlyCanRoll ? 1 : 0) - (p1.currentlyCanRoll ? 1 : 0);
                // });

                for (let plugItem of possiblePlugItems) {

                    if (uniquePerkHashes.has(plugItem.plugItemHash)) continue; // some items display the same perk twice, so skip them
                    uniquePerkHashes.add(plugItem.plugItemHash);

                    let perkDef = Destiny.DestinyInventoryItemDefinition[plugItem.plugItemHash];
                    let perkName = perkDef?.displayProperties?.name;

                    let perkIsLegacy = plugItem.currentlyCanRoll === false;

                    // TODO: find a better way to check for enhanced perks
                    let perkIsEnhanced = Destiny.isEnhancedPerk(perkDef);

                    // check if perk is rolled and selected
                    let perkIsSelected = itemSockets[columnIndex].plugHash === plugItem.plugItemHash;

                    // check if perk is rolled
                    let perkIsRolled = false;
                    if (reusablePlugs[columnIndex]) {
                        for (let rollPlugItem of reusablePlugs[columnIndex]) {
                            if (rollPlugItem.plugItemHash === plugItem.plugItemHash) {
                                perkIsRolled = true;
                                // break;
                            }
                        }
                    }

                    // skip enhanced perks that are not rolled
                    if (!this.state.showEnhancedPerks && perkIsEnhanced && !perkIsSelected && !perkIsRolled) continue;

                    // skip perks that are not able to roll and are not on the current roll
                    if (!perkIsSelected && !perkIsRolled && perkIsLegacy) continue;

                    if (perkIsRolled && perkIsLegacy) {
                        // console.log(definition.displayProperties.name + ": " + perkName + " (legacy ItemCard)")
                        // break;
                    }

                    let perkClass;
                    let perkTip = "";
                    let craftLevel;

                    if (perkIsSelected) {
                        perkClass = "selected";
                    } else if (perkIsRolled) {
                        perkClass = "rolled"
                    }

                    if (perkIsLegacy) {
                        perkClass += " legacy";
                        perkTip = " (Legacy Perk: cannot currently roll)";
                    }

                    if (perkIsEnhanced) {
                        perkClass += " enhanced";
                    }

                    if (plugItem.craftingRequirements?.requiredLevel) {
                        craftLevel = plugItem.craftingRequirements.requiredLevel;
                    }

                    // console.log(plugItem);

                    perkElements.push(<div
                        className={"perk " + perkClass}
                        data-name={(perkIsEnhanced ? "Enhanced " : "") + perkName + perkTip + (craftLevel !== undefined ? " (Lv. " + craftLevel + ")" : "")}
                        onClick={() => {
                            console.log(plugItem)
                            console.log(perkDef)
                        }}
                    ><img
                        className="perk-icon"
                        alt=""
                        src={Destiny.BNET + perkDef?.displayProperties?.icon}
                    ></img></div>)

                }
            }

            // sometimes origin traits are not randomized and
            // are slotted by default, so this catches those
            // while avoiding errors caused by weird curated rolls
            // (see True Prophecy)
            if (!uniquePerkHashes.has(socketEntry.singleInitialItemHash)) {

                let perkDef = Destiny.DestinyInventoryItemDefinition[socketEntry.singleInitialItemHash];

                if (perkDef && perkDef.plug?.plugCategoryHash === Destiny.PLUG_CATEGORY_HASH_ORIGIN_TRAITS) {
                    let perkName = perkDef?.displayProperties?.name;

                    perkElements.push(<div
                        className={"perk selected"}
                        data-name={perkName}
                        onClick={() => {
                            console.log(socketEntry)
                            console.log(perkDef)
                        }}
                    ><img
                        className="perk-icon"
                        alt=""
                        src={Destiny.BNET + perkDef?.displayProperties?.icon}
                    ></img></div>)
                }

            }

            if (i > 0) {
                colElements.push(<div className="perk-col-divider" />)
            }

            colElements.push(<div
                className="perk-col"
            >
                {perkElements}
            </div>)


        }







        return <div
            // style={{ margin: "0 auto" }}
            className="perk-cols-container"
        >
            {colElements}
        </div>

    }

    getCraftingProgressDisplay(craftingData) {

        let percent = craftingData.progress * 100;

        return <div className="crafting-progress-container">
            <div className="crafting-progress-details">
                {"Weapon Lv. " + craftingData.level}
                <span className="crafting-percent">{percent.toFixed(0) + "%"}</span>
            </div>
            <div
                className="crafting-progress-bar"
                style={{ width: percent + "%" }}
            />
        </div>

    }

    render() {

        let itemData = this.props.itemData;
        let item = itemData.item;
        let itemSockets = itemData.sockets?.sockets;
        let itemReusablePlugs = itemData.reusablePlugs?.plugs;
        let itemStats = itemData.stats?.stats;
        let itemInstance = itemData.instances;
        let allCharacterData = this.props.allCharacterData;
        let selectedCharacterId = this.props.selectedCharacterId;
        let viewingVault = this.props.viewingVault;

        let definition = Destiny.DestinyInventoryItemDefinition[item.itemHash];

        let showPossibleRolls = this.state.showPossibleRolls;

        // console.log(definition.sockets);

        let isArmor = definition.itemType === Destiny.ITEM_TYPE_ARMOR;
        let isWeapon = definition.itemType === Destiny.ITEM_TYPE_WEAPON;

        let className;

        if (selectedCharacterId) {
            let characterData = allCharacterData[selectedCharacterId];
            className = Destiny.ClassHashNames[characterData.classHash];
        }

        // extract deepsight info
        let deepsightData = Destiny.getDeepsightData(itemData);
        let craftingData = Destiny.getCraftingData(itemData);

        return <div
            ref={this.props.elementRef}
            className="inspect-card"
            style={this.props.style}
        >
            <div
                className="inspect-card-header-container"
            >
                <div
                    className="inspect-card-header-backdrop"
                    style={{
                        backgroundColor: Destiny.ItemTierColors[definition.inventory.tierType]
                    }}
                />

                <div>
                    <h1
                        className="inspect-title"
                    >{definition.displayProperties.name}
                    </h1>
                    <div>{definition.itemTypeDisplayName}</div>
                </div>
            </div>

            <div
                className="inspect-card-content"
            >

                <div className="inspect-power-section-container">
                    {itemInstance && itemInstance.primaryStat ? this.getPowerDisplay(definition, itemInstance) : null}
                    {craftingData ? this.getCraftingProgressDisplay(craftingData) : null}
                </div>

                {/* {itemStats ? this.getStatDisplay(itemStats, isArmor) : null} */}
                {itemStats ? <StatBars
                    stats={itemStats}
                    isArmor={isArmor}
                /> : null}

                {isWeapon ? <div className="inspect-card-roll-controls-container">
                    <button
                        onClick={() => this.setState(prevState => ({ showPossibleRolls: !prevState.showPossibleRolls }))}
                    >{this.state.showPossibleRolls ? "Show Current Perks" : "Show Possible Perks"}</button>

                    {this.state.showPossibleRolls && craftingData ? <button
                        onClick={() => this.setState(prevState => ({ showEnhancedPerks: !prevState.showEnhancedPerks }))}
                    >{this.state.showEnhancedPerks ? "Hide Enhanced Perks" : "Show Enhanced Perks"}</button> : null}
                </div> : null}


                {isWeapon && !showPossibleRolls && itemSockets ? this.getPerkDisplay(definition.sockets, itemSockets, itemReusablePlugs) : null}
                {isWeapon && showPossibleRolls && definition.sockets ? this.getPossibleRollsDisplay(itemData, definition) : null}

                {isArmor && itemSockets ? this.getArmorModDisplay(itemSockets, itemReusablePlugs) : null}

                <div
                    className="inspect-card-controls-container"
                >



                    {!definition.nonTransferrable && selectedCharacterId ? <button
                        onClick={() => {
                            if (this.props.onTransferRequested) {
                                this.props.onTransferRequested(!viewingVault, selectedCharacterId)
                            }
                        }}
                    >
                        {viewingVault ? `Transfer To ${className}` : "Transfer To Vault"}
                    </button> : null}

                    {/* <a
                        className="button"
                        target="_blank"
                        rel="noreferrer"
                        href={"https://www.light.gg/db/items/" + item.itemHash}
                    >View On Light.gg</a> */}



                    <button onClick={this.props.onClose}>Close</button>
                </div>

            </div>



        </div>

    }


}

export default ItemCard;